import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faExternalLinkAlt,
  faStopwatch,
  faChevronRight,
  faChevronLeft,
  faAngleUp,
  faShieldAlt,
  faPaperPlane,
  faArrowLeft,
  faArrowRight,
  faRocket,
  faMeteor,
  faSearch,
  faAtom,
  faEnvelope,
  faQuestionCircle,
  faPlusCircle,
  faMinusCircle,
  faPalette,
  faIndustry,
  faComments,
  faMobile,
  faUserAstronaut,
  faSpaceShuttle,
  faSatellite,
  faAt,
  faIdBadge,
  faLanguage,
  faEye,
  faEyeSlash,
  faCog,
  faHome,
  faSignOutAlt,
  faBars,
  faStore,
  faCaretUp,
  faCaretDown,
  faBroom,
  faTags,
  faAddressBook,
  faPlus,
  faCheck,
  faTimes,
  faEdit,
  faAngleLeft,
  faAngleRight,
  faCertificate,
  faCheckDouble,
  faLink,
} from "@fortawesome/free-solid-svg-icons";
import { faClock, faCheckCircle } from "@fortawesome/free-regular-svg-icons";

export const fontawesomeIcons = [
  fab,
  faClock,
  faExternalLinkAlt,
  faStopwatch,
  faChevronRight,
  faChevronLeft,
  faAngleUp,
  faShieldAlt,
  faPaperPlane,
  faArrowLeft,
  faArrowRight,
  faRocket,
  faMeteor,
  faSearch,
  faAtom,
  faEnvelope,
  faQuestionCircle,
  faPlusCircle,
  faMinusCircle,
  faPalette,
  faIndustry,
  faComments,
  faMobile,
  faUserAstronaut,
  faSpaceShuttle,
  faSatellite,
  faAt,
  faIdBadge,
  faLanguage,
  faEye,
  faEyeSlash,
  faCog,
  faHome,
  faSignOutAlt,
  faBars,
  faStore,
  faCaretUp,
  faCaretDown,
  faBroom,
  faTags,
  faAddressBook,
  faPlus,
  faAngleLeft,
  faAngleRight,
  faCertificate,
  faCheckDouble,
  faCheck,
  faCheckCircle,
  faTimes,
  faEdit,
  faLink,
];
