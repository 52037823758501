import React,{Component} from 'react';
import {Col,ButtonGroup,Button,Label,
        ListGroupItem} from 'reactstrap';

export default class EditableRadio extends Component {
  constructor(props){
    super(props)
    this.state={
      editing:false,
      prevValue:''
    }
  }
  componentDidUpdate=(prevProps,prevState)=>{
    const {editAll,setData,editedValue,editPrev,nameInput} = this.props
    const {prevValue,editing} = this.state
    if(!prevProps.editAll&&editAll){
      this.setState(() => {
       return {editing:true}
      })
    }
    if(typeof setData==='boolean'&&prevValue===''){
      this.setState({
        prevValue:setData
      })
    }
    if(editPrev){
      if(!prevState.editing&&editing&&editedValue===''){
        this.props.editValue({target:{value:setData,name:nameInput}})
      }
    }
  }
  enableEdit=()=>{
    this.setState({editing:true})
  }
  cancelEditing=()=>{
    const {nameInput} = this.props
    const {prevValue} = this.state
    this.setState({
      editing:false
    })
    this.props.editValue({target:{value:prevValue,name:nameInput}})
  }
  doneEditing=()=>{
    this.setState({
      editing:false
    })
  }
  render(){
    const {setData,nameInput,title,editedValue,editAll,
           lblHidden,className,bool} = this.props
    const {editing,prevValue} = this.state
    return(
      <ListGroupItem className={`${className} py-0`}     color={editedValue!==''&&editedValue!==prevValue?'info':''}>
        <Label hidden={lblHidden}>
          {title}
        </Label>
        {editing || editAll
        ?<Col className="my-2 d-flex justify-content-center">
            <Label check className="animated fadeInUp switch">
                <input required type="checkbox" onChange={this.props.editValue} name={nameInput} checked={editedValue===''?setData:editedValue}/>
                <span className="fas slider round"/>
            </Label> &nbsp;
            <ButtonGroup>
              <Button size="sm" onClick={this.doneEditing} color="primary">
                <i className="fas fa-check"/>
              </Button>
              <Button size="sm" onClick={this.cancelEditing}>
                <i className="fas fa-times"/>
              </Button>
            </ButtonGroup>
          </Col>

        :<p className="card-text">
          {editedValue===''
          ?setData
            ?bool.cierto
            :bool.falso
          :editedValue
            ?bool.cierto
            :bool.falso}&nbsp;
          <Button color="link" onClick={this.enableEdit}>
            <i className="fas fa-edit"/> Editar
          </Button>
        </p>}
      </ListGroupItem>
    )
  }
}
