import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  // Collapse,
  Button,
  Container,
  NavItem,
  Row,
  Col,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavLink,
} from "reactstrap";
import { MenuToggle } from "../elements";
import { motion } from "framer-motion";
// import logo from '../../assets/img/logo/logo_full.svg';
import { ReactComponent as Logo } from "../../assets/img/logo/logo.svg";
import { ProfileNavCont } from "../../containers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import hero_nav from '../assets/nav_bg_1920.png';
import { GLOBAL_LANGUAGES, LABELS_NAVBAR } from "../../constants";
import "../../style/navb.css";

function Navb({ lang, setLanguage, location: { pathname } }) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  useEffect(() => {
    setIsOpen(false);
  }, [pathname]);
  const isPlatform = pathname.indexOf("platform") > -1;
  const isBeCard = pathname.split("/").length > 2;
  return (
    <>
      <Navbar
        className={`d-print-flex nav_bar ${isPlatform ? "platf_navb" : ""}`}
        fixed={"top"}
        expand={false}
      >
        <NavbarBrand
          tag={Link}
          className={`${isPlatform ? "ml-3" : ""}`}
          to="/"
        >
          <Logo className="img-fluid logo" />
        </NavbarBrand>
        {isPlatform ? (
          <ProfileNavCont className={`ml-auto ${isOpen ? "text-light" : ""}`} />
        ) : isBeCard ? null : (
          <Nav className="ml-auto mr-2">
            <NavItem>
              <NavLink color="secondary" outline tag={Link} to="/pricing">
                {LABELS_NAVBAR.PRICING[lang]}
              </NavLink>
            </NavItem>
            <NavItem>
              <Button color="secondary" outline tag={Link} to="/login">
                {LABELS_NAVBAR.LOGIN[lang]}
              </Button>
            </NavItem>
          </Nav>
        )}

        <NavbarToggler
          tag={MenuToggle}
          collapse={isOpen}
          light={isPlatform ? false : true}
          className="toggle-icon-white d-print-none"
          onClick={toggle}
        ></NavbarToggler>
      </Navbar>
      <Menu setLanguage={setLanguage} isOpen={isOpen} toggle={toggle} />
    </>
  );
}

function Menu({ isOpen, setLanguage }) {
  const animStates = {
    open: {
      clipPath: `circle(150% at 91% 4.5%)`,
      opacity: 1,
    },
    closed: {
      clipPath: `circle(0% at 91% 4.5%)`,
      opacity: 0.5,
    },
  };
  return (
    <motion.div
      initial={false}
      animate={isOpen ? "open" : "closed"}
      variants={animStates}
      className={`circle_menu ${
        isOpen ? "front_menu" : ""
      } d-flex flex-column justify-content-start align-items-start`}
    >
      <Container className="text-light">
        <Row>
          <Col>
            <Nav vertical>
              {Object.keys(GLOBAL_LANGUAGES).map((key, i) => (
                <NavItem key={i}>
                  <NavLink
                    onClick={() => setLanguage(GLOBAL_LANGUAGES[key].lang)}
                    className="text-light"
                    tag={"a"}
                    href="#"
                    key={i}
                  >
                    {GLOBAL_LANGUAGES[key].langKey}&nbsp;
                    <FontAwesomeIcon icon={"language"} />
                  </NavLink>
                </NavItem>
              ))}

              <NavItem>
                <NavLink className="text-light" tag={Link} to="/cards">
                  Directorio&nbsp;
                  <FontAwesomeIcon icon="address-book" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className="text-light"
                  tag={Link}
                  to="/login?card=signup"
                >
                  ¡Quiero una!&nbsp;
                  <FontAwesomeIcon icon="id-badge" />
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
      </Container>
    </motion.div>
  );
}

export default Navb;
