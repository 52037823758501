import React, { Component, Fragment } from "react";
import {
  Form,
  ListGroup,
  Col,
  Row,
  Button,
  FormGroup,
  Nav,
  NavItem,
  NavLink,
  Progress,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  EditableEl,
  EditableDate,
  EditablePhone,
  EditableRadio,
  EditableElOptions,
} from "../forms/editableEls";
import { UploadFiles, SocialHandles } from "../forms/questTypes";
import { AnimSend, SocialHandlesIcons } from "../elements";
import { inputToDate, remEmpty, notEmpty } from "../../utils";
import { GLOBAL_LANGUAGES } from "../../constants";
import { LABELS_FORM_BECARD } from "../../constants/platform";
import { EditableImg } from "./editableEls";
import data from "../../assets/bodym/sending.json";

import "../../style/form.css";

export default class EditableBeCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editLang: "es",
      firstname: "",
      lastname: "",
      treatment_es: "",
      treatment_en: "",
      bio_es: "",
      bio_en: "",
      job_title_es: "",
      job_title_en: "",
      showBirthday: false,
      birthDate: "",
      email: "",
      phone: "",
      phone_ext: "",
      country: "",
      socialmedia_handles: [],
      imgForCrop: [],
      cropped_img: [],
      profile_media: [],
      tags: [],
    };
  }
  componentDidMount = () => {};
  componentDidUpdate = (prevProps, prevState) => {};
  editAll = () => {
    this.setState({
      editAll: !this.state.editAll,
    });
    setTimeout(() => this.setState({ editAll: false }), 1000);
  };
  getFiles = ({ target: { files } }) => {
    this.setState({ cropped_img: files });
  };
  loadCropImg = ({ target: { files } }) => {
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        this.setState({ imgForCrop: reader.result });
      });
      reader.readAsDataURL(files[0]);
    }
  };
  getValue = (e) => {
    const { value, name } = e.target;
    this.setState({ [name]: value });
  };
  endAnim = () => {
    this.props.cancelRequest();
  };
  startSend = async (e) => {
    const {
      sel_becard: { _id },
    } = this.props;
    e.preventDefault();
    const {
      showBirthday,
      firstname,
      lastname,
      treatment_es,
      job_title_es,
      bio_es,
      treatment_en,
      job_title_en,
      bio_en,
      birthDate,
      email,
      phone,
      phone_ext,
      socialmedia_handles,
      cropped_img,
      // profile_media,
      tags,
      country,
    } = this.state;
    let handlesMap = socialmedia_handles.reduce((obj, { network, value }) => {
      obj[network] = value;
      return obj;
    }, {});
    const interm = {
      birthDate: inputToDate(birthDate),
      socialmedia_handles: handlesMap,
      firstname,
      lastname,
      phone,
      phone_ext,
      email,
      bio: {
        es: bio_es,
        en: bio_en,
      },
      job_title: {
        es: job_title_es,
        en: job_title_en,
      },
      treatment: {
        es: treatment_es,
        en: treatment_en,
      },
      showBirthday,
      tags,
      country,
    };
    const data = remEmpty(interm);
    if (notEmpty(data)) {
      let images = null;
      if (cropped_img.length) {
        images = new FormData();
        for (let i = 0; i < cropped_img.length; i++) {
          images.append("images", cropped_img[i]);
        }
      }
      this.props.updateBeCard(data, _id, images);
    }
  };
  setLang = (lang) => {
    this.setState({
      editLang: lang,
    });
  };
  render() {
    const {
      sel_becard: {
        _id,
        firstname = "",
        lastname = "",
        birthDate = "",
        job_title = {},
        bio = {},
        // treatment={},
        phone = "",
        phone_ext = "",
        showBirthday,
        socialmedia_handles = {},
        country,
      },
      isFetchingOne,
      countries,
      lang,
    } = this.props;
    const { editAll, imgForCrop, editLang } = this.state;

    return (
      <Fragment>
        {_id ? (
          <Form id="editable_becard" onSubmit={this.startSend}>
            {isFetchingOne ? (
              <Row className="justify-content-center">
                <Col xs={12} sm={6}>
                  <AnimSend data={data} endAnim={this.endAnim} />
                </Col>
              </Row>
            ) : (
              <Fragment>
                {/* Selector de Formulario de idioma */}
                <Button
                  onClick={this.editAll}
                  block
                  className="mb-2"
                  color="primary"
                >
                  {LABELS_FORM_BECARD.EDIT_ALL[lang]}&nbsp;
                  <FontAwesomeIcon icon="edit" />
                </Button>
                <ListGroup flush className="mb-2 bg-transparent">
                  <EditableEl
                    editValue={this.getValue}
                    setData={firstname}
                    editAll={editAll}
                    editedValue={this.state.firstname}
                    title={LABELS_FORM_BECARD.FIRST_NAME[lang]}
                    nameInput={"firstname"}
                    type="text"
                  />
                  <EditableEl
                    editValue={this.getValue}
                    setData={lastname}
                    editAll={editAll}
                    editedValue={this.state.lastname}
                    title={LABELS_FORM_BECARD.LAST_NAME[lang]}
                    nameInput={"lastname"}
                    type="text"
                  />
                </ListGroup>
                <hr />
                <Nav tabs justified className="">
                  <NavItem>
                    <NavLink
                      active={editLang === "es"}
                      href="#"
                      onClick={() => this.setLang("es")}
                    >
                      {GLOBAL_LANGUAGES["es"].langName}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      active={editLang === "en"}
                      href="#"
                      onClick={() => this.setLang("en")}
                    >
                      {GLOBAL_LANGUAGES["en"].langName}
                    </NavLink>
                  </NavItem>
                </Nav>
                {editLang === "es" ? (
                  <ListGroup key={0} className="mb-2 animated fadeIn">
                    <EditableEl
                      editValue={this.getValue}
                      setData={job_title.es}
                      editAll={editAll}
                      editedValue={this.state.job_title_es}
                      title={LABELS_FORM_BECARD.TITLE[lang]}
                      nameInput={"job_title_es"}
                      type="text"
                    />

                    <EditableEl
                      editValue={this.getValue}
                      setData={bio.es}
                      editAll={editAll}
                      editedValue={this.state.bio_es}
                      title={LABELS_FORM_BECARD.BIO[lang]}
                      nameInput={"bio_es"}
                      type="textarea"
                    />
                  </ListGroup>
                ) : editLang === "en" ? (
                  <ListGroup className="mb-2 animated fadeIn">
                    <EditableEl
                      editValue={this.getValue}
                      setData={job_title.en}
                      editAll={editAll}
                      editedValue={this.state.job_title_en}
                      title={LABELS_FORM_BECARD.TITLE[editLang]}
                      nameInput={"job_title_en"}
                      type="text"
                    />

                    <EditableEl
                      editValue={this.getValue}
                      setData={bio.en}
                      editAll={editAll}
                      editedValue={this.state.bio_en}
                      title={LABELS_FORM_BECARD.BIO[editLang]}
                      nameInput={"bio_en"}
                      type="textarea"
                    />
                  </ListGroup>
                ) : null}
                <hr />
                <ListGroup>
                  <EditableRadio
                    editValue={this.getValue}
                    setData={showBirthday}
                    editAll={editAll}
                    bool={{ cierto: "Sí", falso: "No" }}
                    editedValue={this.state.showBirthday}
                    nameInput={"showBirthday"}
                    title={LABELS_FORM_BECARD.SHOW_BIRTHDATE[lang]}
                  />

                  <EditableDate
                    editValue={this.getValue}
                    setData={birthDate}
                    editAll={editAll}
                    editedValue={this.state.birthDate}
                    nameInput={"birthDate"}
                    title={LABELS_FORM_BECARD.BIRTHDATE[lang]}
                  />
                  <EditableElOptions
                    editValue={this.getValue}
                    setData={country}
                    editAll={editAll}
                    // options={countries.reduce((acc,{ cca2, name, idd, flag }) =>({...acc,}),{})
                    options={countries.reduce(
                      (acc, { name, idd, flag, cca2 }) => ({
                        ...acc,
                        [cca2]: `${flag} ${name?.common} ${idd?.root}${idd?.suffixes[0]}`,
                      }),
                      {}
                    )}
                    editedValue={this.state.country}
                    nameInput={"country"}
                    title={LABELS_FORM_BECARD.COUNTRY[lang]}
                  />
                  <EditablePhone
                    editValue={this.getValue}
                    setData={phone}
                    editAll={editAll}
                    editedValue={this.state.phone}
                    title={LABELS_FORM_BECARD.PHONE[lang]}
                    nameInput={"phone"}
                    type="text"
                    regExp="/^\d{10}$/"
                    pattern="[0-9]{10}"
                    valid={/^\d{10}$/.test(phone)}
                    invalid={
                      phone.length >= 10 ? !/^\d{10}$/.test(phone) : null
                    }
                    placeholder={"Teléfono a 10 dígitos p.ej. 1234567890"}
                    required
                  />
                  <EditableEl
                    editValue={this.getValue}
                    setData={phone_ext}
                    editAll={editAll}
                    editedValue={this.state.phone_ext}
                    title={LABELS_FORM_BECARD.PHONE_EXT[lang]}
                    nameInput={"phone_ext"}
                    type="text"
                  />
                  <SocialHandlesIcons handles={socialmedia_handles} />
                </ListGroup>

                <SocialHandles
                  className="my-2"
                  prevValue={socialmedia_handles}
                  setHandles={this.getValue}
                  name="socialmedia_handles"
                  handles={this.state.socialmedia_handles}
                />
                <UploadFiles
                  isRow={true}
                  inputCol={10}
                  labelCol={2}
                  formText={LABELS_FORM_BECARD.PROFILE_UPLOAD_EXPL[lang]}
                  title={LABELS_FORM_BECARD.PROFILE_UPLOAD[lang]}
                  classLbl=""
                  accept="image/jpeg,image/png"
                  getFiles={this.loadCropImg}
                  gotFiles={imgForCrop.length}
                  name="userPhoto"
                />
                <EditableImg
                  initialCrop={{ unit: "px", width: 600, aspect: 1 }}
                  getFiles={this.getFiles}
                  src={imgForCrop}
                />
                <FormGroup>
                  <Button
                    // onClick={this.startSend}
                    block
                    color="primary"
                    disabled={isFetchingOne}
                    form="editable_becard"
                    type="submit"
                  >
                    {LABELS_FORM_BECARD.UPDATE[lang]}&nbsp;
                    <FontAwesomeIcon icon="rocket" />
                  </Button>
                </FormGroup>
              </Fragment>
            )}
          </Form>
        ) : (
          <Progress animated />
        )}
      </Fragment>
    );
  }
}
