import React from 'react';
import {
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardText,
  Row,
  Col
} from 'reactstrap';
import {LABELS_COMPANY} from '../../../constants';

function RosterCompanyCard({
  className,
  lang,
  info:{
    icon_img,
    handle,
    commercial_name,
    company_name,
    phone,
    phone_ext,
    main_url,
    description,
    foundation_date,
    industry
  }
}){
  return(
    <Card className={`${className} companyCard`}>
      <Row noGutters>
        <Col xs={4}>
          <CardImg className="rounded-left" src={icon_img} alt={`company icon ${handle}`}/>
        </Col>
        <Col xs={8}>
          <CardBody>
            <CardText tag="h4">
              <b>
                {commercial_name}
              </b> <br/>
              <small className="text-muted">
                {company_name}
              </small>
            </CardText>
            <CardTitle>
              {`${LABELS_COMPANY.SINCE[lang]} ${new Date(foundation_date).getFullYear()}`} <br/>
              <b>
                {`${LABELS_COMPANY.INDUSTRY[lang]}: ${industry[lang]}`}
              </b> <br/>
              {description[lang]}
            </CardTitle>
          </CardBody>
        </Col>
      </Row>
    </Card>
  )
}

export default RosterCompanyCard
