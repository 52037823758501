import React from 'react';
import {Container,Row,Col} from 'reactstrap'

function NoMatch(){
  return(
    <Container>
      <Row>
        <Col>
          <h3>
            No encontrado
          </h3>
        </Col>
      </Row>
    </Container>
  )
}

export default NoMatch;
