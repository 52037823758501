export const PLATF_SECTIONS_MENU = [
  {
    to: "/platform/",
    faIcon: "home",
    name: {
      es: "Home",
      en: "Home"
    }
  },
  {
    to: "/platform/becards",
    faIcon: "id-badge",
    name: {
      es: "BeCards",
      en: "BeCards"
    }
  },
  {
    to: "/platform/companies",
    faIcon: "satellite",
    name: {
      es: "Empresas",
      en: "Companies"
    }
  },
  {
    to: "/platform/services",
    faIcon: "store",
    name: {
      es: "Productos",
      en: "Products"
    }
  },
  {
    to: "/platform/profile",
    faIcon: "user-astronaut",
    name: {
      es: "Perfil",
      en: "Profile"
    }
  }
];

export const PLATFORM_ROUTES = {
  LOGIN: "login"
};

export const FORMS = {
  profileUpdate: {
    title: {
      en: "Fill your profile",
      es: "Completa tu perfil"
    }
  },
  BeCard: {
    title: {
      en: "Create your BeCard",
      es: "Crea tu BeCard"
    }
  },
  Company: {
    title: {
      en: "Create your Company's BeCard",
      es: "Crea la BeCard de tu Empresa"
    },
    notice: {
      en: "Companies BeCards need to be validated, we will contact you and ask you for proof of ownership",
      es: "Las BeCards empresariales necesitan ser verificadas, para esto te contactaremos y te pediremos prueba de propiedad"
    }
  }
};

export const LABELS_FORM_SIGNUP = {};

export const LABELS_FORM_LOGIN = {
  VALID: {
    es: "Súper",
    en: "Super"
  },
  INVALID: {
    es: "Inválido",
    en: "Invalid"
  },
  PASS_FORG_TITLE: {
    es: "Contraseña olvidada",
    en: "Forgotten Password"
  },
  SEND_FORG_EMAIL: {
    es: "Enviar correo de recuperación",
    en: "Send password reset mail"
  },
  NO_ACCOUNT: {
    es: "¿No tienes cuenta?",
    en: "¿Don't have an account?"
  },
  CREATE_ACCOUNT: {
    es: "Crea una cuenta",
    en: "Create account"
  },
  PASS_FORG: {
    es: "¿Olvidaste tu contraseña?",
    en: "Forgot your password?"
  },
  HAS_ACCOUNT: {
    es: "¿Ya tienes cuenta?",
    en: "Have an account?"
  },
  REGISTER: {
    es: "Registrarse",
    en: "Register"
  },
  CONFIRM_PASS: {
    es: "Confirma la contraseña",
    en: "Confirm Password"
  },
  PASS_EIGHT_CH: {
    es: "Contraseña",
    en: "Password"
  },
  PASS_RESTR: {
    es: "(8 Caracteres, 1 Mayúscula y 1 Número)",
    en: "Password (8 Chars., 1 capital & a number)"
  },
  RESET_PASS: {
    es: "Reestablecer contraseña",
    en: "Reset password"
  },
  LOGIN: {
    es: "Ingresa",
    en: "Log in"
  }
};

export const LABELS_FORM_BECARD = {
  EDIT_ALL: {
    es: "Editar todo",
    en: "Edit all fields"
  },
  UPDATE: {
    es: "Actualizar",
    en: "Update"
  },
  ADD_IMAGE: {
    es: "Añadir una imagen a la BeCard",
    en: "Add an image to the BeCard"
  },
  IMAGE_FORM_TITLE: {
    es: "Imagen",
    en: "Add an image to the BeCard"
  },
  TAGS: {
    es: "Etiquetas",
    en: "Tags"
  },
  TAGS_EXPL: {
    es: "Para filtrar la búsqueda y maneras en las que te pueden encontrar",
    en: "For filtering search"
  },
  BIO: {
    es: "Biografía",
    en: "Bio"
  },
  BIO_EXPL: {
    es: "Mientras más concreta mejor",
    en: "The more succint the better"
  },
  BIRTHDATE: {
    es: "Fecha nacimiento",
    en: "Birthday"
  },
  BIRTHDATE_EXPL: {
    es: "",
    en: ""
  },
  PHONE: {
    es: "Teléfono",
    en: "Telephone"
  },
  COUNTRY: {
    es: "País",
    en: "Country"
  },
  PHONE_EXPL: {
    es: "10 dígitos p.ej 1234567890",
    en: "10 digits format"
  },
  PHONE_VALID: {
    es: "Súper",
    en: "Super"
  },
  PHONE_INVALID: {
    es: "Ingresa 10 dígitos",
    en: "Please use 10 digits"
  },
  PHONE_EXT: {
    es: "Extensión",
    en: "Extension"
  },
  HANDLE: {
    es: "Handle único",
    en: "Unique handle"
  },
  EMAIL: {
    es: "Correo electrónico",
    en: "Email"
  },
  EMAIL_EXPL: {
    es: "Llenar en caso de querer que sea diferente al registrado",
    en: "Fill if email is different from the registered one"
  },
  TITLE: {
    es: "Ocupación/Puesto",
    en: "Job Title"
  },
  HANDLE_EXPL: {
    es: `El título único de tu BeCard p.ej https://www.becards.app/cards/community/juan.gomez
    Caracteres admisibles: letras, números, guiones, guión bajo, punto, mínimo 3`,
    en: `Unique title for your BeCard ex. https://www.becards.app/cards/community/john.doe
    Possible characters: letters, numbers, dash, underscore, period, at least 3`
  },
  FIRST_NAME: {
    es: "Nombre(s)",
    en: "Name"
  },
  TREATMENT: {
    es: "Prefijo",
    en: "Treatment"
  },
  LAST_NAME: {
    es: "Apellido(s)",
    en: "Last name"
  },
  SHOW_BIRTHDATE: {
    es: "Mostrar cumpleaños",
    en: "Show BirthDate"
  },
  FILE_UPLOAD: {
    es: "Apellido(s)",
    en: "Last name"
  },
  FILE_UPLOAD_EXPL: {
    es: "Apellido(s)",
    en: "Last name"
  },
  PROFILE_UPLOAD: {
    es: "Foto perfil",
    en: "Profile Picture"
  },
  PROFILE_UPLOAD_EXPL: {
    es: "Una foto para personalizar tu perfil, debe ser de al menos 60px por 60px",
    en: "A profile picture to personalize your profile, it should be at least 60px square"
  }
};

export const LABELS_FORM_COMPANY = {
  COMPANY_NAME: {
    es: "Razón social",
    en: "Legal name"
  },
  COMPANY_NAME_PLHLD: {
    es: "Soluciones empresariales S de RL",
    en: "Enterprise solutions LTD"
  },
  COMMERCIAL_NAME_PLHLD: {
    es: "Soluciones empresariales",
    en: "Enterprise solutions"
  },
  COMMERCIAL_NAME: {
    es: "Nombre comercial",
    en: "Commercial Name"
  },
  PHONE: {
    es: "Teléfono",
    en: "Phone"
  },
  PHONE_EXPL: {
    es: "10 dígitos p.ej 1234567890",
    en: "10 digits format"
  },
  PHONE_EXT: {
    es: "Extensión",
    en: "Extension"
  },
  HANDLE: {
    es: "Handle único",
    en: "Unique Handle"
  },
  HANDLE_EXPL: {
    es: "El título único de tu Empresa p.ej https://www.becards.app/cards/empresamx ",
    en: "Unique title for your Company ex. https://www.becards.app/cards/empresamx"
  },
  TAX_ID: {
    es: "RFC",
    en: "VAT"
  },
  MAIN_URL: {
    es: "Página web",
    en: "Webpage"
  },
  DESCRIPTION: {
    es: "Qué hace tu empresa",
    en: "What it does"
  },
  DESCRIPTION_PLHLD: {
    es: "Nos dedicamos a",
    en: "We are in the business off "
  },
  DESCRIPTION_EXPL: {
    es: "Nos dedicamos a",
    en: "We are in the business off "
  },
  INDUSTRY: {
    es: "Industria",
    en: "Industry"
  },
  FOUNDATION_DATE: {
    es: "Fundación",
    en: "Foundation"
  },
  EMAIL: {
    es: "Email",
    en: "Email"
  },
  EMPLOYEES: {
    es: "Empleados",
    en: "Employees"
  },
  LOGO_UPLOAD: {
    es: "Subir logo",
    en: "Logo upload"
  },
  IMAGE_FORM_TITLE: {
    es: "Imagen",
    en: "Add an image to the company"
  },
  LOGO_UPLOAD_EXPL: {
    es: "Una imagen de tu logo, debe ser de al menos 60px por 60px",
    en: "A logo image, it should be at least 60px square"
  },
  ICON_UPLOAD: {
    es: "Ícono de la empresa",
    en: "Company's icon"
  },
  ICON_UPLOAD_EXPL: {
    es: "Una imagen del ícono de tu empresa, debe ser de al menos 60px por 60px",
    en: "An image of your company's icon, it should be at least 60px square"
  },
  TAGS: {
    es: "Etiquetas",
    en: "Tags"
  },
  TAGS_EXPL: {
    es: "El título único de tu BeCard p.ej https://www.becards.app/cards/community/juan.gomez ",
    en: "Unique title for your BeCard ex. https://www.becards.app/cards/community/john.doe"
  },
  ADD_ADDRESS: {
    es: "Añadir domicilio",
    en: "Add Address"
  },
  PUBLISH: {
    es: "Publicar Empresa",
    en: "Publish Company"
  }
};

export const LABELS_FORM_ADDRESS = {
  CIUDAD: {
    es: "Ciudad",
    en: "City"
  },
  LINE_ONE: {
    es: "Línea 1",
    en: "Line 1"
  },
  LINE_TWO: {
    es: "Línea 2",
    en: "Line 2"
  },
  POSTAL_CODE: {
    es: "Código postal",
    en: "ZIP code"
  },
  ESTADO: {
    es: "Estado",
    en: "State/County"
  },
  SHIPPING: {
    es: "Domicilio de entregas",
    en: "Shipping Address"
  }
};

export const SLIDE_MENU_CONTENT = {
  editBeCard: {
    title: {
      en: "Edit BeCard",
      es: "Editar BeCard"
    }
  }
};

export const LABELS_CALENDAR = {
  ACTIVITIES: {
    es: "Actividades",
    en: "Activites"
  },
  NO_ACTIVITIES: {
    es: "Proximamente asistente personal",
    en: "Soon personal assistant"
  }
};

export const LABELS_PLATFORM = {
  CLOSE: {
    en: "Close",
    es: "Cerrar"
  },
  CANCEL: {
    en: "Cancel",
    es: "Cancelar"
  },
  CREATE_BECARD: {
    en: "Create BeCard",
    es: "Crear BeCard"
  },
  CREATE_COMPANY: {
    en: "Create Company",
    es: "Crear Empresa"
  },
  SEARCH: {
    en: "Search",
    es: "Buscar"
  }
};

export const MAX_TAGS = 6;

export const TAGS_MESSAGES = {
  REPEATED: {
    es: "La etiqueta ya está agregada",
    en: "Tag is already added"
  },
  MAXED: {
    es: `Máximo ${MAX_TAGS} etiquetas`,
    en: `You can add ${MAX_TAGS} tops`
  }
};

export const SOCIAL_NETWORKS = [
  {
    network: "facebook",
    faIcon: "facebook",
    placeholder: "example_user",
    urlEx: "https://www.facebook.com/"
  },
  {
    network: "instagram",
    faIcon: "instagram",
    placeholder: "example_user",
    urlEx: "https://www.instagram.com/"
  },
  {
    network: "twitter",
    faIcon: "twitter",
    placeholder: "example_user",
    urlEx: "https://twitter.com/"
  },
  {
    network: "linkedin",
    faIcon: "linkedin",
    placeholder: "example_user",
    urlEx: "https://www.linkedin.com/in/"
  },
  {
    network: "whatsapp",
    faIcon: "whatsapp",
    placeholder: "número a 10 dígitos",
    type: "tel",
    maxLength: "10",
    pattern: "[0-9]{10}",
    regexp: /^\d{10}$/
  },
  {
    network: "telegram",
    faIcon: "telegram",
    placeholder: "example_user",
    urlEx: "https://t.me/"
  }
];

export const PLATF_REDIR_TIMEOUT = 3500;
export const RESET_FORM = 4000;
