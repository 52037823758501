import React from 'react';
import {connect} from 'react-redux';
import {ProfileForm} from '../../components/forms';
import {cancel_user,post_data_user} from '../../redux/actions/user-actions';
import {toggle_form_modal} from '../../redux/actions/platform-actions';

function ProfileFormCont({form_modal,toggle_form_modal,post_data_user,cancel_user,...rest}){
  const cancelRequest=()=>{
    cancel_user()
    if(form_modal){
      toggle_form_modal()
    }
  }
  return(
    <ProfileForm
    updateUser={post_data_user}
    cancelRequest={cancelRequest}
    {...rest} />
  )
}

const mapStateToProps=({
  userReducer,
  platformReducer:{form_modal},
  globalReducer:{lang}
})=>({
  ...userReducer,
  form_modal,
  lang
})

const mapActionsToProps={
  cancel_user,
  post_data_user,
  toggle_form_modal
}

export default connect(mapStateToProps,mapActionsToProps)(ProfileFormCont);
