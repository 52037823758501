import React from "react";
import { Container, Row, Col } from "reactstrap";

function Promise({ mainText }) {
  return (
    <Container className="" fluid>
      <Container>
        <Row className="text-light sect_80 align-items-center">
          <Col>
            <h2>{mainText}</h2>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Promise;
