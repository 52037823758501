import React from 'react';
import {Container,Row} from 'reactstrap';
import {JumboColor} from '../sections';
import {VerifyCont} from '../../containers'
import {ErrorBoundary} from '../elements';

const Verify =({match,history})=>{
  return (
    <section>
      <JumboColor
          header={'Gracias por esperar'}
          lead={'Estamos verificando tu cuenta'}
          cta={''}
          link={''}/>
          <Container className="bg_grayBlue" fluid>
            <Container>
              <Row>
                <ErrorBoundary>
                  <VerifyCont
                    history={history}
                    match={match}
                  />
                </ErrorBoundary>

              </Row>
             </Container>
          </Container>
      {/* <Footer/> */}
    </section>
  )
}

export default Verify
