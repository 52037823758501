export const REQUEST_SIGNUP = "REQUEST_SIGNUP";
export const START_SIGNUP = "START_SIGNUP";
export const CANCEL_SIGNUP = "CANCEL_SIGNUP";
export const SUCCESS_SIGNUP = "SUCCESS_SIGNUP";
export const ERROR_SIGNUP = "ERROR_SIGNUP";
export const RESET_SIGNUP = "RESET_SIGNUP";

export const START_VERIFY_SIGNUP = "START_VERIFY_SIGNUP";

export const start_signup=(dataSignup)=>({
  type:START_SIGNUP,
  dataSignup
})

export const request_signup =()=>({
  type:REQUEST_SIGNUP,
  isFetching:true
})

export const cancel_signup=()=>({
  type:CANCEL_SIGNUP,
  isFetching:false
})

export const success_signup=(message)=>({
  type:SUCCESS_SIGNUP,
  message
})

export const error_signup=(error)=>({
  type:ERROR_SIGNUP,
  error
})

export const reset_signup=()=>({
  type:RESET_SIGNUP,
  error:false,
  message:false
})

export const start_verify=(dataVerify)=>({
  type:START_VERIFY_SIGNUP,
  dataVerify
})
