import React from "react";
import { Row, Col } from "reactstrap";

function SecBarAd({ className, link, img, title, subtitle }) {
  return (
    <Row
      tag="a"
      target="blank"
      rel="noopener noreferrer"
      href={link}
      className={`${className} w-100`}
    >
      <Col className="p-2">
        <h3>
          <b>{title}</b>
        </h3>
        <p>{subtitle}</p>
        <img className="w-100 ad_img" src={img.src} alt={img.alt} />
      </Col>
    </Row>
  );
}

export default SecBarAd;
