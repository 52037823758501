import {
  TOGGLE_FILTER,
  ADD_TYPE_FILTER,
  REMOVE_TYPE_FILTER
} from '../actions/filter-actions';

export const filterReducer=(
  state={
    filtered:false,
    filter_types:[],
    typeFilter:'',
    id_typeFilter:''
  },
  action
)=>{
  const {
    type,
    typeFilter,
    id_typeFilter
  } = action
  switch (type) {
    case TOGGLE_FILTER:
      return({
        ...state,
        filtered:!state.filtered
      })
    case ADD_TYPE_FILTER:
    const found = state.filter_types.indexOf(typeFilter)
    if(found<0) return ({
      ...state,
      filter_types:[
        ...state.filter_types,
        typeFilter
      ]
    })
    return ({...state})
    case REMOVE_TYPE_FILTER:
    return ({
      ...state,
      filter_types:[
        ...state.filter_types.slice(0,id_typeFilter),
        ...state.filter_types.slice(id_typeFilter+1),
      ]
    })


    default:
    return ({...state})

  }
}
