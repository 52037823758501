import React, { Component } from "react";
import {
  InputGroup,
  Input,
  ButtonGroup,
  Button,
  Label,
  ListGroupItem,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class EditableEl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      prevValue: "",
    };
  }
  componentDidUpdate = (prevProps, prevState) => {
    const { editAll, setData, editedValue, editPrev, nameInput } = this.props;
    const { prevValue, editing } = this.state;
    if (!prevProps.editAll && editAll) {
      this.setState(() => {
        return { editing: true };
      });
    }
    if (setData && setData !== "" && prevValue === "") {
      this.setState({
        prevValue: setData,
      });
    }
    if (editPrev) {
      if (!prevState.editing && editing && editedValue === "") {
        this.props.editValue({ target: { value: setData, name: nameInput } });
      }
    }
  };
  enableEdit = () => {
    this.setState({ editing: true });
  };
  cancelEditing = () => {
    const { nameInput } = this.props;
    const { prevValue } = this.state;
    this.setState({
      editing: false,
    });
    this.props.editValue({ target: { value: prevValue, name: nameInput } });
  };
  doneEditing = () => {
    this.setState({
      editing: false,
    });
  };
  keyHandler = (e) => {
    if (e.key === "Tab" || e.key === "Enter") {
      this.doneEditing();
    }
    // else if (e.key==='ArrowLeft'){
    //   this.changePossibleQ(false)
    // }
  };
  render() {
    const {
      setData,
      type,
      nameInput,
      title,
      editedValue,
      editAll,
      lblHidden,
      sizeInput,
      className,
    } = this.props;
    const { editing, prevValue } = this.state;
    return (
      <ListGroupItem
        onKeyUp={this.keyHandler}
        className={`${className} py-0`}
        color={editedValue !== "" && editedValue !== prevValue ? "info" : ""}
      >
        <Label hidden={lblHidden}>
          <b>{title}</b>
        </Label>
        {editing || editAll ? (
          <InputGroup className="my-2 animated fadeIn">
            <Input
              type={type}
              name={nameInput}
              size={sizeInput}
              onChange={this.props.editValue}
              value={editedValue}
              placeholder={setData || title}
            />
            &nbsp;
            <ButtonGroup>
              <Button size="sm" onClick={this.doneEditing} color="primary">
                <FontAwesomeIcon icon="check" />
              </Button>
              <Button size="sm" onClick={this.cancelEditing}>
                <FontAwesomeIcon icon="times" />
              </Button>
            </ButtonGroup>
          </InputGroup>
        ) : (
          <p className="card-text">
            {editedValue === "" ? setData || title : editedValue}&nbsp;
            <Button color="link" onClick={this.enableEdit}>
              <FontAwesomeIcon icon="edit" /> Editar
            </Button>
          </p>
        )}
      </ListGroupItem>
    );
  }
}
