import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Label,
  CardFooter,
  Button,
  FormFeedback,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimFailSucc } from "../elements";
//import {LOCAL_MESSAGES} from '../../constants';
import { LABELS_FORM_LOGIN } from "../../constants/platform";

function ForgPassForm({
  cancelLogin,
  startForgPass,
  isFetching,
  error,
  message,
  setSignUp,
  lang,
}) {
  const [formVals, setFormVals] = useState({ login: "" });
  const loginName = "login";

  const getValue = ({ target: { value, name } }) => {
    setFormVals({ ...formVals, [name]: value });
  };
  const { login, passW } = formVals;
  const handleForm = (e) => {
    e.preventDefault();
    if (passW !== "") {
      startForgPass({
        email: login,
      });
      setFormVals({ login: "" });
    }
  };
  const validUsername = new RegExp(/@/).test(login);
  return (
    <Card className="animated fadeInPlace">
      <CardHeader className="font-weight-bold">
        {LABELS_FORM_LOGIN.PASS_FORG_TITLE[lang]}
      </CardHeader>
      <CardBody>
        <Form onSubmit={handleForm}>
          {isFetching ? (
            message || error ? (
              <Row className="justify-content-center">
                <Col>
                  <AnimFailSucc error={error} endAnim={cancelLogin} />
                </Col>
              </Row>
            ) : null
          ) : (
            <>
              <FormGroup>
                <Label>
                  <i className="fas fa-at" />
                  &nbsp; Email
                </Label>

                <InputGroup>
                  <Input
                    onChange={getValue}
                    valid={login.length ? validUsername : null}
                    invalid={login.length ? null : validUsername}
                    value={login}
                    type="text"
                    autoComplete="username"
                    name={loginName}
                    id={loginName}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <FontAwesomeIcon icon="at" />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                <FormFeedback valid>
                  {LABELS_FORM_LOGIN.VALID[lang]}
                </FormFeedback>
                <FormFeedback>{LABELS_FORM_LOGIN.INVALID[lang]}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Button type="submit" color="primary" block>
                  {LABELS_FORM_LOGIN.SEND_FORG_EMAIL[lang]}
                </Button>
              </FormGroup>
              {/* <FormGroup>
                    <Button onClick={setForgot} color="link" block>
                      ¿Olvidaste tu contraseña?
                    </Button>
                  </FormGroup> */}
            </>
          )}
        </Form>
      </CardBody>
      <CardFooter>
        <b>{LABELS_FORM_LOGIN.NO_ACCOUNT[lang]}</b>
        <Button onClick={setSignUp} className="d-inline" color="link">
          {LABELS_FORM_LOGIN.CREATE_ACCOUNT[lang]}
        </Button>
      </CardFooter>
    </Card>
  );
}

export default ForgPassForm;
