import React from 'react';
import {connect} from 'react-redux';
import {CompanySubmenu} from '../../components/platform';
import {set_sel_form,toggle_form_modal} from '../../redux/actions/platform-actions';

function CompanySubmenuCont({
  set_sel_form,
  toggle_form_modal,
  ...rest
}){
  const createCompany =()=>{
    set_sel_form('Company')
    toggle_form_modal()
  }
  return(
    <CompanySubmenu createCompany={createCompany}  {...rest}/>
  )
}

const mapStateToProps=({
  globalReducer:{lang}
})=>({
  lang
})

const mapActionsToProps={
  set_sel_form,
  toggle_form_modal
}

export default connect(mapStateToProps, mapActionsToProps)(CompanySubmenuCont);
