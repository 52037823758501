import React, {Component,Fragment} from 'react';
import {Row,Col, Form, FormGroup, Label, Input,Button,FormText,FormFeedback} from 'reactstrap';
import {UploadFiles} from '../forms/questTypes'
import {AnimSend} from '../elements'
import {EditableImg} from './editableEls';
import '../../style/form.css'
import {LABELS_FORM_BECARD} from '../../constants/platform';
import {inputToDate} from '../../utils';
import data from '../../assets/bodym/sending.json';

export default class ProfileForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      progr:0,
      localMessage:'',
      birthDate: '',
      showBirthday:false,
      firstname: '',
      lastname: '',
      telephone:'',
      email: '',
      imgForCrop:[],
      cropped_img:[]
    }
  }
  componentDidUpdate=(prevProps)=>{
    const {changedUser} = this.props
    if(changedUser&&prevProps.changedUser!==changedUser){
      this.props.toggleChangedUser(changedUser)

      this.setState({
         progr:100
      })
      setTimeout(()=>{
        this.props.toggleFormModal(true)
      },700)
    }

  }
  getFiles = ({target:{files}}) => {
    this.setState({cropped_img: files})
  }
  loadCropImg=({target:{files}})=>{
    if(files&&files.length>0){
      const reader = new FileReader()
      reader.addEventListener('load',()=>{
        this.setState({imgForCrop: reader.result})
      })
      reader.readAsDataURL(files[0]);
    }
  }
  getValue=(e)=>{
    const {value,name,type,checked} = e.target;
    const val = type === 'checkbox' ? checked : value;
    this.setState({
      [name]:val
    })

  }
  endAnim=()=>{
    this.props.cancelRequest()
  }
  startSend=(e)=>{
    e.preventDefault()
    const {
      birthDate,
      firstname,
      lastname,
      telephone,
      showBirthday,
      cropped_img
    } = this.state
    const data = {
      firstname,
      lastname,
      telephone
    }
    if(showBirthday)data.birthDate = inputToDate(birthDate)
    if(cropped_img.length){
      let images = new FormData()
      for (let i = 0;i<cropped_img.length;i++){
        images.append('images',cropped_img[i])
      }
      this.props.updateUser(data,images)
    } else {
      this.props.updateUser(data)
    }
  }
  render() {
    const {isFetching,lang} = this.props
    const {showBirthday, firstname, lastname, telephone,birthDate,imgForCrop} = this.state
    return (
      <Form id="profile_form" onSubmit={this.startSend}>
        {isFetching
          ?<Row className="justify-content-center">
            <Col xs={12} sm={6}>
              <AnimSend
              data={data}
              endAnim={this.endAnim}/>
            </Col>
          </Row>
          : <Fragment>
            <FormGroup className="animated fadeInUp" row>
              <Label for="firstname" sm={2}>{LABELS_FORM_BECARD.FIRST_NAME[lang]}</Label>
              <Col sm={10}>
                <Input
                  onChange={this.getValue}
                  value={firstname}
                  type="firstname"
                  name="firstname"
                  required
                  id="firstname" placeholder={'Nombre(s)'}/>
              </Col>
            </FormGroup>
            <FormGroup className="animated fadeInUp" row>
              <Label for="lastname" sm={2}>{LABELS_FORM_BECARD.LAST_NAME[lang]}</Label>
              <Col sm={10}>
                <Input
                  onChange={this.getValue}
                   value={lastname}
                   type="lastname"
                   name="lastname"
                   id="lastname"
                   required
                   placeholder={'Apellidos'}/>
              </Col>
            </FormGroup>
            <FormGroup row>
           <Label sm={2}>
             Mostrar cumpleaños
           </Label>
           <Col sm={10}>
             <Label check className="switch">
                <input type="checkbox" onChange={this.getValue} name="showBirthday" checked={showBirthday}/>
                <span className="slider round"/>
              </Label>
            </Col>
         </FormGroup>
            {/* <FormGroup row>
              <Label for="newPassword" sm={2}>Contraseña nueva</Label>
              <Col sm={10}>
                <Input
                  value={password}
                  type="password"
                  name="newPassword"
                  id="newPassword"
                  placeholder="contraseña nueva"/>
              </Col>
            </FormGroup> */}
            {showBirthday
              ?<FormGroup className="animated fadeInUp" row>
                <Label for="birthDate" sm={2}>{LABELS_FORM_BECARD.BIRTHDATE[lang]}</Label>
                <Col sm={10}>
                  <Input
                    onChange={this.getValue}
                    value={birthDate}
                    name="birthDate"
                    id="birthDate"
                    required
                    type="date"/>
                </Col>
            </FormGroup>
              :null
            }
            <FormGroup className="animated fadeInUp" row>
              <Label for="telephone" sm={2}>{LABELS_FORM_BECARD.PHONE[lang]}</Label>
              <Col sm={10}>
                <Input
                  onChange={this.getValue}
                  value={telephone}
                  name="telephone"
                  id="telephone"
                  type="text"
                  // regExp="/^\d{10}$/"
                  pattern='[0-9]{10}'
                  valid={/^\d{10}$/.test(telephone)}
                  invalid={telephone.length>=10
                          ?!/^\d{10}$/.test(telephone)
                          :null
                  }
                  placeholder={LABELS_FORM_BECARD.PHONE_EXPL[lang]}
                  required
                   />
                  <FormFeedback className="animated fadeIn" valid>{LABELS_FORM_BECARD.PHONE_VALID[lang]}</FormFeedback>
                  <FormFeedback>{LABELS_FORM_BECARD.PHONE_INVALID[lang]}</FormFeedback>
                  <FormText></FormText>
              </Col>
            </FormGroup>
            <UploadFiles
              isRow={true}
              inputCol={10}
              labelCol={2}
              formText={LABELS_FORM_BECARD.PROFILE_UPLOAD_EXPL[lang]}
              title={LABELS_FORM_BECARD.PROFILE_UPLOAD[lang]}
              classLbl=""
              accept="image/jpeg,image/png"
              getFiles={this.loadCropImg}
              gotFiles={imgForCrop.length}
              name="userPhoto"/>
            <EditableImg
               initialCrop={
                 {unit: 'px',
                 width: 600,
                 aspect: 1}
               }
               getFiles={this.getFiles}
               src={imgForCrop}
              />
            <FormGroup>
              <Button
                // onClick={this.startSend}
                block
                color="primary"
                disabled={isFetching}
                form="profile_form"
                type="submit" >
                  Actualizar
              </Button>
            </FormGroup>
          </Fragment>
        }


    </Form>)
  }
}
