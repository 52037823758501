import React from "react";
import { SEO } from "../elements";
import {
  Jumbo,
  // Promises,
  // UseCases,
  // Features,
  // HowItWorks,
  // Plans,
  // CTASubscribe,
  Footer,
} from "../sections";
import { Intro, Promise, CallToAction, Features } from "../sections/landing";

import "../../style/landing.css";

import hero_img from "../../assets/img/landing/hero_landing.png";
import bg_hero_right from "../../assets/img/landing/bg_hero_right.png";
import top_hero from "../../assets/img/landing/top_hero.svg";

import main_CTA from "../../assets/img/landing/call_to_action/main_CTA.png";
import bg_action from "../../assets/img/landing/call_to_action/bg_left.png";

import main_intro from "../../assets/img/landing/intro/main_intro.png";
import bg_intro from "../../assets/img/landing/intro/bg_left.png";
import feature1 from "../../assets/img/landing/features/feature1.png";
import feature2 from "../../assets/img/landing/features/feature2.png";
import feature3 from "../../assets/img/landing/features/feature3.png";
// import sect_trans from '../../assets/img/landing/bg_sections/bg_beslides.png'
// import sect_trans_2 from '../../assets/img/sect_trans.svg'
// import useCasesImg from '../../constants/landing';
//
// import graph_1 from '../../assets/img/landing/features/graph_1.png';
// import graph_2 from '../../assets/img/landing/features/graph_2.png';
// import graph_3 from '../../assets/img/landing/features/graph_3.png';
// import fresh from '../../assets/img/landing/features/fresh.png';
// import fresh_2 from '../../assets/img/landing/features/fresh_2.png';
// import fresh_3 from '../../assets/img/landing/features/fresh_3.png';
// import autoplay from '../../assets/img/landing/features/autoplay.png';
// import autoplay_2 from '../../assets/img/landing/features/autoplay_2.png';
// import autoplay_3 from '../../assets/img/landing/features/autoplay_3.png';
// import design_1 from '../../assets/img/landing/features/design_1.png';
// import design_2 from '../../assets/img/landing/features/design_2.png';
// import design_3 from '../../assets/img/landing/features/design_3.png';
// import animated_1 from '../../assets/img/landing/features/animated_1.png';
// import animated_2 from '../../assets/img/landing/features/animated_2.png';
// import animated_3 from '../../assets/img/landing/features/animated_3.png';
//
// import process_1 from '../../assets/img/landing/process/process_1.png';
// import process_2 from '../../assets/img/landing/process/process_2.png';
// import process_3 from '../../assets/img/landing/process/process_3.png';
// import process_4 from '../../assets/img/landing/process/process_4.png';
//
// import standard from '../../assets/img/icons/startup_treatment/standard.svg';
// import premium from '../../assets/img/icons/startup_treatment/premium.svg';
// import enterprise from '../../assets/img/icons/startup_treatment/enterprise.svg';

function Landing({ lang }) {
  // const useCases = [
  //   {
  //     // img:useCasesImg.graph,
  //     scenario: "Presentación de negocios",
  //   },
  //   {
  //     // img:useCasesImg.brand_guidelines,
  //     scenario: "Guías de marca",
  //   },
  //   {
  //     // img:useCasesImg.product,
  //     scenario: "Lanzamiento de producto",
  //   },
  //   {
  //     // img:useCasesImg.process,
  //     scenario: "Explicación de proceso",
  //   },
  //   {
  //     // img:useCasesImg.project_presentation,
  //     scenario: "Presentación de proyecto",
  //   },
  //   {
  //     // img:useCasesImg.investment,
  //     scenario: "Presentación a inversionistas",
  //   },
  //   {
  //     // img:useCasesImg.architecture,
  //     scenario: "Proyecto Arquitectónico",
  //   },
  //   {
  //     // img:useCasesImg.app_usage,
  //     scenario: "Presentación de App",
  //   },
  //   {
  //     // img:useCasesImg.team_present,
  //     scenario: "Presentación de equipo",
  //   },
  //   {
  //     // img:useCasesImg.service,
  //     scenario: "Explicación de servicio",
  //   },
  // ];

  // const sectTr = {
  //   backgroundImage: `linear-gradient(to top, rgba(233,236,239,0),rgba(233,236,239,1) 80%)`,
  //   // position:'relative',
  //   backgroundSize: "contain",
  //   backgroundRepeat: "no-repeat",
  //   // backgroundPosition:'right',
  // };

  // const sectTr_2 = {
  //   backgroundImage:`url(${sect_trans_2})`
  // }

  // const features = [
  //   {
  //     title: "Analítica",
  //     text: "Conocer el comportamiento es crucial para mejorar",
  //     icon: "chart-bar",
  //     graphs: [
  //       {
  //         // img:graph_1,
  //         style: {
  //           width: "40%",
  //           top: "0",
  //         },
  //       },
  //       {
  //         // img:graph_2,
  //         style: {
  //           width: "50%",
  //           top: "45vh",
  //           left: "10%",
  //         },
  //       },
  //       {
  //         // img:graph_3,
  //         style: {
  //           width: "50%",
  //           top: "30vh",
  //           left: "30%",
  //         },
  //       },
  //     ],
  //   },
  //   {
  //     title: "Auto presentación",
  //     text:
  //       "No es necesario que estés para que tu historia se cuenta como tú quieres",
  //     icon: "stopwatch",
  //     graphs: [
  //       {
  //         // img:autoplay,
  //         style: {
  //           width: "30%",
  //         },
  //       },
  //       {
  //         // img:autoplay_2,
  //         style: {
  //           width: "50%",
  //           top: "12vh",
  //           left: "20%",
  //         },
  //       },
  //       {
  //         // img:autoplay_3,
  //         style: {
  //           width: "35%",
  //           top: "45vh",
  //           left: "0",
  //         },
  //       },
  //     ],
  //   },
  //   {
  //     title: "Siempre fresco",
  //     text: "Olvídate de las versiones, el contenido siempre está actualizado",
  //     icon: "carrot",
  //     graphs: [
  //       {
  //         // img:fresh,
  //         style: {
  //           width: "30%",
  //         },
  //       },
  //       {
  //         // img:fresh_2,
  //         style: {
  //           width: "50%",
  //           top: "35vh",
  //           left: "10%",
  //         },
  //       },
  //       {
  //         // img:fresh_3,
  //         style: {
  //           width: "50%",
  //           top: "45vh",
  //           left: "10%",
  //         },
  //       },
  //     ],
  //   },
  //   {
  //     title: "Diseño profesional",
  //     text: "Todo hecho por expertos gráficos",
  //     icon: "palette",
  //     graphs: [
  //       {
  //         // img:design_3,
  //         style: {
  //           width: "50%",
  //           top: "15vh",
  //           left: "20%",
  //         },
  //       },
  //       {
  //         // img:design_2,
  //         style: {
  //           width: "35%",
  //           top: "50vh",
  //           left: "0",
  //         },
  //       },
  //       {
  //         // img:design_1,
  //         style: {
  //           width: "35%",
  //         },
  //       },
  //     ],
  //   },
  //   {
  //     title: "Una animación dice lo que mil gráficos",
  //     text: "Explica conceptos complicados con practicidad y facilidad",
  //     icon: "paper-plane",
  //     graphs: [
  //       {
  //         // img:animated_3,
  //         style: {
  //           width: "30%",
  //         },
  //       },
  //       {
  //         // img:animated_2,
  //         style: {
  //           width: "50%",
  //           top: "22vh",
  //           left: "25%",
  //         },
  //       },
  //       {
  //         // img:animated_1,
  //         style: {
  //           width: "50%",
  //           top: "45vh",
  //           left: "0",
  //         },
  //       },
  //     ],
  //   },
  // ];
  const features = [
    {
      title: "Enlaces de redes sociales!",
      text: "No es necesario que estés para que tu historia se cuenta como tú quieres",
      icon: "stopwatch",
      graphs: [
        {
          img: feature1,
          style: {
            width: "80%",
            left: "0%",
            top: "-10%",
          },
        },
      ],
    },
    {
      title: "Muestra tu portafolio",
      text: "Olvídate de las versiones, el contenido siempre está actualizado",
      icon: "carrot",
      graphs: [
        {
          img: feature2,
          style: {
            width: "80%",
            left: "0%",
            top: "-10%",
          },
        },
      ],
    },
    {
      title: "Una forma más rápida de conectarse",
      text: "Todo hecho por expertos gráficos",
      icon: "palette",
      graphs: [
        {
          img: feature3,
          style: {
            width: "80%",
            left: "0%",
            top: "-10%",
          },
        },
      ],
    },
  ];

  //   const userProc = [
  //     {
  //       title: "Sobre ti",
  //       text: "Cuéntanos acerca de tu marca y cuál es tu objetivo ",
  //       // img:process_1
  //     },
  //     {
  //       title: "Contenido",
  //       text: "Muéstranos lo que quieres que transformemos, o empecemos desde 0 como tú quieras",
  //       // img:process_2
  //     },
  //     {
  //       title: "Mejora",
  //       text: `Deja que nuestros profesionales te aconsejen para conseguir el mayor impacto.
  //
  //       Recibe propuestas, critícalas hasta que estés contento`,
  //       // img:process_3
  //     },
  //     {
  //       title: "Publica",
  //       text: "¡Comparte, impresiona, impacta y mide el resultado de tu contenido!",
  //       // img:process_4
  //     },
  //   ];

  // const solutions = [
  //   {
  //     icon: "",
  //     title: "Starter",
  //     text: "Obtén una presentación profesional, espectacular y efectiva, hecha por expertos de diseño y storytelling",
  //     color: "orange",
  //     link: "/pricing",
  //     // img:standard
  //   },
  //   {
  //     icon: "",
  //     title: "Premium",
  //     text: "Obtén orientación uno a uno y una presentación profesional, espectacular y efectiva, hecha por expertos",
  //     color: "red",
  //     link: "/pricing",
  //     // img:premium
  //   },
  //   {
  //     icon: "",
  //     title: "Enterprise",
  //     text: "Obtén presentaciones para tu empresa profesionales con orientación de expertos de story telling y diseño",
  //     color: "purple",
  //     link: "/pricing",
  //     // img:enterprise
  //   },
  // ];

  const heroImg = {
    src: hero_img,
    alt: "Explicando el uso de las BeCards",
  };

  const bgHero = {
    className: "float_hero_right",
    src: bg_hero_right,
  };

  const bgHeroTop = {
    className: "float_hero_top",
    src: top_hero,
  };

  const jumboTexts = {
    heroText: {
      es: `BeCards
         tu tarjeta de presentación digital`,
      en: `BeCards
         your digital business card`,
    },
    leadText: {
      es: `Olvídate del papel y pluma,
        olvídate de "te llamo después".
        Conecta inmediatamente en un click `,
      en: `Forget pen and paper,
        forget "I'll call you later".
        Conect in a click's time`,
    },
    buttonText: {
      es: "Comenzar",
      en: "Begin",
    },
  };

  const featuresText = {
    title: {
      es: "Sin importar tu ocupación",
      en: "Regardles of your profession",
    },
    subtitle: {
      es: "Muestra tus talentos",
      en: "Showcase your talents",
    },
  };

  const textPromise = {
    es: (
      <>
        Una <b>solución</b> que <b>encaja</b> <br />
        perfecto con cualquier <b>objetivo</b> profesional.
      </>
    ),
    en: (
      <>
        A <b>solution</b> that <br /> perfectly <b>fits</b> any <br />{" "}
        professional <b>purpose</b>.
      </>
    ),
  };

  const textActionable = {
    title: {
      es: "BeCard, tu tarjeta personal digital",
      en: "BeCard, your digital business card",
    },
    subtitle: {
      es: "Haz de tu primera impresión, la mejor",
      en: "Make of your first impression, the best.",
    },
  };

  const mainBtnAction = {
    text: {
      es: "¡Quiero una!",
      en: "¡I want mine!",
    },
    goTo: "/login",
  };

  const featuresImg = {
    src: main_intro,
    alt: "intro",
  };

  const actionImg = {
    src: main_CTA,
    alt: "action",
  };

  const bgIntro = {
    className: "float_intro_left",
    src: bg_intro,
  };

  const bgAction = {
    className: "float_action_left",
    src: bg_action,
  };

  return (
    <section className="public_pages">
      <SEO title="Home" description="Presentaciones efectivas y elegantes" />
      <Jumbo
        className="text-light text-left"
        heroText={jumboTexts.heroText[lang]}
        leadText={jumboTexts.leadText[lang]}
        buttonText={jumboTexts.buttonText[lang]}
        gotoBtn={"/login?card=signup"}
        heroImg={heroImg}
        background={bgHero}
        bgTop={bgHeroTop}
        lang={lang}
      />
      <div id="start" />
      <Intro
        title={featuresText.title[lang]}
        heroImg={featuresImg}
        background={bgIntro}
        subtitle={featuresText.subtitle[lang]}
      />
      <Promise mainText={textPromise[lang]} />
      <Features features={features} />
      <CallToAction
        title={textActionable.title[lang]}
        subtitle={textActionable.subtitle[lang]}
        mainBtnText={mainBtnAction.text[lang]}
        goTo={mainBtnAction.goTo}
        background={bgAction}
        actionImg={actionImg}
      />

      {/*<Promises/>
      <UseCases
      background={sectTr}
      useCases={useCases}/>
      <Features
      // background={sectTr_2}
      features={features}/>
      <HowItWorks
      userProc={userProc}
      />
      <Plans
      solutions={solutions}
      />
      <CTASubscribe/>*/}
      <Footer />
    </section>
  );
}

export default Landing;
