import {
  takeLatest,
  put,
  call,
  //select
} from "redux-saga/effects";
import * as actions from "../redux/actions/country-actions";
import { add_toast } from "../redux/actions/toasts-actions";
import { getStateLang } from "./utils";
import {
  api_public,
  //api_private
} from "../services";

function* listAllActiveCountries() {
  yield put(actions.request_countries());
  try {
    const lang = yield getStateLang();
    const countries = yield call(api_public.getAllCountries);
    if (countries.error) {
      yield put(actions.error_countries(countries.error));
      yield put(
        add_toast({
          error: true,
          text: countries.error,
        })
      );
      yield put(actions.cancel_countries());
    } else {
      yield put(actions.success_countries(countries.message));
      yield put(actions.set_all_countries(countries.countries));
      yield put(
        add_toast({
          error: false,
          text: countries.message[lang],
        })
      );
      yield put(actions.cancel_countries());
    }
  } catch (error) {
    yield put(actions.error_countries(error));
    yield put(
      add_toast({
        error: true,
        text: error,
      })
    );
    yield put(actions.cancel_countries());
  }
}

export function* watchCountry() {
  yield takeLatest(actions.LIST_COUNTRIES, listAllActiveCountries);
}
