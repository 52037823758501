import React from 'react';
import {connect} from 'react-redux';
import {CardsFilter} from '../../components/platform/elements'
import {start_search_becards} from '../../redux/actions/becard-actions';
import {
  toggle_filter,
  remove_type_filter,
  add_type_filter
} from '../../redux/actions/filter-actions';

function CardsFilterCont({
  start_search_becards,
  toggle_filter,
  remove_type_filter,
  add_type_filter,
  ...rest
}){
  return(
    <CardsFilter
      startSearch={start_search_becards}
      remFilter={remove_type_filter}
      addFilter={add_type_filter}
      toggleFilter={toggle_filter} {...rest}/>
  )
}

const mapStateToProps=({
  filterReducer,
  globalReducer:{lang}
})=>({
  ...filterReducer,
  lang
})

const mapActionsToProps={
  toggle_filter,
  start_search_becards,
  remove_type_filter,
  add_type_filter
}

export default connect(mapStateToProps,mapActionsToProps)(CardsFilterCont);
