import React, { useEffect } from "react";
import { connect } from "react-redux";
import { BeCard } from "../components/routes";
import { search_one_becard } from "../redux/actions/becard-actions";
import { list_countries } from "../redux/actions/country-actions";

function BeCardCont({
  search_one_becard,
  match,
  list_countries,
  countries,
  countriesError,
  ...rest
}) {
  useEffect(() => {
    if (!countriesError && !countries.length) {
      list_countries();
    }
  }, [countries, countriesError, list_countries]);
  useEffect(() => {
    const {
      params: { company, handle },
    } = match;
    if (company && handle) {
      let queryURL = new URLSearchParams();
      queryURL.append("search", true);
      queryURL.append("handle", handle);
      queryURL.append("company", company);
      search_one_becard(`?${queryURL.toString()}`);
    }
    // eslint-disable-next-line
  }, []);
  return <BeCard countries={countries} {...rest} />;
}

const mapStateToProps = ({
  becardReducer,
  globalReducer,
  countryReducer: { countries, error: countriesError },
}) => ({
  ...becardReducer,
  ...globalReducer,
  countries,
  countriesError,
});

const mapActionsToProps = {
  search_one_becard,
  list_countries,
};

export default connect(mapStateToProps, mapActionsToProps)(BeCardCont);
