import React, { Component, Fragment } from "react";
import { Form, ListGroup, Col, Row, Button, FormGroup } from "reactstrap";
import { EditableEl, EditableDate, EditablePhone } from "../forms/editableEls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UploadFiles } from "../forms/questTypes";
import { AnimSend } from "../elements";
import { inputToDate, remEmpty } from "../../utils";
import { LABELS_FORM_BECARD } from "../../constants/platform";
import { EditableImg } from "./editableEls";

import "../../style/form.css";

export default class EditableProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progr: 0,
      showMessage: false,
      showBirthday: false,
      localMessage: "",
      birthDate: "",
      firstname: "",
      lastname: "",
      telephone: "",
      email: "",
      imgForCrop: [],
    };
  }
  componentDidUpdate = (prevProps) => {
    const { changedUser } = this.props;
    if (changedUser && prevProps.changedUser !== changedUser) {
      // this.setLocalMessage(USER_MESSSAGES.updated_user)
      this.props.toggleChangedUser(changedUser);
      this.props.toggleEdit();
    }
  };
  editAll = () => {
    this.setState({
      editAll: !this.state.editAll,
    });
    setTimeout(() => this.setState({ editAll: false }), 1000);
  };
  getFiles = ({ target: { files } }) => {
    this.setState({ cropped_img: files });
  };
  loadCropImg = ({ target: { files } }) => {
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        this.setState({ imgForCrop: reader.result });
      });
      reader.readAsDataURL(files[0]);
    }
  };
  getValue = (e) => {
    const { value, name } = e.target;
    this.setState({ [name]: value });
  };
  endAnim = () => {
    this.props.cancelRequest();
  };
  startSend = (e) => {
    e.preventDefault();
    const { birthDate, firstname, lastname, telephone, cropped_img } =
      this.state;
    const data = remEmpty({
      birthDate: inputToDate(birthDate),
      firstname,
      lastname,
      telephone,
    });
    if (cropped_img?.length) {
      let images = new FormData();
      for (let i = 0; i < cropped_img.length; i++) {
        images.append("images", cropped_img[i]);
      }
      this.props.updateUser(data, images);
    } else {
      this.props.updateUser(data);
    }
  };
  render() {
    const {
      user: { firstname = "", lastname = "", birthDate = "", telephone = "" },
      isFetching,
      lang,
    } = this.props;
    const { editAll, imgForCrop } = this.state;

    return (
      <Form id="editable_user" onSubmit={this.startSend}>
        {isFetching ? (
          <Row className="justify-content-center">
            <Col xs={12} sm={6}>
              <AnimSend endAnim={this.endAnim} />
            </Col>
          </Row>
        ) : (
          <Fragment>
            <ListGroup>
              <Button onClick={this.editAll} block color="primary">
                Editar todo
              </Button>

              <EditableEl
                editValue={this.getValue}
                setData={firstname}
                editAll={editAll}
                editedValue={this.state.firstname}
                title="Nombre"
                nameInput={"firstname"}
                type="text"
              />
              <EditableEl
                editValue={this.getValue}
                setData={lastname}
                editAll={editAll}
                editedValue={this.state.lastname}
                title="Apellidos"
                nameInput={"lastname"}
                type="text"
              />

              <EditableDate
                editValue={this.getValue}
                setData={birthDate}
                editAll={editAll}
                editedValue={this.state.birthDate}
                nameInput={"birthDate"}
                title="Fecha de nacimiento"
              />
              <EditablePhone
                editValue={this.getValue}
                setData={telephone}
                editAll={editAll}
                editedValue={this.state.telephone}
                title="Teléfono"
                nameInput={"telephone"}
                type="text"
                regExp="/^\d{10}$/"
                pattern="[0-9]{10}"
                valid={/^\d{10}$/.test(telephone)}
                invalid={
                  telephone.length >= 10 ? !/^\d{10}$/.test(telephone) : null
                }
                placeholder={"Teléfono a 10 dígitos p.ej. 1234567890"}
                required
              />
            </ListGroup>
            <UploadFiles
              isRow={true}
              inputCol={10}
              labelCol={2}
              formText={LABELS_FORM_BECARD.PROFILE_UPLOAD_EXPL[lang]}
              title={LABELS_FORM_BECARD.PROFILE_UPLOAD[lang]}
              classLbl=""
              accept="image/jpeg,image/png"
              getFiles={this.loadCropImg}
              gotFiles={imgForCrop.length}
              name="userPhoto"
            />
            <EditableImg
              initialCrop={{ unit: "px", width: 600, aspect: 1 }}
              getFiles={this.getFiles}
              src={imgForCrop}
            />
            <FormGroup>
              <Button
                // onClick={this.startSend}
                block
                color="primary"
                disabled={isFetching}
                form="editable_user"
                type="submit"
              >
                {LABELS_FORM_BECARD.UPDATE[lang]}&nbsp;
                <FontAwesomeIcon icon="rocket" />
              </Button>
            </FormGroup>
          </Fragment>
        )}
      </Form>
    );
  }
}
