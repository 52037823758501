import React from 'react';
import {connect} from 'react-redux';
import {CompanyForm} from '../../components/forms';
import {create_company,cancel_company} from '../../redux/actions/company-actions';
import {toggle_form_modal} from '../../redux/actions/platform-actions';
import {add_toast} from '../../redux/actions/toasts-actions';

function CompanyFormCont({
  cancel_company,
  create_company,
  add_toast,
  toggle_form_modal,
  form_modal,
  ...rest
}){
  const cancelRequest=()=>{
    cancel_company()
    if(form_modal){
      toggle_form_modal()
    }
  }
  return(
    <CompanyForm
      createCompany={create_company}
      cancelRequest={cancelRequest}
      addToast={add_toast}
      {...rest} />
  )
}

const mapStateToProps=({
  companyReducer,
  globalReducer:{lang},
  loginReducer:{user},
  platformReducer:{form_modal}
})=>({
  ...companyReducer,
  lang,
  user,
  form_modal
})

const mapActionsToProps={
  add_toast,
  toggle_form_modal,
  create_company,
  cancel_company
}

export default connect(mapStateToProps,mapActionsToProps)(CompanyFormCont);
