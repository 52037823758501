import React,{Component} from 'react';
import {
  InputGroup,
  ButtonGroup,
  Button,
  Label,
  ListGroupItem,
  Input
} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export default class EditablePhone extends Component {
  constructor(props){
    super(props)
    this.state={
      editing:false,
      prevValue:''
    }
  }
  componentDidUpdate=(prevProps,prevState)=>{
    const {editAll,setData,editedValue,editPrev,nameInput} = this.props
    const {prevValue,editing} = this.state
    if(!prevProps.editAll&&editAll){
      this.setState(() => {
       return {editing:true}
      })
    }
    if(setData&&setData!==''&&prevValue===''){
      this.setState({
        prevValue:setData
      })
    }
    if(editPrev){
      if(!prevState.editing&&editing&&editedValue===''){
        this.props.editValue({target:{value:setData,name:nameInput}})
      }
    }
  }
  enableEdit=()=>{
    this.setState({editing:true})
  }
  cancelEditing=()=>{
    const {nameInput} = this.props
    const {prevValue} = this.state
    this.setState({
      editing:false
    })
    this.props.editValue({target:{value:prevValue,name:nameInput}})
  }
  doneEditing=()=>{
    this.setState({
      editing:false
    })
  }
  keyHandler=(e)=>{
    if(e.key==='Tab'||e.key==='Enter'){
      this.doneEditing()
    }
    // else if (e.key==='ArrowLeft'){
    //   this.changePossibleQ(false)
    // }
  }
  render(){
    const {setData,nameInput,title,editedValue,editAll,
           lblHidden,className} = this.props
    const {editing,prevValue} = this.state
    return(
      <ListGroupItem
      onKeyUp={this.keyHandler}
      className={`${className} py-0`}
      color={editedValue!==''&&editedValue!==prevValue?'info':''}>
        <Label hidden={lblHidden}>
          {title} <br/>
            <small
            id="phone_help"
            className="form-text text-muted">{setData}</small>
        </Label>
        {editing || editAll
        ? <InputGroup className="my-2 animated fadeInUp">
            <Input required
                onChange={this.props.editValue}
                className={`form-control form-control-sm`}
                size="sm" aria-describedby="phone_help"
                type="tel" value={editedValue}
                name={nameInput}
                // regexp="/^\d{10}$/"
                maxLength="10"
                pattern='[0-9]{10}'
                valid={/^\d{10}$/.test(editedValue)}
                invalid={editedValue.length>=10
                  ?!/^\d{10}$/.test(editedValue)
                  :null
                }
                placeholder={setData||title}/>

            <ButtonGroup>
              <Button size="sm" onClick={this.doneEditing} color="primary">
                <FontAwesomeIcon icon="check"/>
              </Button>
              <Button size="sm" onClick={this.cancelEditing}>
                <FontAwesomeIcon icon="times"/>
              </Button>
            </ButtonGroup>
            &nbsp;

          </InputGroup>
        :<p className="card-text">
          {editedValue===''?setData:editedValue}&nbsp;
          <Button color="link" onClick={this.enableEdit}>
            <FontAwesomeIcon icon="edit"/> Editar
          </Button>
        </p>}
      </ListGroupItem>
    )
  }
}
