import React from 'react';
import {
  Row,
  Col,
  ListGroupItem
} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

function SocialHandlesIcons({handles}){
  return(
    <ListGroupItem>
      {handles
        ?<Row className="justify-content-center social_cont">
             {handles.instagram
             ?<Col xs={1}>

                     <FontAwesomeIcon size="lg" icon={['fab','instagram']}/>
             </Col>
             :null}
             {handles.facebook
             ?<Col xs={1}>

                 <FontAwesomeIcon size="lg" icon={['fab','facebook']}/>
             </Col>
             :null}
             {handles.twitter
             ?<Col xs={1}>

                   <FontAwesomeIcon size="lg" icon={['fab','twitter']}/>
             </Col>
             :null}
             {handles.linkedin
             ?<Col xs={1}>

                 <FontAwesomeIcon size="lg" icon={['fab','linkedin']}/>
             </Col>
             :null}
             {handles.whatsapp
             ?<Col xs={1}>

                 <FontAwesomeIcon size="lg" icon={['fab','whatsapp']}/>

             </Col>
             :null}

           </Row>
        :null
      }
    </ListGroupItem>
  )
}

export default SocialHandlesIcons
