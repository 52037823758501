import React,{Component,Fragment} from 'react';
import {
  Input,
  InputGroup,
  Badge,
  // ButtonGroup,
  Button,
  ListGroupItem,
  Label,
  InputGroupAddon
} from 'reactstrap';

export default class EditableTags extends Component {
  constructor(props){
    super(props)
    this.state={
      editing:false,
      prevValue:[],
      currTag:''
    }
  }
  componentDidUpdate=(prevProps)=>{
    const {editAll,setData} = this.props
    const {prevValue} = this.state
    if(!prevProps.editAll&&editAll){
      this.setState(() => {
       return {editing:true}
      })
    }
    if(setData&&setData.length&&!prevValue.length){
      this.setState({
        prevValue:setData
      })
    }
  }
  enableEdit=()=>{
    const {nameInput} = this.props
    const {prevValue} = this.state
    this.setState({
      editing:true
    })
    this.props.editValue({target:{value:prevValue,name:nameInput}})
  }
  cancelEditing=()=>{
    const {nameInput} = this.props
    const {prevValue} = this.state
    this.setState({
      editing:false
    })
    this.props.editValue({target:{value:prevValue,name:nameInput}})
  }
  doneEditing=()=>{
    this.setState({
      editing:false,
    })
  }
  keyHandler=(e)=>{
    if(e.key==='Enter'){
      this.addTag()
    } else if(e.key==='Tab'){
      this.doneEditing()
    }
    // else if (e.key==='ArrowLeft'){
    //   this.changePossibleQ(false)
    // }
  }
  addTag=()=>{
    const {nameInput,editedValue} = this.props
    const {currTag} = this.state
    const found = editedValue.find((el)=>el===currTag)
    if(currTag!==''&&!found){
      let newTags = [...editedValue,currTag.trim()]
      this.props.editValue({target:{value:newTags,name:nameInput}})
      this.setState({
        currTag:''
      })
    }
  }
  delTag=(id)=>{
    const {nameInput,editedValue} = this.props
    let newTags = [...editedValue.slice(0,id),...editedValue.slice(id+1)]
    this.props.editValue({target:{value:newTags,name:nameInput}})

  }
  getValue=(e)=>{
    this.setState({
      [e.target.name]:e.target.value
    })
  }
  render(){
    const {setData,title,editedValue,className} = this.props
    const {editing,prevValue,currTag} = this.state
    return(

        <ListGroupItem
          onKeyUp={this.keyHandler}
          className={`${className} py-0`}
          color={editedValue.length&&editedValue.length!==prevValue.length?'info':''}>
          <Label>
            <b>
              {title}
            </b>
          </Label>
          <br/>
          {editing
            ?<Fragment>
              <div>
                {editedValue.map((el,i)=>
                  <Badge key={i} className="close_bagde m-1" color="primary">
                    {el}&nbsp;<Button onClick={()=>this.delTag(i)} close />
                  </Badge>
                )}
              </div>
              <InputGroup className="my-2 justify-content-center animated fadeIn">
                <Input bsSize="sm" onChange={this.getValue} value={currTag} name={'currTag'} type="text"/>
                <InputGroupAddon addonType="append">
                    <Button size="sm" onClick={this.addTag} color="primary">
                      <i className="fas fa-plus"/>
                    </Button>
                    <Button size="sm" onClick={this.doneEditing} color="primary">
                      <i className="fas fa-check"/>
                    </Button>
                    <Button size="sm" onClick={this.cancelEditing}>
                      <i className="fas fa-times"/>
                    </Button>
                </InputGroupAddon>
              </InputGroup>
            </Fragment>
            :<Fragment>
              {editedValue.length
                ?editedValue.map((el,i)=>
                <Badge className="m-1" key={i} color="primary">
                  {el}
                </Badge>)
                :setData.map((el,i)=>
                <Badge className="m-1" key={i} color="primary">
                  {el}
                </Badge>)}
              <br/>
              <Button color="link" onClick={this.enableEdit}>
                <i className="fas fa-edit"/> Editar
              </Button>
            </Fragment>
          }
        </ListGroupItem>

    )
  }
}
