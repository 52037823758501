import React,{useEffect} from 'react';
import {connect} from 'react-redux';
import {Verify} from '../../components/forms';
import {start_verify} from '../../redux/actions/signup-actions';
import {PLATF_REDIR_TIMEOUT} from '../../constants/platform';

function VerifyCont({match:{params:{user_id,validation_code}},start_verify,history,customer,...rest}){
  useEffect(()=>{
    start_verify({
      user_id,
      validation_code
    })
    // eslint-disable-next-line
  },[])
  useEffect(()=>{
    setTimeout(()=>{
    history.push('/platform')
    },PLATF_REDIR_TIMEOUT)
  },[customer, history])
  return(
    <Verify {...rest}/>
  )
}

const mapStateToProps=({signupReducer})=>({
  ...signupReducer
})

const mapActionsToProps={
  start_verify
}


export default connect(mapStateToProps,mapActionsToProps)(VerifyCont);
