import React,{Component} from 'react';
import {connect} from 'react-redux';
import {Navb} from '../components/sections';
import {set_lang} from '../redux/actions/global-actions';
import {add_toast} from '../redux/actions/toasts-actions';
import {LOCAL_MESSAGES} from '../constants';
// import {
//   toggle_form_modal,
//   choose_current_form
// } from '../redux/actions/platform-actions';

class NavbCont extends Component{
  render(){
    const {add_toast,set_lang,...rest} = this.props
    const setLanguage=(lang)=>{
      set_lang(lang)
      add_toast({
        error:false,
        text:LOCAL_MESSAGES.LANGUAGE_SET[lang]
      })
    }
    return(
      <Navb

      setLanguage={setLanguage} {...rest}/>
    )
  }
}

export default connect(({
  navbarReducer,
  globalReducer:{lang}
})=>
({
  ...navbarReducer,
  lang
}),{
  set_lang,
  add_toast
})(NavbCont)
