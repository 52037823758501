import { all } from "redux-saga/effects";
import { watchPostDataBeCard } from "./becard-sagas";
import { watchPostDataSignup } from "./signup-sagas";
import { watchPostLogin } from "./login-sagas";
import { watchUser } from "./user-sagas";
import { watchCompany } from "./company-sagas";
import { watchCountry } from "./country-sagas";

export function* greetingsSaga() {
  //    yield console.log('Hi there!');
  yield 1;
  // console.log('hi')
}

export default function* rootSaga() {
  console.log("redux saga setup ok");
  yield all([
    //add your sagas here:
    greetingsSaga(),
    watchPostDataBeCard(),
    watchPostDataSignup(),
    watchPostLogin(),
    watchUser(),
    watchCompany(),
    watchCountry(),
  ]);
}
