export const LIST_COUNTRIES = "LIST_COUNTRIES";
export const SET_ALL_COUNTRIES = "SET_ALL_COUNTRIES";
export const REQUEST_COUNTRY = "REQUEST_COUNTRY";
export const CANCEL_COUNTRY = "CANCEL_COUNTRY";
export const SUCCESS_COUNTRY = "SUCCESS_COUNTRY";
export const ERROR_COUNTRY = "ERROR_COUNTRY";

export const list_countries = () => ({
  type: LIST_COUNTRIES,
});

export const cancel_countries = () => ({
  type: CANCEL_COUNTRY,
  isFetching: false,
});

export const request_countries = () => ({
  type: REQUEST_COUNTRY,
  isFetching: true,
});

export const success_countries = (message) => ({
  type: SUCCESS_COUNTRY,
  message,
});

export const error_countries = (error) => ({
  type: ERROR_COUNTRY,
  error,
});

export const set_all_countries = (countries) => ({
  type: SET_ALL_COUNTRIES,
  countries,
});
