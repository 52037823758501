import {
  REQUEST_USER,
  CANCEL_USER,
  SUCCESS_USER,
  ERROR_USER,
  RESET_USER
} from '../actions/user-actions';

export const userReducer=(
  state={
    isFetching:false,
    message:false,
    error:false,
    sel_user:{}
  },action
)=>{
  const {
    type,
    isFetching,
    error,
    message
  } = action
  switch (type) {
    case REQUEST_USER:
      return({...state,isFetching})
    case CANCEL_USER:
      return({...state,isFetching})
    case SUCCESS_USER:
      return({...state,message})
    case ERROR_USER:
      return({...state,error})
    case RESET_USER:
      return({...state,message,error})
    default:
      return({...state})

  }


}
