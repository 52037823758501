import {
  REQUEST_COMPANY,
  CANCEL_COMPANY,
  SUCCESS_COMPANY,
  ERROR_COMPANY,
  RESET_COMPANY,
  SET_USER_COMPANIES
} from '../actions/company-actions';

export const companyReducer=(
  state={
    isFetching:false,
    error:false,
    message:false,
    user_companies:[]
  },action)=>{
  const {
    type,
    isFetching,
    error,
    message,
    user_companies
  } = action
  switch (type) {
    case REQUEST_COMPANY:
      return ({...state,isFetching})
    case CANCEL_COMPANY:
      return ({...state,isFetching})
    case SUCCESS_COMPANY:
      return ({...state,message})
    case ERROR_COMPANY:
      return ({...state,error})
    case RESET_COMPANY:
      return ({...state,error,message})
    case SET_USER_COMPANIES:
      return ({...state,error,user_companies})

    default:
      return ({...state})

  }

}
