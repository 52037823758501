import {
  REQUEST_COUNTRY,
  CANCEL_COUNTRY,
  SUCCESS_COUNTRY,
  ERROR_COUNTRY,
  SET_ALL_COUNTRIES,
} from "../actions/country-actions";

export const countryReducer = (
  state = {
    isFetching: false,
    message: false,
    error: false,
    countries: [],
  },
  action
) => {
  const { type, isFetching, message, error, countries } = action;

  switch (type) {
    case REQUEST_COUNTRY: {
      return { ...state, isFetching };
    }
    case CANCEL_COUNTRY:
      return { ...state, isFetching };
    case SUCCESS_COUNTRY:
      return { ...state, message };
    case ERROR_COUNTRY:
      return { ...state, error };
    case SET_ALL_COUNTRIES:
      return { ...state, countries };
    default:
      return { ...state };
  }
};
