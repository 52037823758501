import React, { useEffect } from "react";
import {
  Input,
  Button,
  ButtonGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SOCIAL_NETWORKS } from "../../../constants/platform";
import { notEmpty } from "../../../utils";

function SocialHandles({ className, handles, setHandles, name, prevValue }) {
  useEffect(() => {
    if (prevValue && notEmpty(prevValue)) {
      Object.keys(prevValue).map((a, i) => {
        const found = SOCIAL_NETWORKS.find((el) => el.network === a);
        // console.log('prevValue[a]',prevValue[a])
        addHandle(found, prevValue[a]);
        // addValue({target:{value:prevValue[a]}},i)
        return null;
      });
    }
    // eslint-disable-next-line
  }, [prevValue]);
  const addHandle = (hndl, val) => {
    let new_handles = [];
    const found = handles.find((el) => el.network === hndl.network);
    if (found) {
      const ind = handles.indexOf(found);
      new_handles = [...handles.slice(0, ind), ...handles.slice(ind + 1)];
    } else {
      new_handles = [...handles, { ...hndl, value: val ? val : "" }];
    }
    setHandles({
      target: {
        name,
        value: new_handles,
      },
    });
  };
  const addValue = ({ target: { value } }, i) => {
    let upd_handle = [
      ...handles.slice(0, i),
      {
        ...handles[i],
        value: handles[i].urlEx
          ? value.replace(handles[i].urlEx, "").trim()
          : value.trim(),
      },
      ...handles.slice(i + 1),
    ];
    setHandles({
      target: {
        name,
        value: upd_handle,
      },
    });
  };
  return (
    <>
      <ButtonGroup className={`${className}`}>
        {SOCIAL_NETWORKS.map((el, i) => (
          <Button
            key={i}
            onClick={() => addHandle(el)}
            color="primary"
            outline
            active={!!handles.find((obj) => obj.network === el.network)}
          >
            <FontAwesomeIcon icon={["fab", el.faIcon]} />
          </Button>
        ))}
      </ButtonGroup>
      {handles.map(
        ({ network, faIcon, placeholder, urlEx, type, regexp, ...rest }, i) => (
          <Row key={i}>
            <Col>
              <InputGroup
                size="sm"
                className="my-2 justify-content-center animated fadeIn"
              >
                {urlEx ? (
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>{urlEx}</InputGroupText>
                  </InputGroupAddon>
                ) : null}
                <Input
                  onChange={(e) => addValue(e, i)}
                  value={handles[i].value}
                  placeholder={placeholder}
                  name={network}
                  type={type ? type : "text"}
                  invalid={
                    regexp && handles[i].value !== ""
                      ? !regexp.test(handles[i].value)
                      : null
                  }
                  valid={
                    regexp && handles[i].value !== ""
                      ? regexp.test(handles[i].value)
                      : null
                  }
                  {...rest}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <FontAwesomeIcon icon={["fab", faIcon]} />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>
          </Row>
        )
      )}
    </>
  );
}

export default SocialHandles;
