import React, { Component, Fragment } from "react";
import {
  Form,
  FormGroup,
  Col,
  FormText,
  Input,
  Label,
  Row,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  // Collapse
} from "reactstrap";
import { TagsInput, UploadFiles, SocialHandles } from "./questTypes";
import { EditableImg } from "./editableEls";
import { AnimSend } from "../elements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LABELS_FORM_COMPANY } from "../../constants/platform";
// import {INSTRUMENT_OPTIONS} from '../../globals'
import {
  inputToDate,
  // dateToInput
} from "../../utils";
import data from "../../assets/bodym/sending.json";

export default class CompanyForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company_name: "",
      commercial_name: "",
      phone: "",
      phone_ext: "",
      handle: "",
      tax_id: "",
      main_url: "",
      description_es: "",
      description_en: "",
      industry_es: "",
      industry_en: "",
      foundation_date: "",
      email: "",
      employees: [],
      tags: [],
      socialmedia_handles: [],
      iconForCrop: [],
      logoForCrop: [],
      cropped_icon: [],
      cropped_logo: [],
      profile_media: [],
      addAddress: false,
      city: "",
      line1: "",
      line2: "",
      postal_code: "",
      state: "",
      location_link: "",
      shipping: false,
    };
  }
  componentDidUpdate = (prevProps) => {
    const { isFetching } = this.props;
    const { isFetching: wasFetching } = prevProps;
    if (wasFetching && !isFetching) {
      this.resetForm();
    }
  };
  componentDidMount = () => {
    if (process.env.REACT_APP_PREFILL_DATA) {
      this.setState({
        company_name: "N12 Estudio SAS de CV",
        commercial_name: "N12 Estudio",
        phone: "5514540468",
        phone_ext: "",
        handle: "n12mx",
        tax_id: "NES170504FA9",
        main_url: "https://www.n12.mx",
        description_es:
          "Maximizamos las experiencias digitales que ofrecen las marcas",
        // description_en:'',
        industry_es: "Tecnología",
        // industry_en:'',
        foundation_date: "2017-05-04",
        email: "connection@n12.mx",
        // employees:[],
        tags: ["Tech", "Development", "Marketing"],
      });
    }
  };
  resetForm = () => {
    this.setState({});
    // this.props.toggle()
  };
  getValue = (e) => {
    const { value, name, type, checked } = e.target;
    const val = type === "checkbox" ? checked : value;
    this.setState({
      [name]: val,
    });
  };
  getHandle = (e) => {
    const { value, name } = e.target;
    this.setState({
      [name]: value.replace(/\s/g, " ").trim(),
    });
  };
  endAnim = () => {
    this.props.cancelRequest();
  };
  loadCropImg = ({ target: { files } }) => {
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        this.setState({ iconForCrop: reader.result });
      });
      reader.readAsDataURL(files[0]);
    }
  };
  getFiles = ({ target: { files } }) => {
    this.setState({ cropped_icon: files });
  };
  startSend = (e) => {
    e.preventDefault();
    const {
      company_name,
      commercial_name,
      phone,
      phone_ext,
      handle,
      tax_id,
      main_url,
      description_es,
      // description_en,
      industry_es,
      // industry_en,
      foundation_date,
      email,
      employees,
      tags,
      socialmedia_handles,
      cropped_icon,
    } = this.state;
    let handlesMap = socialmedia_handles.reduce((obj, { network, value }) => {
      obj[network] = value;
      return obj;
    }, {});
    const data = {
      company_name,
      commercial_name,
      phone,
      phone_ext,
      handle,
      tax_id,
      main_url,
      description: {
        es: description_es,
      },
      industry: {
        es: industry_es,
      },
      foundation_date: inputToDate(foundation_date),
      email,
      employees,
      tags,
      socialmedia_handles: handlesMap,
    };
    // if(showBirthday)data.birthDate = inputToDate(birthDate)
    // if(profile_img)data.profile_img = profile_img
    if (cropped_icon.length) {
      let images = new FormData();
      for (let i = 0; i < cropped_icon.length; i++) {
        images.append("images", cropped_icon[i]);
      }
      this.props.createCompany(data, images);
    } else {
      this.props.createCompany(data);
    }
  };
  render() {
    const { isFetching, lang, user } = this.props;
    const {
      company_name,
      commercial_name,
      phone,
      phone_ext,
      handle,
      tax_id,
      // addAddress,
      main_url,
      description_es,
      // description_en,
      industry_es,
      // industry_en,
      foundation_date,
      email,
      // employees,
      socialmedia_handles,
      iconForCrop,
      // logoForCrop,
      tags,
    } = this.state;
    return (
      <Form onSubmit={this.startSend}>
        {isFetching ? (
          <Row className="justify-content-center">
            <Col xs={12} sm={6}>
              <AnimSend data={data} endAnim={this.endAnim} />
            </Col>
          </Row>
        ) : (
          <Fragment>
            <FormGroup row>
              <Label for="email" sm={2}>
                {LABELS_FORM_COMPANY.EMAIL[lang]}*
              </Label>
              <Col sm={10}>
                <Input
                  onChange={this.getValue}
                  value={email}
                  type="email"
                  name="email"
                  id="email"
                  required
                  placeholder="somebody@company.com"
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="company_name" sm={2}>
                {LABELS_FORM_COMPANY.COMPANY_NAME[lang]}
              </Label>
              <Col sm={10}>
                <Input
                  onChange={this.getValue}
                  value={company_name}
                  type="text"
                  name="company_name"
                  id="company_name"
                  placeholder={LABELS_FORM_COMPANY.COMPANY_NAME_PLHLD[lang]}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="commercial_name" sm={2}>
                {LABELS_FORM_COMPANY.COMMERCIAL_NAME[lang]}*
              </Label>
              <Col sm={10}>
                <Input
                  onChange={this.getValue}
                  value={commercial_name}
                  type="text"
                  name="commercial_name"
                  id="commercial_name"
                  placeholder={LABELS_FORM_COMPANY.COMMERCIAL_NAME_PLHLD[lang]}
                  required
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="description_es" sm={2}>
                {LABELS_FORM_COMPANY.DESCRIPTION[lang]}*
              </Label>
              <Col sm={10}>
                <Input
                  onChange={this.getValue}
                  value={description_es}
                  type="textarea"
                  name="description_es"
                  id="description_es"
                  placeholder={LABELS_FORM_COMPANY.DESCRIPTION_PLHLD[lang]}
                  required
                />
                <FormText>
                  {LABELS_FORM_COMPANY.DESCRIPTION_EXPL[lang]}
                </FormText>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="industry_es" sm={2}>
                {LABELS_FORM_COMPANY.INDUSTRY[lang]}*
              </Label>
              <Col sm={10}>
                <Input
                  onChange={this.getValue}
                  value={industry_es}
                  type="text"
                  name="industry_es"
                  id="industry_es"
                  placeholder="Industria"
                  required
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="handle" sm={2}>
                {LABELS_FORM_COMPANY.HANDLE[lang]}*
              </Label>
              <Col sm={10}>
                <InputGroup className="">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FontAwesomeIcon icon={"at"} />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    onChange={this.getHandle}
                    value={handle}
                    type="text"
                    name="handle"
                    id="handle"
                    placeholder="becardsappmx"
                    required
                  />
                </InputGroup>

                <FormText>{LABELS_FORM_COMPANY.HANDLE_EXPL[lang]}</FormText>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="tax_id" sm={2}>
                {LABELS_FORM_COMPANY.TAX_ID[lang]}*
              </Label>
              <Col sm={10}>
                <Input
                  onChange={this.getValue}
                  value={tax_id}
                  type="text"
                  name="tax_id"
                  id="tax_id"
                  required
                  placeholder="AAA200413FFF"
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="main_url" sm={2}>
                {LABELS_FORM_COMPANY.MAIN_URL[lang]}
              </Label>
              <Col sm={10}>
                <Input
                  onChange={this.getValue}
                  value={main_url}
                  type="text"
                  name="main_url"
                  id="main_url"
                  placeholder="http://website.com"
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="phone" sm={2}>
                {LABELS_FORM_COMPANY.PHONE[lang]}
              </Label>
              <Col sm={8}>
                <Input
                  onChange={this.getValue}
                  value={phone}
                  name="phone"
                  id="phone"
                  type="text"
                  // regExp="/^\d{10}$/"
                  pattern="[0-9]{10}"
                  valid={/^\d{10}$/.test(phone)}
                  invalid={phone.length >= 10 ? !/^\d{10}$/.test(phone) : null}
                  placeholder={"Teléfono a 10 dígitos p.ej. 1234567890"}
                />
                <FormText>{LABELS_FORM_COMPANY.PHONE_EXPL[lang]}</FormText>
              </Col>
              <Col sm={2}>
                <Input
                  onChange={this.getValue}
                  value={phone_ext}
                  name="phone_ext"
                  id="phone_ext"
                  type="text"
                  // regExp="/^\d{10}$/"
                  placeholder={"Extensión"}
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label sm={2}>Social handles</Label>
              <Col sm={10}>
                <SocialHandles
                  setHandles={this.getValue}
                  name="socialmedia_handles"
                  handles={socialmedia_handles}
                />
              </Col>
            </FormGroup>
            {/* <FormGroup row>
              <Label sm={2}>
                {LABELS_FORM_COMPANY.ADD_ADDRESS[lang]}
              </Label>
              <Col sm={10}>
                <Label check className="switch">
                   <input type="checkbox" onChange={this.getValue} name="addAddress" checked={addAddress}/>
                   <span className="slider round"/>
                 </Label>
               </Col>
            </FormGroup>
            <Collapse isOpen={addAddress}>

            </Collapse> */}
            <FormGroup row>
              <Label for="foundation_date" sm={2}>
                {LABELS_FORM_COMPANY.FOUNDATION_DATE[lang]}
              </Label>
              <Col sm={10}>
                <Input
                  onChange={this.getValue}
                  value={foundation_date}
                  type="date"
                  name="foundation_date"
                  id="foundation_date"
                  placeholder="Fundación"
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={2}>{LABELS_FORM_COMPANY.TAGS[lang]}</Label>
              <Col sm={10}>
                <TagsInput
                  getValue={this.getValue}
                  addToast={this.props.addToast}
                  nameInput="tags"
                  lang={lang}
                  tags={tags}
                />
                <FormText>{LABELS_FORM_COMPANY.TAGS_EXPL[lang]}</FormText>
              </Col>
            </FormGroup>
            <UploadFiles
              isRow={true}
              inputCol={10}
              labelCol={2}
              formText={LABELS_FORM_COMPANY.LOGO_UPLOAD[lang]}
              title={LABELS_FORM_COMPANY.IMAGE_FORM_TITLE[lang]}
              accept="image/jpeg,image/png"
              classLbl=""
              className="mt-3"
              getFiles={this.loadCropImg}
              gotFiles={iconForCrop.length}
              name="cropped_icon"
            />
            <EditableImg
              initialCrop={{
                unit: "px",
                width: 600,
                // height:300,
                // aspect: 16/9
                aspect: 1,
              }}
              handle={handle}
              user={user}
              getFiles={this.getFiles}
              src={iconForCrop}
            />
            <FormGroup>
              <Button
                // onClick={this.startSend}
                block
                color="primary"
                disabled={isFetching}
                type="submit"
              >
                {LABELS_FORM_COMPANY.PUBLISH[lang]}
              </Button>
            </FormGroup>
          </Fragment>
        )}
      </Form>
    );
  }
}
