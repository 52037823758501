import React from 'react';
import {Container} from 'reactstrap';
import {SEO} from '../elements';
import {
  JumboColor,
  Footer
} from '../sections';
import {
  CardsFilterCont,
  CardsRosterCont
} from '../../containers'

function BeCardsDir (){
  return(
    <section className="soft_bg">
      <SEO title="Directorio" description="Encuentra al profesional que necesitas"/>
      <JumboColor
        header={'Directorio de BeCards'}
        lead={'Encuentra al profesional que necesitas'}/>
      {/* <Container className="bg_grayBlue" fluid> */}
          <Container className="pb-5">
                <CardsFilterCont/>
                <CardsRosterCont/>
           </Container>
        {/* </Container> */}
     <Footer/>
  </section>
  )
}

export default BeCardsDir;
