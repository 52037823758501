import React,{Component,Fragment} from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  // CardHeader,
  CardFooter,
  CardBody,
  CardText,
  CardTitle,
  Button
} from 'reactstrap';
import {EditableProfile} from '../../forms';
import {optDate} from '../../../utils';
import profile from '../../../assets/img/utils/profile.png'
import '../../../style/profile.css';

export default class UserProfile extends Component {
  constructor(props){
    super(props)
    this.state={
      edit:false
    }
  }
  toggleEdit=()=>{
    this.setState({
      edit:!this.state.edit
    })
  }
  render(){
    const {className,user:{firstname,lastname,birthDate,telephone,email,profile_img},...rest} = this.props
    const {edit} = this.state
    // let bg_img={backgroundImage:`url(${profile_img})`}
    return(
      <Container className={`${className}`}>
        <Row className="justify-content-center">
          {edit
            ? <Col className="justify-content-center" xs={12} lg={12}>
              <EditableProfile
                updateUser={this.props.updateUser}
                toggleChangedUser={this.props.toggleChangedUser}
                cancelRequest={this.props.cancelRequest}
                toggleEdit={this.toggleEdit}
                user={this.props.user} {...rest}/>
                <br/>
                <Button onClick={this.toggleEdit} color="link">
                  Terminar
                </Button>
            </Col>
            :<Col className="justify-content-center" xs={12} lg={12}>
              <Fragment>
                <div className="media mx-auto d-flex justify-content-center overlapping_profile_img">
                  <img src={profile_img?profile_img:profile} className="img_circular rounded-circle img-fluid" width="140px"  alt="fotografia usuario"/>
                </div>
                <Card className={`pt-5 profile_card`}>
                  <CardBody className="d-flex flex-column justify-content-center">

                    <CardTitle>
                      {firstname
                        ?<Fragment>
                          <i className="fas fa-user"/> {`${firstname} ${lastname}`}
                        </Fragment>
                        : <Button onClick={this.props.fillProfile} color="link">Completa tu perfil</Button>
                      }

                    </CardTitle>
                    {telephone
                      ?<CardText tag={'div'} className="text-center d-flex flex-column">
                          <div><i className="fas fa-mobile"/>&nbsp;{`  ${telephone} `}</div><br/>
                          <div><i className="fas fa-at"/>&nbsp;{`  ${email} `}</div><br/>
                          <div><i className="fas fa-birthday-cake"/>&nbsp;{`  ${new Date(birthDate).toLocaleString('es-MX',optDate)} `}</div>
                      </CardText>
                      :null
                    }

                  </CardBody>
                  <CardFooter>
                    {firstname

                      ? <Button onClick={this.toggleEdit} color="link">
                          Editar
                        </Button>
                      :null
                    }
                  </CardFooter>
                </Card>
              </Fragment>
            </Col>
          }
        </Row>
      </Container>
    )
  }
}
