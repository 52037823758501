import React from 'react';
import {
  Container,
  Row,
  Col
} from 'reactstrap';
import {RosterCompanyCard} from './';
import {GhostCard} from '../../elements';
import {LOCAL_MESSAGES} from '../../../constants';

import '../../../style/roster.css'

function CompanyRoster({lang,isFetching,className,user_companies,platform}){
  return(
    <Container>
      {isFetching
        ? <Row>
          <Col>
            <GhostCard
              company
              className="mb-3"
             />
          </Col>
        </Row>
        : <>
        {platform
          ? <>
            {user_companies.length
              ? <Row>
                {user_companies.map((company,i)=><Col xs={12} key={i}>
                    <RosterCompanyCard
                      className="animated fadeInPlace"
                      lang={lang}
                      info={company}/>
                </Col>)}
              </Row>
              : <>
                <Row>
                  <Col>
                    <h3 className="text-center animated fadeInPlace">
                      {LOCAL_MESSAGES.EMPTY_COMPANIES[lang]}
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <GhostCard
                      company
                      className="mb-3"
                     />
                  </Col>
                </Row>
              </>
            }
          </>
          : <>

          </>
        }
        </>
      }

    </Container>
  )
}

export default CompanyRoster;
