import React from 'react';
import {
  Alert,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import {
  ProfileFormCont,
  BeCardFormCont,
  CompanyFormCont
} from '../../containers';
import {FORMS,LABELS_PLATFORM} from '../../constants/platform'


const FormModal =({
  lang,
  form_modal,
  className,
  toggle,
  sel_form,
  children
})=>{
  const {title={},notice} = FORMS[sel_form]
  return (
    <div>
          <Modal isOpen={form_modal} toggle={toggle} size="lg" backdrop="static" className={className}>
            <ModalHeader toggle={toggle}>{title[lang]}</ModalHeader>
            <ModalBody>
              {notice
                ?<Alert color="warning" className="multiline">
                  {notice[lang]}
                </Alert>
                :null
              }
              {sel_form==='profileUpdate'
                ? <ProfileFormCont/>
                :null
              }
              {sel_form==='BeCard'
                ? <BeCardFormCont />
                :null
              }
              {sel_form==='Company'
                ? <CompanyFormCont />
                :null
              }
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggle}>
                {LABELS_PLATFORM.CLOSE[lang]}
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      )
}

export default FormModal
