import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Jumbotron,
  Container,
  Row,
  Col,
  Button,
  Collapse,
  Popover,
  PopoverHeader,
  PopoverBody,
  NavLink
} from "reactstrap";
import { SEO } from "../elements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BackgroundBeCard } from "../elements/icons";

import {
  GET_CONTACT_LABEL,
  WHATSAPP_MESSAGE,
  MAIL_SUBJECT,
  LABELS_BECARD,
  NO_COMPANY_HANDLE,
  VERIFIED_STATUS
} from "../../constants";
import { SOCIAL_NETWORKS } from "../../constants/platform";
import { shorten } from "../../utils";
import "../../style/becards.css";

const maxBioLength = 120;

function BeCard({ becard, lang, countries }) {
  const [showBio, setShowBio] = useState(false);
  const [popoverOpen, setPopover] = useState(false);
  const togglePop = () => setPopover(!popoverOpen);
  const toggle = () => {
    setShowBio(!showBio);
  };

  const {
    _id,
    profile_img,
    email,
    company,
    firstname,
    lastname,
    theme,
    job_title = { es: "", en: "" },
    country,
    bio = { es: "", en: "" },
    socialmedia_handles,
    qr_code,
    link_card,
    vcf,
    handle,
    colors,
    decorativeHeaderImg,
    website,
    bgElementsStyle
  } = becard;

  const blob = new Blob([vcf], { type: "text/vcard;charset=utf-8" });
  const blobDownload = URL.createObjectURL(blob);
  const verified = company?.status.slice(-1)[0].status === VERIFIED_STATUS;
  const countryFound = countries.find((el) => el.cca2 === country);
  let countryCode = "";
  if (countryFound) {
    countryCode = `${countryFound.idd?.root}${
      countryFound.idd?.suffixes?.length === 1
        ? countryFound.idd?.suffixes[0]
        : ""
    }`;
  }

  const {
    colors: companyColors,
    theme: companyTheme,
    bgElementsStyle: companyBgElementsStyle
  } = company ?? {};

  const langBio = bio[lang];
  const showBioCollapse = langBio?.length > maxBioLength;

  const currentTheme = theme ?? companyTheme;
  const currentColors = colors ?? companyColors ?? {};
  const currentBgElementsStyle =
    bgElementsStyle ?? companyBgElementsStyle ?? {};

  const backgroundStyle = {
    background: currentColors?.complimentary ?? "#e95a4b",
    minHeight: "100vh"
  };

  return (
    <>
      <SEO
        cover={profile_img || null}
        title={firstname ? `${firstname} ${lastname}` : ""}
        description={
          firstname
            ? `BeCard de ${firstname} ${lastname} descarga mis datos 📲 en BeCards.app`
            : null
        }
        location={
          handle
            ? `cards/${company ? company.name : NO_COMPANY_HANDLE}/${handle}`
            : ""
        }
      />
      <Jumbotron
        className="d-flex flex-column justify-content-center abs_cont mb-0"
        fluid
        style={backgroundStyle}>
        <BackgroundBeCard
          className="bg_float"
          primary={currentColors?.primary}
          secondary={currentColors?.secondary}
          highlights={currentBgElementsStyle.highlights ?? true}
          overlay={currentBgElementsStyle.overlay ?? true}
        />
        {/* <img alt="background" className="bg_float" src={bg_card} /> */}
        {_id ? (
          <Container
            className={`${
              currentTheme === "light" ? "text-light" : "text-dark"
            } align-items-center`}
            fluid>
            <Row className="justify-content-center mb-4">
              <Col
                xs={{ size: 12, order: 2 }}
                md={{ size: 7, order: 1 }}
                className="info_cont d-flex flex-column justify-content-center align-items-center">
                {decorativeHeaderImg ? (
                  <img
                    className="img-fluid decorative_header_img"
                    src={decorativeHeaderImg}
                    alt="decorative header"
                  />
                ) : null}
                <h1 className="name_title animated fadeInUp mb-2">
                  {`${firstname} ${lastname}`}
                </h1>
                <h3 className="job_title animated fadeInUp mb-3">
                  {`${job_title[lang]} `}
                  {company ? (
                    <a
                      className={
                        currentTheme === "light" ? "text-light" : "text-dark"
                      }
                      href={company.main_url}
                      target="_blank"
                      rel="noopener noreferrer">
                      {`@ ${company.commercial_name}`}
                    </a>
                  ) : (
                    ""
                  )}
                </h3>
                <Row className="justify-content-center social_cont mb-3">
                  {email ? (
                    <Col>
                      <a
                        className={
                          currentTheme === "light" ? "text-light" : "text-dark"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`mailto:${email}?subject=${MAIL_SUBJECT}&body=${WHATSAPP_MESSAGE}${link_card}`}>
                        <FontAwesomeIcon size="lg" icon="paper-plane" />
                      </a>
                    </Col>
                  ) : null}
                  {socialmedia_handles.instagram ? (
                    <Col>
                      <a
                        className={
                          currentTheme === "light" ? "text-light" : "text-dark"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${SOCIAL_NETWORKS[1].urlEx}${socialmedia_handles.instagram}`}>
                        <FontAwesomeIcon
                          size="lg"
                          icon={["fab", "instagram"]}
                        />
                      </a>
                    </Col>
                  ) : null}
                  {socialmedia_handles.telegram ? (
                    <Col>
                      <a
                        className={
                          currentTheme === "light" ? "text-light" : "text-dark"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${SOCIAL_NETWORKS[5].urlEx}${socialmedia_handles.telegram}`}>
                        <FontAwesomeIcon size="lg" icon={["fab", "telegram"]} />
                      </a>
                    </Col>
                  ) : null}
                  {socialmedia_handles.facebook ? (
                    <Col>
                      <a
                        className={
                          currentTheme === "light" ? "text-light" : "text-dark"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${SOCIAL_NETWORKS[0].urlEx}${socialmedia_handles.facebook}`}>
                        <FontAwesomeIcon size="lg" icon={["fab", "facebook"]} />
                      </a>
                    </Col>
                  ) : null}
                  {socialmedia_handles.twitter ? (
                    <Col>
                      <a
                        className={
                          currentTheme === "light" ? "text-light" : "text-dark"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${SOCIAL_NETWORKS[2].urlEx}${socialmedia_handles.twitter}`}>
                        <FontAwesomeIcon size="lg" icon={["fab", "twitter"]} />
                      </a>
                    </Col>
                  ) : null}
                  {socialmedia_handles.linkedin ? (
                    <Col>
                      <a
                        className={
                          currentTheme === "light" ? "text-light" : "text-dark"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${SOCIAL_NETWORKS[3].urlEx}${socialmedia_handles.linkedin}`}>
                        <FontAwesomeIcon size="lg" icon={["fab", "linkedin"]} />
                      </a>
                    </Col>
                  ) : null}
                  {socialmedia_handles.whatsapp ? (
                    <Col>
                      <a
                        className={
                          currentTheme === "light" ? "text-light" : "text-dark"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://wa.me/${countryCode}${socialmedia_handles.whatsapp}?text=${WHATSAPP_MESSAGE}${link_card}`}>
                        <FontAwesomeIcon size="lg" icon={["fab", "whatsapp"]} />
                      </a>
                    </Col>
                  ) : null}
                  {website ? (
                    <Col>
                      <a
                        className={
                          currentTheme === "light" ? "text-light" : "text-dark"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        href={website}>
                        <FontAwesomeIcon size="lg" icon={["fas", "link"]} />
                      </a>
                    </Col>
                  ) : null}
                </Row>

                <section className="animated fadeIn mb-3 d-print-none">
                  {showBioCollapse ? (
                    <>
                      {showBio ? null : (
                        <p>{`${shorten(langBio, maxBioLength)}...`}</p>
                      )}
                      <Collapse className="animated fadeIn" isOpen={showBio}>
                        <p>{langBio}</p>
                      </Collapse>
                    </>
                  ) : (
                    <p>{langBio}</p>
                  )}
                  <br />
                  {showBioCollapse ? (
                    <Button
                      onClick={toggle}
                      className={`mb-2`}
                      outline
                      color="light"
                      size="sm">
                      {LABELS_BECARD.EXPAND[lang]}&nbsp;
                      <FontAwesomeIcon
                        size="sm"
                        icon={showBio ? "minus-circle" : "plus-circle"}
                      />
                    </Button>
                  ) : null}
                </section>
                <Row className="mt-1 justify-content-between">
                  <Col className="d-flex align-items-center justify-content-center">
                    <Row className="justify-content-center align-items-center text-center">
                      <Col>
                        <Button
                          tag={"a"}
                          download={`${firstname}_${lastname}${
                            company ? `_${company.handle}` : ""
                          }.vcf`}
                          href={blobDownload}
                          style={{
                            ...(currentColors?.secondary && {
                              background: currentColors.secondary,
                              borderColor: currentColors.secondary,
                              color: currentColors.text
                            })
                          }}
                          color="primary">
                          {GET_CONTACT_LABEL[lang]}&nbsp;&nbsp;
                          <FontAwesomeIcon icon={"id-badge"} />
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={6}>
                    <img
                      className="qr_code_card img-fluid"
                      src={qr_code}
                      alt="qr code"
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                xs={{ size: 12, order: 1 }}
                md={{ size: 5, order: 2 }}
                className="mb-2 d-flex flex-column justify-content-center align-items-center profile_img_cont">
                <div className="abs_cont prof_img_cont">
                  <img
                    className="img-fluid rounded-circle shadow profile_img animated fadeIn"
                    src={profile_img}
                    alt={`${firstname} ${lastname}`}
                  />
                  {company ? (
                    <img
                      className="img-fluid company_icon rounded-circle shadow"
                      src={company.icon_img}
                      alt="company icon"
                    />
                  ) : null}
                  {company && verified ? (
                    <>
                      <FontAwesomeIcon
                        onMouseEnter={togglePop}
                        onMouseLeave={togglePop}
                        className="verified_icon text-primary"
                        size="lg"
                        id="info_verified"
                        icon="check-double"
                      />
                      <Popover
                        placement="bottom"
                        isOpen={popoverOpen}
                        target="info_verified"
                        toggle={toggle}>
                        <PopoverHeader>
                          {LABELS_BECARD.IS_VERIFIED[lang]}
                          &nbsp;
                          <FontAwesomeIcon icon="certificate" />
                        </PopoverHeader>
                        <PopoverBody>
                          {LABELS_BECARD.IS_VERIFIED_BODY[lang]}
                        </PopoverBody>
                      </Popover>
                    </>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <NavLink
                  className={
                    currentTheme === "light" ? "text-light" : "text-dark"
                  }
                  tag={Link}
                  to="/login?card=signup">
                  ¡Haz la tuya gratis! <FontAwesomeIcon icon="rocket" />
                </NavLink>
              </Col>
            </Row>
          </Container>
        ) : null}
      </Jumbotron>
    </>
  );
}

export default BeCard;
