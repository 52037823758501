import React,{useEffect} from 'react';
import {connect} from 'react-redux';
import {CompanyRoster} from '../../components/platform/elements';
import {list_user_companies} from '../../redux/actions/company-actions';

function CompanyRosterCont({
  platform,
  user,
  list_user_companies,
  user_companies,
  ...rest
}){
  useEffect(()=>{
    if(platform&&user&&!user_companies.length){
      list_user_companies()
    }
  },[list_user_companies, platform, user, user_companies.length])
  return(
    <CompanyRoster
      user_companies={user_companies}
      platform={platform}
      {...rest}/>
  )
}

const mapStateToProps=({
  companyReducer,
  globalReducer:{lang},
  loginReducer:{user},
  filterReducer:{filtered}
})=>({
  ...companyReducer,
  lang,
  user,
  filtered
})

const mapActionsToProps ={
  list_user_companies
}

export default connect(mapStateToProps, mapActionsToProps)(CompanyRosterCont);
