import React from 'react';
import {Card,CardBody,CardImg,CardFooter} from 'reactstrap';
import profile_img from '../../assets/img/utils/profile_ghost.png'
import '../../style/ghostcard.css';

function GhostCard({className}){
  return(
    <Card className={`ghost_card ${className}`}>
      <CardImg src={profile_img} className="img_circular rounded-circle p-4"  />
      <CardBody>
      </CardBody>
      <CardFooter/>
    </Card>
  )
}

export default GhostCard;
