import React, { useEffect } from "react";
import { connect } from "react-redux";
import { EditableBeCard } from "../../components/forms";
import {
  start_update_becard,
  cancel_one_becard,
  set_sel_becard,
} from "../../redux/actions/becard-actions";
import { list_countries } from "../../redux/actions/country-actions";
import { toggle_slide_menu } from "../../redux/actions/platform-actions";

function EditableBeCardCont({
  slide_menu,
  cancel_one_becard,
  set_sel_becard,
  start_update_becard,
  toggle_slide_menu,
  list_countries,
  countriesError,
  countries,
  ...rest
}) {
  useEffect(() => {
    if (!countriesError && !countries.length) {
      list_countries();
    }
  }, [countries, countriesError, list_countries]);
  const cancelRequest = () => {
    set_sel_becard({});
    cancel_one_becard();
    if (slide_menu) {
      toggle_slide_menu();
    }
  };
  return (
    <EditableBeCard
      updateBeCard={start_update_becard}
      cancelRequest={cancelRequest}
      countries={countries}
      {...rest}
    />
  );
}

const mapStateToProps = ({
  becardReducer,
  globalReducer: { lang },
  platformReducer: { slide_menu },
  countryReducer: {
    countries,
    isFetching: isFetchingCountries,
    error: countriesError,
  },
}) => ({
  ...becardReducer,
  slide_menu,
  lang,
  countries,
  isFetchingCountries,
  countriesError,
});

const mapActionsToProps = {
  start_update_becard,
  cancel_one_becard,
  toggle_slide_menu,
  set_sel_becard,
  list_countries,
};

export default connect(mapStateToProps, mapActionsToProps)(EditableBeCardCont);
