import React,{useEffect} from 'react';
import {connect} from 'react-redux';
import {renew_token} from '../../redux/actions/login-actions';
import {PLATFORM_ROUTES} from '../../constants/platform';

function CheckLoginCont({history,user,token,renew_token}){
  useEffect(()=>{
    if(token&&!user){
      renew_token()
    } else if(!token&&!user){
      history.push(PLATFORM_ROUTES.LOGIN)
    }
    // eslint-disable-next-line
  },[])
  return(
    <div/>
  )
}

const mapStateToProps=({
  loginReducer
})=>({
  ...loginReducer
})

const mapActionsToProps={
  renew_token,
}

export default connect(mapStateToProps,mapActionsToProps)(CheckLoginCont);
