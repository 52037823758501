import React from 'react';
import {Link} from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Button
} from 'reactstrap';

function CallToAction({title,subtitle,actionImg,background,goTo,mainBtnText}){
  return(
    <Container className="abs_cont" fluid>
      {background&&<img alt="background" className={background.className} src={background.src}/>}
      <Container>
        <Row className="text-light text-left align-items-center">
          <Col>
            <h3 className="">
              {subtitle}
            </h3>
            <h2 className="sect_title">
              {title}
            </h2>
            <Button tag={Link} to={goTo} color="primary" className="mt-2">
              {mainBtnText}
            </Button>
          </Col>
          <Col xs={12} md={7}>
            <img className="img-fluid" src={actionImg.src} alt={actionImg.alt}/>
          </Col>
        </Row>

      </Container>
    </Container>
  )
}

export default CallToAction
