import {
  SET_LANG
} from '../actions/global-actions.js';

export const globalReducer=(
  state={
    lang:'es'
  },
  action
)=>{
  const {
    type,
    lang
  } = action
  switch (type) {
    case SET_LANG:
      return({...state,lang})

    default:
      return({...state})

  }
}
