import {
  takeLatest,
  put,
  call,
  //select
} from "redux-saga/effects";
import * as actions from "../redux/actions/company-actions";
import { add_toast } from "../redux/actions/toasts-actions";
import { api_public, api_private } from "../services";
import { getStateLang, getLoginUser } from "./utils";
import { LOCAL_MESSAGES } from "../constants";

function* postDataCompany({ dataCompany, images }) {
  yield put(actions.reset_company());
  yield put(actions.request_company());
  const lang = yield getStateLang();
  try {
    if (images) {
      const upload = yield call(api_private.uploadImages, images);
      if (upload.image_urls && upload.image_urls.length) {
        let dataImg = {
          ...dataCompany,
          icon_img: upload.image_urls[0],
        };
        const created = yield call(api_private.createCompany, dataImg);
        if (created.company) {
          yield put(
            add_toast({
              error: false,
              text: created.message[lang],
            })
          );
          yield getUserCompanies();
        }
      }
    } else {
      const created = yield call(api_private.createCompany, dataCompany);
      if (created.company) {
        yield put(
          add_toast({
            error: false,
            text: created.message[lang],
          })
        );
        yield getUserCompanies();
      } else if (created.error) {
        yield put(actions.error_company(created.error));
        yield put(
          add_toast({
            error: true,
            text: created.error[lang],
          })
        );
        yield put(actions.cancel_company());
      }
    }
  } catch (error) {
    yield put(actions.error_company(error));
    yield put(
      add_toast({
        error: true,
        text: error.toString(),
      })
    );
    yield put(actions.cancel_company());
  }
}

function* getUserCompanies() {
  yield put(actions.reset_company());
  yield put(actions.request_company());
  try {
    const user = yield getLoginUser();
    const lang = yield getStateLang();
    const companies = yield call(
      api_public.getAllCompanies,
      `?search=true&user=${user._id}`
    );
    if (companies.error) {
      yield put(actions.error_company(companies.error));
      yield put(actions.cancel_company());
    } else {
      yield put(actions.success_company(companies.message));
      yield put(
        add_toast({
          error: false,
          text: LOCAL_MESSAGES.USER_COMPANIES[lang],
        })
      );
      yield put(actions.set_user_companies(companies.companies));

      yield put(actions.cancel_company());
    }
  } catch (error) {
    yield put(actions.error_company());
    yield put(
      add_toast({
        error: true,
        text: error.toString(),
      })
    );
    yield put(actions.cancel_company());
  }
}

// function* getAllCompanies(){
//
// }

export function* watchCompany() {
  yield takeLatest(actions.CREATE_COMPANY, postDataCompany);
  yield takeLatest(actions.LIST_USER_COMPANIES, getUserCompanies);
}
