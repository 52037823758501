import React, { Component } from "react";
import {
  InputGroup,
  Input,
  ButtonGroup,
  Button,
  Label,
  ListGroupItem,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class EditableElOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      prevValue: "",
    };
  }
  componentDidUpdate = (prevProps, prevState) => {
    const { editAll, setData, editedValue, editPrev, nameInput } = this.props;
    const { prevValue, editing } = this.state;
    if (!prevProps.editAll && editAll) {
      this.setState(() => {
        return { editing: true };
      });
    }
    if (setData && setData !== "" && prevValue === "") {
      this.setState({
        prevValue: setData,
      });
    }
    if (editPrev) {
      if (!prevState.editing && editing && editedValue === "") {
        this.props.editValue({ target: { value: setData, name: nameInput } });
      }
    }
  };
  enableEdit = () => {
    this.setState({ editing: true });
  };
  cancelEditing = () => {
    const { nameInput } = this.props;
    const { prevValue } = this.state;
    this.setState({
      editing: false,
    });
    this.props.editValue({ target: { value: prevValue, name: nameInput } });
  };
  doneEditing = () => {
    this.setState({
      editing: false,
    });
  };
  keyHandler = (e) => {
    if (e.key === "Tab" || e.key === "Enter") {
      this.doneEditing();
    }
    // else if (e.key==='ArrowLeft'){
    //   this.changePossibleQ(false)
    // }
  };
  renderChoices = () => {
    const { options } = this.props;
    return Object.keys(options).map((key, i) => (
      <option key={i} value={key}>
        {options[key]}
      </option>
    ));
  };
  render() {
    const { setData, nameInput, title, editedValue, editAll, className } =
      this.props;
    const { editing, prevValue } = this.state;
    return (
      <ListGroupItem
        onKeyUp={this.keyHandler}
        className={`${className} py-0`}
        color={editedValue !== "" && editedValue !== prevValue ? "info" : ""}
      >
        <Label>{title}</Label>
        {editing || editAll ? (
          <InputGroup className="my-2 animated fadeInUp">
            <Input
              bsSize="sm"
              type="select"
              name={nameInput}
              onChange={this.props.editValue}
              value={editedValue}
              placeholder={setData}
            >
              <option value="">Seleccione una opción</option>
              {this.renderChoices()}
            </Input>
            <ButtonGroup>
              <Button size="sm" onClick={this.doneEditing} color="primary">
                <FontAwesomeIcon icon="check" />
              </Button>
              <Button size="sm" onClick={this.cancelEditing}>
                <FontAwesomeIcon icon="times" />
              </Button>
            </ButtonGroup>
          </InputGroup>
        ) : (
          <p className="card-text">
            {editedValue === "" ? setData || title : editedValue}&nbsp;
            <Button color="link" onClick={this.enableEdit}>
              <i className="fas fa-edit" /> Editar
            </Button>
          </p>
        )}
      </ListGroupItem>
    );
  }
}
