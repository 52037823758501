import React from 'react';
import {Button} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import profile from '../../../assets/img/utils/profile.png';

function ProfileNav({className,fillProfile,isFetching,user:{firstname,lastname,profile_img}}){
  return(
    <div className={`d-flex justify-content-center align-items-center ${className}`}>
    {
      isFetching
      ? <FontAwesomeIcon className="text-light" spin icon="cog" />
      : <>
        <p className="my-auto">
            {firstname
              ?firstname
              :<Button onClick={fillProfile} color="link">Completa tu perfil</Button>
            }&nbsp;
            {lastname&&lastname}
          </p>
          <img width="40px" className="img-fluid rounded-circle mx-2" src={profile_img?profile_img:profile} alt=""/>
      </>
    }
  </div>
  )
}

export default ProfileNav;
