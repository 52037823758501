import {
  REQUEST_BECARD,
  CANCEL_BECARD,
  REQUEST_ONE_BECARD,
  CANCEL_ONE_BECARD,
  SUCCESS_BECARD,
  ERROR_BECARD,
  RESET_BECARD,
  SET_ONE_BECARD,
  SET_ALL_BECARDS,
  SET_FILTERED_BECARDS,
  SET_USER_BECARDS,
  SET_SEL_BECARD
  // SET_INFO_BECARD
} from '../actions/becard-actions';

export const becardReducer=(
  state={
    isFetching:false,
    isFetchingOne:false,
    message:false,
    error:false,
    becard:{user:{},_id:false},
    all_becards:[],
    filtered_becards:[],
    user_becards:[],
    sel_becard:{}
    // info_becard:{customer:{}}
  },
  action
)=>{
  const {
    type,
    isFetching,
    isFetchingOne,
    message,
    error,
    becard,
    all_becards,
    filtered_becards,
    user_becards,
    sel_becard
    // info_becard
  } = action

  switch (type) {
    case REQUEST_BECARD:
      return({...state,isFetching})
    case CANCEL_BECARD:
      return({...state,isFetching})
    case REQUEST_ONE_BECARD:
      return({...state,isFetchingOne})
    case CANCEL_ONE_BECARD:
      return({...state,isFetchingOne})
    case SUCCESS_BECARD:
      return({...state,message})
    case ERROR_BECARD:
      return({...state,error})
    case SET_ONE_BECARD:
      return({...state,becard})
    case SET_ALL_BECARDS:
      return({...state,all_becards})
    case SET_SEL_BECARD:
      return({...state,sel_becard})
    case SET_USER_BECARDS:
      return({...state,user_becards})
    case SET_FILTERED_BECARDS:
      return({...state,filtered_becards})
    // case SET_INFO_BECARD:
    //   return({...state,info_becard})
    case RESET_BECARD:
      return({...state,error,message})
    default:
      return ({...state})

  }
}
