import React from "react";
import { Container, Row, Col } from "reactstrap";

function Intro({ title, subtitle, heroImg, background }) {
  return (
    <Container className="abs_cont" fluid>
      {background && (
        <img
          alt="background"
          className={background.className}
          src={background.src}
        />
      )}
      <Container>
        <Row className="text-light text-left">
          <Col>
            <h2 className="sect_title">{title}</h2>
            <h3 className="secondary_title">{subtitle}</h3>
          </Col>
        </Row>
        <Row className="justify-content-end">
          <Col xs={12} md={7}>
            <img className="img-fluid" src={heroImg.src} alt={heroImg.alt} />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Intro;
