export const AUTHOR = "luiscasillas@n12.mx";
export const DEFAULT_DESCRIPTION = "Presentaciones personales digitales";
export const URL = "https://www.becards.app";
export const LOCATION = "";
export const NO_COMPANY_HANDLE = "community";
export const DEFAULT_TITLE = "BeCards";
export const SOCIAL = {
  facebook: "https://www.facebook.com/n12estudio",
  twitter: "https://twitter.com/N12Estudio",
  linkedin: "https://www.linkedin.com/company/n12-estudio/",
  instagram: "https://www.instagram.com/n12estudio/",
};

export const VERIFIED_STATUS = "Verified";

export const LOCAL_MESSAGES = {
  DUPLICATED_ADD_VALUE: "Sólo puedes agregar una vez el valor",
  NOT_EMPTY: "No puede dejar el campo vacío",
  ARRAY_EMPTY: "Seleccione al menos un valor",
  FORMAT_MISMATCH: "El valor no corresponde con el formato necesario",
  LANGUAGE_SET: {
    es: "Se ha cambiado el idioma a español",
    en: "English has been set as language",
  },
  PASSW_VALID: {
    es: "Las confirmación de contraseña coincide",
    en: "Confirmation match",
  },
  PASSW_INVALID: {
    es: "No coincide la confirmación",
    en: "Confirmation don't match",
  },
  EMPTY_PASS: {
    es: "Proporcione una contraseña",
    en: "Provide a password",
  },
  SHORT_PASS: {
    es: "La contraseña debe tener al menos 8 caracteres",
    en: "Password should be at least 8 characters long",
  },
  NO_BECARD: {
    es: "No hubo coincidencias",
    en: "No match for url",
  },
  USER_BECARDS: {
    es: "BeCards de usuario disponibles",
    en: "User BeCards available",
  },
  EMPTY_BECARDS: {
    es: "No existen aún registros",
    en: "No Records availbale",
  },
  USER_COMPANIES: {
    es: "Empresas de usuario disponibles",
    en: "User Companies available",
  },
  EMPTY_COMPANIES: {
    es: "No existen aún registros",
    en: "No Records avaliable",
  },
};

export const LABELS_NAVBAR = {
  LOGIN: {
    es: "Ingresar",
    en: "Log in",
  },
  PRICING: {
    es: "Precio",
    en: "Pricing",
  },
};

export const LABELS_BECARD = {
  EXPAND: {
    es: "Leer más",
    en: "Expand",
  },
  IS_VERIFIED: {
    es: "Empresa verificada",
    en: "Verified company",
  },
  IS_VERIFIED_BODY: {
    es: "BeCards ha verificado la existencia de esta empresa,  por medio de documentos oficiales",
    en: "BeCards has verified this company through official documents",
  },
};

export const LABELS_COMPANY = {
  SINCE: {
    es: "Fundada en",
    en: "Since",
  },
  INDUSTRY: {
    es: "Industria",
    en: "Industry",
  },
};

export const LABELS_FILTER = {
  SEARCH: {
    es: "Buscar",
    en: "Search",
  },
  SEARCH_PLACEHOLDER: {
    es: "Buscar...",
    en: "Search...",
  },
  CATHEGORIES: {
    es: "Filtros",
    en: "Filters",
  },
  CLEAN: {
    es: "Limpiar",
    en: "Clean",
  },
};

export const TOAST_TITLE = "BeCards.app";

export const DELAY_TEXT = 3000;
export const ANIM_TRANS = 3000;

export const PROMO_SHOW = process.env.NODE_ENV === "development" ? 1500 : 30000;

export const DEBOUNCE_TIME = {
  DEFAULT: 500,
  SEARCH: 1500,
};

export const THROTTLE_TIME = 500;

export const DELAY_ANIM = 0.4;

export const CARDS_PER_ROW_ROAST = 4;
export const CARDS_PER_ROW_ROAST_MEDIUM = 3;

// export const ARRAY_ROAST = [1,2,3]
// export const ARRAY_ROAST_MEDIUM = [1,2,3,4]

export const SMALL_BREAKPOINT = 1201;

export const CHANGE_FEATURES = 8000;
export const CHANGE_USAGE = 3000;

export const TOAST_TIMEOUT = {
  main: 6000,
  pad: 500,
};

export const GET_CONTACT_LABEL = {
  en: "Save",
  es: "Guardar",
};

export const COUNTRY_CODES = {
  MX: "52",
  CO: "57",
};

export const GLOBAL_LANGUAGES = {
  en: { lang: "en", langName: "English", langKey: "EN" },
  es: { lang: "es", langName: "Español", langKey: "ES" },
};

export const WHATSAPP_MESSAGE =
  "Hola%20nos%20conocimos%20por%20mi%20BeCard%20en%20";

export const MAIL_SUBJECT = "Hola!";

export const FILTERS = {
  company: { icon: "satellite", info: "Company", urlQry: "search_company" },
  tag: { icon: "tags", info: "Tags", urlQry: "tags" },
  user: { icon: "user-astronaut", info: "User", urlQry: "handle" },
};
