import {
  REQUEST_LOGIN,
  SUCCESS_LOGIN,
  CANCEL_LOGIN,
  ERROR_LOGIN,
  SET_USER_LOGIN,
  SET_TOKEN_LOGIN,
  RESET_LOGIN,
  LOG_OUT
} from '../actions/login-actions';

export const loginReducer=(state={
  token:sessionStorage.getItem(process.env.REACT_APP_TOKEN_NAME)||false,
  isFetching:false,
  message:false,
  error:false,
  user:false
},action)=>{
  const {
    type,
    isFetching,
    message,
    token,
    error,
    user
  } = action
  switch (type) {
    case REQUEST_LOGIN:
      return ({...state,isFetching})
    case SUCCESS_LOGIN:
      return({...state,message})
    case CANCEL_LOGIN:
      return({...state,isFetching})
    case ERROR_LOGIN:
      return({...state,error})
    case SET_USER_LOGIN:
      return({...state,user})
    case SET_TOKEN_LOGIN:
      return({...state,token})
    case RESET_LOGIN:
      return({...state,message,error})
    case LOG_OUT:
      return({...state,user,token})

    default:
      return ({...state})

  }
}
