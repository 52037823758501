import React, { Component, Fragment } from "react";
import {
  Form,
  FormGroup,
  Col,
  FormText,
  Input,
  Label,
  Row,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import { TagsInput, UploadFiles, SocialHandles } from "./questTypes";
import { EditableImg } from "./editableEls";
import { AnimSend } from "../elements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LABELS_FORM_BECARD } from "../../constants/platform";
import { inputToDate, dateToInput } from "../../utils";
import data from "../../assets/bodym/sending.json";

const HANDLE_REGEX = /^[A-Za-z0-9\-_.]{3,}$/;

export default class BeCardForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: "",
      lastname: "",
      handle: "",
      treatment: "",
      bio: "",
      job_title: "",
      showBirthday: false,
      birthDate: "",
      email: "",
      phone: "",
      phone_ext: "",
      socialmedia_handles: [],
      imgForCrop: [],
      cropped_img: [],
      profile_media: [],
      tags: []
    };
  }
  componentDidUpdate = (prevProps) => {
    const { isFetchingOne } = this.props;
    const { isFetchingOne: wasFetching } = prevProps;
    if (wasFetching && !isFetchingOne) {
      this.resetForm();
    }
  };
  componentDidMount = () => {
    const {
      user: { email, firstname, lastname, birthDate, profile_img }
    } = this.props;
    if (process.env.REACT_APP_PREFILL_DATA) {
      this.setState({
        firstname: "Luis",
        lastname: "Casillas",
        handle: "luis.casillas",
        treatment: "Sr",
        bio: "Devsigner",
        job_title: "Lead Guitarist",
        showBirthday: true,
        email: "luiscasillas@n12.mx",
        phone: "2222222222",
        phone_ext: "105"
      });
    }
    if (email) {
      this.setState({
        email
      });
    }
    if (lastname) {
      this.setState({
        lastname
      });
    }
    if (firstname) {
      this.setState({
        firstname
      });
    }
    if (birthDate) {
      this.setState({
        birthDate: dateToInput(birthDate)
      });
    }
    if (profile_img) {
      this.setState({
        profile_img
      });
    }
  };
  resetForm = () => {
    this.setState({
      progr: 0,
      firstname: "",
      lastname: "",
      handle: "",
      treatment: "",
      bio: "",
      job_title: "",
      showBirthday: false,
      birthDate: "",
      email: "",
      phone: "",
      phone_ext: "",
      country: "",
      socialmedia_handles: [],
      imgForCrop: [],
      cropped_img: [],
      profile_media: [],
      tags: []
    });
    // this.props.toggle()
  };
  getValue = (e) => {
    const { value, name, type, checked } = e.target;
    const val = type === "checkbox" ? checked : value;
    this.setState({
      [name]: val
    });
  };
  getHandle = (e) => {
    const { value, name } = e.target;
    this.setState({
      [name]: value.replace(/\s/g, " ").trim()
    });
  };
  endAnim = () => {
    this.props.cancelRequest();
  };
  loadCropImg = ({ target: { files } }) => {
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        this.setState({ imgForCrop: reader.result });
      });
      reader.readAsDataURL(files[0]);
    }
  };
  getFiles = ({ target: { files } }) => {
    this.setState({ cropped_img: files });
  };
  startSend = (e) => {
    e.preventDefault();
    const {
      showBirthday,
      firstname,
      lastname,
      handle,
      treatment,
      job_title,
      bio,
      birthDate,
      email,
      phone,
      phone_ext,
      socialmedia_handles,
      cropped_img,
      profile_img,
      profile_media,
      tags,
      country
    } = this.state;
    let handlesMap = socialmedia_handles.reduce((obj, { network, value }) => {
      obj[network] = value;
      return obj;
    }, {});
    const data = {
      firstname,
      lastname,
      handle,
      treatment: {
        es: treatment
      },
      job_title: {
        es: job_title
      },
      bio: {
        es: bio
      },
      email,
      phone,
      phone_ext,
      socialmedia_handles: handlesMap,
      cropped_img,
      profile_media,
      tags,
      country
    };
    if (showBirthday) data.birthDate = inputToDate(birthDate);
    if (profile_img) data.profile_img = profile_img;
    let images = null;
    if (cropped_img.length) {
      images = new FormData();
      for (let i = 0; i < cropped_img.length; i++) {
        images.append("images", cropped_img[i]);
      }
    }
    this.props.createBeCard(data, images);
  };
  render() {
    const { isFetchingOne, lang, user, countries } = this.props;
    const {
      socialmedia_handles,
      firstname,
      lastname,
      job_title,
      handle,
      treatment,
      bio,
      birthDate,
      showBirthday,
      email,
      phone,
      phone_ext,
      imgForCrop,
      country,
      // profile_media,
      tags
    } = this.state;
    return (
      <Form onSubmit={this.startSend}>
        {isFetchingOne ? (
          <Row className="justify-content-center">
            <Col xs={12} sm={6}>
              <AnimSend data={data} endAnim={this.endAnim} />
            </Col>
          </Row>
        ) : (
          <Fragment>
            <FormGroup row>
              <Label for="email" sm={2}>
                {LABELS_FORM_BECARD.EMAIL[lang]}*
              </Label>
              <Col sm={10}>
                <Input
                  onChange={this.getValue}
                  value={email}
                  required
                  type="email"
                  name="email"
                  id="email"
                  placeholder="somebody@company.com"
                />
                <FormText color="muted">
                  {LABELS_FORM_BECARD.EMAIL_EXPL[lang]}
                </FormText>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="firstname" sm={2}>
                {LABELS_FORM_BECARD.FIRST_NAME[lang]}*
              </Label>
              <Col sm={10}>
                <Input
                  onChange={this.getValue}
                  value={firstname}
                  type="text"
                  name="firstname"
                  id="firstname"
                  placeholder="Nombre(s)"
                  required
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="lastname" sm={2}>
                {LABELS_FORM_BECARD.LAST_NAME[lang]}*
              </Label>
              <Col sm={10}>
                <Input
                  onChange={this.getValue}
                  value={lastname}
                  type="text"
                  name="lastname"
                  id="lastname"
                  placeholder="Nombre(s)"
                  required
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="bio" sm={2}>
                {LABELS_FORM_BECARD.BIO[lang]}*
              </Label>
              <Col sm={10}>
                <Input
                  onChange={this.getValue}
                  value={bio}
                  type="textarea"
                  name="bio"
                  id="bio"
                  placeholder="Bio"
                  required
                />
                <FormText>{LABELS_FORM_BECARD.BIO_EXPL[lang]}</FormText>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="job_title" sm={2}>
                {LABELS_FORM_BECARD.TITLE[lang]}*
              </Label>
              <Col sm={10}>
                <Input
                  onChange={this.getValue}
                  value={job_title}
                  type="text"
                  name="job_title"
                  id="job_title"
                  placeholder="Título, p.ej: Director, Gerente, Manager, etc."
                  required
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="handle" sm={2}>
                {LABELS_FORM_BECARD.HANDLE[lang]}*
              </Label>
              <Col sm={10}>
                <InputGroup className="">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FontAwesomeIcon icon={"at"} />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    onChange={this.getHandle}
                    value={handle}
                    type="text"
                    name="handle"
                    id="handle"
                    placeholder="juan.gonzales"
                    pattern={HANDLE_REGEX.source}
                    valid={HANDLE_REGEX.test(handle)}
                    invalid={
                      phone.length >= 3 ? !HANDLE_REGEX.test(handle) : null
                    }
                    required
                  />
                </InputGroup>

                <FormText>{LABELS_FORM_BECARD.HANDLE_EXPL[lang]}</FormText>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="treatment" sm={2}>
                {LABELS_FORM_BECARD.TREATMENT[lang]}
              </Label>
              <Col sm={10}>
                <Input
                  onChange={this.getValue}
                  value={treatment}
                  type="text"
                  name="treatment"
                  id="treatment"
                  placeholder="Dr,Sr,Sra,Mr,Mdm"
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="phone" sm={2}>
                {LABELS_FORM_BECARD.PHONE[lang]}
              </Label>
              <Col sm={3}>
                <Input
                  onChange={this.getValue}
                  value={country}
                  name="country"
                  id="country"
                  invalid={!country}
                  type="select">
                  <option value="">{LABELS_FORM_BECARD.COUNTRY[lang]}</option>
                  {countries.length
                    ? countries.map(({ name, idd, flag, cca2 }) => (
                        <option key={name?.common} value={`${cca2}`}>
                          {`${flag} ${name?.common} ${idd?.root}${idd?.suffixes[0]}`}
                        </option>
                      ))
                    : null}
                </Input>
              </Col>
              <Col sm={5}>
                <Input
                  onChange={this.getValue}
                  value={phone}
                  name="phone"
                  id="phone"
                  type="text"
                  // regExp="/^\d{10}$/"
                  pattern="[0-9]{10}"
                  valid={/^\d{10}$/.test(phone)}
                  invalid={phone.length >= 10 ? !/^\d{10}$/.test(phone) : null}
                  placeholder={"Teléfono a 10 dígitos p.ej. 1234567890"}
                />
                <FormText>{LABELS_FORM_BECARD.PHONE_EXPL[lang]}</FormText>
              </Col>
              <Col sm={2}>
                <Input
                  onChange={this.getValue}
                  value={phone_ext}
                  name="phone_ext"
                  id="phone_ext"
                  type="text"
                  // regExp="/^\d{10}$/"
                  placeholder={"Extensión"}
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label sm={2}>Social handles</Label>
              <Col sm={10}>
                <SocialHandles
                  setHandles={this.getValue}
                  name="socialmedia_handles"
                  handles={socialmedia_handles}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={2}>Mostrar cumpleaños</Label>
              <Col sm={10}>
                <Label check className="switch">
                  <input
                    type="checkbox"
                    onChange={this.getValue}
                    name="showBirthday"
                    checked={showBirthday}
                  />
                  <span className="slider round" />
                </Label>
              </Col>
            </FormGroup>
            {showBirthday ? (
              <FormGroup row>
                <Label for="birthDate" sm={2}>
                  Cumpleaños
                </Label>
                <Col sm={10}>
                  <Input
                    onChange={this.getValue}
                    value={birthDate}
                    type="date"
                    name="birthDate"
                    id="birthDate"
                    placeholder="Tu cumpleaños"
                  />
                </Col>
              </FormGroup>
            ) : null}
            <FormGroup row>
              <Label sm={2}>{LABELS_FORM_BECARD.TAGS[lang]}</Label>
              <Col sm={10}>
                <TagsInput
                  getValue={this.getValue}
                  addToast={this.props.addToast}
                  nameInput="tags"
                  lang={lang}
                  tags={tags}
                />
                <FormText>{LABELS_FORM_BECARD.TAGS_EXPL[lang]}</FormText>
              </Col>
            </FormGroup>
            <UploadFiles
              isRow={true}
              inputCol={10}
              labelCol={2}
              formText={LABELS_FORM_BECARD.ADD_IMAGE[lang]}
              title={LABELS_FORM_BECARD.IMAGE_FORM_TITLE[lang]}
              accept="image/jpeg,image/png"
              classLbl=""
              className="mt-3"
              getFiles={this.loadCropImg}
              gotFiles={imgForCrop.length}
              name="cropped_img"
            />
            <EditableImg
              initialCrop={{ unit: "px", width: 200, aspect: 1 }}
              handle={handle}
              user={user}
              getFiles={this.getFiles}
              src={imgForCrop}
            />
            <FormGroup>
              <Button
                // onClick={this.startSend}
                block
                color="primary"
                disabled={isFetchingOne}
                type="submit">
                Publicar BeCard
              </Button>
            </FormGroup>
          </Fragment>
        )}
      </Form>
    );
  }
}
