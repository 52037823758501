import {takeLatest,put,call,select} from 'redux-saga/effects';
import * as actions from '../redux/actions/login-actions';
import {add_toast} from '../redux/actions/toasts-actions';
import {api_public,api_private,local} from '../services';
import {getStateLang} from './utils';

function* postDataLogin({dataLogin}){
  yield put(actions.request_login())
  const lang = yield getStateLang()

  try {
    const login = yield call(api_public.postLogin,dataLogin)
    if(login.error){
      yield put(actions.error_login(login.error[lang]))
      yield put(add_toast({
        text:login.error[lang],
        error:true
      }))
    } else {
      yield put(actions.success_login(login.message[lang]))
      yield put(actions.set_user_login(login.user))
      yield put(actions.set_token_login(login.token))
      yield call(local.setLocalToken,login.token)
      yield put(add_toast({
        error:false,
        text:login.message[lang]
      }))
    }

  } catch ({error}) {
    yield put(actions.error_login(error.toString()))
    yield put(add_toast({
      text:error.toString(),
      error:true
    }))
    yield put(actions.cancel_login())
  }
}

function* postResetPass({dataResetP}){
  yield put(actions.request_login())
  const lang = yield getStateLang()

  try {
    const login = yield call(api_public.postResetPass,dataResetP)
    if(login.error){
      yield put(actions.error_login(login.error[lang]))
      yield put(add_toast({
        text:login.error[lang],
        error:true
      }))
    } else {
      yield put(actions.success_login(login.message[lang]))
      yield put(actions.set_user_login(login.user))
      yield put(actions.set_token_login(login.token))
      yield call(local.setLocalToken,login.token)
      yield put(add_toast({
        error:false,
        text:login.message[lang]
      }))
    }

  } catch ({error}) {
    yield put(actions.error_login(error.toString()))
    yield put(add_toast({
      text:error.toString(),
      error:true
    }))
    yield put(actions.cancel_login())
  }
}

function* getRenewToken(){
    yield put(actions.request_login())
    try {
      const lang = yield select(({globalReducer:{lang}})=>lang)
      // const token = yield select(({loginReducer:token})=>token)
      const renewed = yield call(api_private.getRenewToken)
      if(renewed.error){
        yield put(actions.error_login(renewed.error[lang]))
        yield put(add_toast({
          text:renewed.error[lang],
          error:true
        }))
        yield put(actions.cancel_login())
        yield call(local.revokeLocalToken)
      } else {
        yield put(actions.success_login(renewed.message[lang]))
        yield put(actions.set_user_login(renewed.user))
        yield put(actions.set_token_login(renewed.token))
        yield call(local.setLocalToken,renewed.token)
        yield put(add_toast({
          error:false,
          text:renewed.message[lang]
        }))
        yield put(actions.cancel_login())
      }
    } catch (error) {
      yield put(actions.error_login(error.toString()))
      yield put(add_toast({
        text:error.toString(),
        error:true
      }))
      yield put(actions.cancel_login())
      yield call(local.revokeLocalToken)
    }
}

function* postForgPass({dataForgP}){
    yield put(actions.reset_login())
    yield put(actions.request_login())
    const lang = yield getStateLang()
    try {
      const forgPass = yield call(api_public.postForgPass,dataForgP)
      if(forgPass.error){
        yield put(actions.error_login(forgPass.error[lang]))
        yield put(add_toast({
          text:forgPass.error[lang],
          error:false
        }))
      } else {
        yield put(actions.success_login(forgPass.message[lang]))
        yield put(add_toast({
          text:forgPass.message[lang],
          error:false
        }))
      }
    } catch (error) {
      yield put(actions.error_login(error.toString()))
      yield put(add_toast({
        text:error.toString(),
        error:true
      }))
      yield put(actions.cancel_login())
    }

}

function* getVerifyToken({reset_pass_token}){
    yield put(actions.reset_login())
    yield put(actions.request_login())
    const lang = yield getStateLang()
    try {
      const forgPass = yield call(api_public.getVerifyToken,reset_pass_token)
      if(forgPass.error){
        yield put(actions.error_login(forgPass.error[lang]))
        yield put(add_toast({
          text:forgPass.error[lang],
          error:false
        }))
      } else {
        yield put(actions.success_login(forgPass.message[lang]))
        yield put(add_toast({
          text:forgPass.message[lang],
          error:false
        }))
      }
    } catch (error) {
      yield put(actions.error_login(error.toString()))
      yield put(add_toast({
        text:error.toString(),
        error:true
      }))
      yield put(actions.cancel_login())
    }

}

export function* watchPostLogin(){
  yield takeLatest(actions.START_LOGIN,postDataLogin)
  yield takeLatest(actions.RENEW_TOKEN,getRenewToken)
  yield takeLatest(actions.START_FORG_PASS,postForgPass)
  yield takeLatest(actions.START_VERIFY_TOKEN,getVerifyToken)
  yield takeLatest(actions.START_RESET_PASS,postResetPass)
}
