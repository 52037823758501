// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Switch, Route, Redirect } from "react-router-dom";
import {
  // Placeholder,
  // Contact,
  NoMatch,
  Login,
  Verify,
  Platform,
  BeCardsDir,
  ResetPass,
  Pricing,
  // BeSlides
} from "./components/routes";
import { NavbCont, BeCardCont, LandingCont } from "./containers";
import { Toaster } from "./components/elements";
// import {CustomerChat} from './components/elements';
import { library } from "@fortawesome/fontawesome-svg-core";
import { fontawesomeIcons } from "./config/fontawesome";
// import {Platform} from './components/routes'

import "date-input-polyfill-react";
import "animate.css";
import "./App.css";
import "./style/utils.css";

library.add(...fontawesomeIcons);

type gProps = {
  store: {},
  location: {
    pathname: string,
  },
  match: {},
};

class App extends Component<gProps> {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { location, token, match } = this.props;
    return (
      <main scroll="no" className="App">
        {/* <CustomerChat/> */}
        <NavbCont match={match} location={location} />
        <Toaster />
        <div>
          <TransitionGroup>
            <CSSTransition
              key={location.pathname}
              timeout={token ? 0 : 800}
              classNames={token ? "" : "fadeTranslate"}
              mountOnEnter={true}
              unmountOnExit={true}
            >
              <div className="wrapper">
                <Switch location={location}>
                  <Route exact path="/" component={LandingCont} />

                  <Route path="/login" component={Login} />
                  <Route path="/pricing" component={Pricing} />
                  <Route path="/platform">
                    <PlatformRoute
                      exact
                      token={token}
                      component={Platform}
                      path="/platform"
                    />
                    <PlatformRoute
                      token={token}
                      component={Platform}
                      path="/platform/:section"
                    />
                  </Route>

                  <Route
                    path="/verify/:user_id/:validation_code"
                    component={Verify}
                  />
                  <Route
                    path="/reset_password/:reset_pass_token"
                    component={ResetPass}
                  />
                  <Route path="/cards">
                    <Route exact path="/cards" component={BeCardsDir} />
                    <Route
                      path="/cards/:company/:handle"
                      component={BeCardCont}
                    />
                  </Route>
                  <Route path="*" component={NoMatch} />
                </Switch>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </main>
    );
  }
}

const PlatformRoute = ({ token, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        token ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

const mapStateToProps = ({ loginReducer }) => ({
  ...loginReducer,
});

export default connect(mapStateToProps)(App);
