export const SET_SEL_FORM = 'SET_SEL_FORM';
export const SET_SLIDE_CONTENT = 'SET_SLIDE_CONTENT';
export const TOGGLE_SLIDE_MENU = 'TOGGLE_SLIDE_MENU';
export const TOGGLE_FORM_MODAL = 'SET_FORM_MODAL';

export const set_sel_form=(sel_form)=>({
  type:SET_SEL_FORM,
  sel_form
})

export const set_slide_content=(slide_content)=>({
  type:SET_SLIDE_CONTENT,
  slide_content
})
export const toggle_slide_menu=()=>({
  type:TOGGLE_SLIDE_MENU
})
export const toggle_form_modal=()=>({
  type:TOGGLE_FORM_MODAL
})
