import {select} from 'redux-saga/effects';

export function* getStateLang(){
  const lang = yield select(({globalReducer:{lang}})=>lang)
  return lang
}

export function* getLoginUser(){
  const user = yield select(({loginReducer:{user}})=>user)
  return user
}
