export const START_UPDATE_USER = "START_UPDATE_USER";
export const REQUEST_USER = "REQUEST_USER";
export const CANCEL_USER = "CANCEL_USER";
export const SUCCESS_USER = "SUCCESS_USER";
export const ERROR_USER = "ERROR_USER";
export const RESET_USER = "RESET_USER";

export const post_data_user=(dataUser,images)=>({
  type:START_UPDATE_USER,
  dataUser,
  images
})

export const request_user=()=>({
  type:REQUEST_USER,
  isFetching:true
})

export const cancel_user=()=>({
  type:CANCEL_USER,
  isFetching:false
})

export const success_user=(message)=>({
  type:SUCCESS_USER,
  message
})

export const error_user=(error)=>({
  type:ERROR_USER,
  error
})

export const reset_user=()=>({
  type:RESET_USER,
  message:false,
  error:false
})
