import {
  REQUEST_SIGNUP,
  CANCEL_SIGNUP,
  SUCCESS_SIGNUP,
  ERROR_SIGNUP,
  RESET_SIGNUP
} from '../actions/signup-actions';

export const signupReducer=(
  state={
    isFetching:false,
    message:false,
    error:false
  },
  action
)=>{
  const {
    type,
    isFetching,
    message,
    error

  } = action
  switch (type) {
    case REQUEST_SIGNUP:
      return({...state,isFetching})
    case CANCEL_SIGNUP:
      return({...state,isFetching})
    case SUCCESS_SIGNUP:
      return({...state,message})
    case ERROR_SIGNUP:
      return({...state,error})
    case RESET_SIGNUP:
        return({...state,error,message})
    default:
        return({...state})

  }
}
