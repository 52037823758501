import React,{useEffect} from 'react';
import {connect} from 'react-redux';
import {LoginForm} from '../../components/forms';
import {local} from '../../services';
import {log_out,start_login,cancel_login} from '../../redux/actions/login-actions';
import {PLATF_REDIR_TIMEOUT} from '../../constants/platform';

function LoginFormCont({history,cancel_login,start_login,log_out,user,token,...rest}){
  useEffect(()=>{
    log_out()
    local.revokeLocalToken()
    // eslint-disable-next-line
  },[])
  useEffect(()=>{
    if(user&&token){
      setTimeout(()=>{
        history.push('/platform')
      },PLATF_REDIR_TIMEOUT)
    }
  },[user, token, history])
  return(
    <LoginForm
      startLogin={start_login}
      cancelLogin={cancel_login}
       {...rest}/>
  )
}

const mapStateToProps=({loginReducer})=>({
  ...loginReducer
})

const mapActionsToProps={
  log_out,
  start_login,
  cancel_login
}
export default connect(mapStateToProps,mapActionsToProps)(LoginFormCont);
