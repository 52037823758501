import React from 'react';
import {Container,Row} from 'reactstrap';
import {JumboColor,Footer} from '../sections';
import {ResetPFormCont} from '../../containers'
import {ErrorBoundary} from '../elements';

function ResetPass ({history,match}){
  return(
    <section>
      <JumboColor
        header={'Reestablecer contraseña'}
        lead={'Gracias por esperar mientras verificamos'}
        cta={''}
        link={''}
      />
      <Container className="bg_grayBlue" fluid>
        <Container>
          <Row className="justify-content-center">
            <ErrorBoundary>
              <ResetPFormCont
                history={history}
                match={match}
              />
            </ErrorBoundary>

          </Row>
         </Container>
      </Container>
      <Footer/>
    </section>
  )
}

export default ResetPass;
