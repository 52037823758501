export const TOGGLE_FILTER = "TOGGLE_FILTER";
export const ADD_TYPE_FILTER = "ADD_TYPE_FILTER";
export const REMOVE_TYPE_FILTER = "REMOVE_TYPE_FILTER";
// export const TOGGLE_FILTER = "TOGGLE_FILTER";

export const toggle_filter=()=>({
  type:TOGGLE_FILTER
})

export const add_type_filter=(typeFilter)=>({
  type:ADD_TYPE_FILTER,
  typeFilter
})

export const remove_type_filter=(id_typeFilter)=>({
  type:REMOVE_TYPE_FILTER,
  id_typeFilter
})
