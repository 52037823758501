import React from 'react';
import {Button} from 'reactstrap';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {LABELS_PLATFORM} from '../../constants/platform';

function BeCardsSubmenu({className,createBeCard,lang}){
  return(
    <div className={`${className} w-100`}>
    <Button onClick={createBeCard} color="primary" block>
      {LABELS_PLATFORM.CREATE_BECARD[lang]}
    </Button>
    <Button block className="mt-2" color="primary" tag={Link} to="/cards" target="_blank" rel="noopener noreferrer">
      {LABELS_PLATFORM.SEARCH[lang]}&nbsp;<FontAwesomeIcon icon="id-badge"/>
    </Button>
    </div>
  )
}

export default BeCardsSubmenu;
