import React from 'react';
import {
  Badge,
  ButtonGroup,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  CardImg,
  CardText
} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import {COUNTRY_CODES,WHATSAPP_MESSGAE} from '../../../constants'

function RosterBeCards({
  lang,
  className,
  platform,
  editBeCard,
  info:{
    _id,
    firstname,
    lastname,
    profile_img,
    company,
    // qr_code,
    link_card,
    job_title,
    socialmedia_handles,
    tags
  }
}){
  return(
    <>
    <Card className={`${className} becard_roster d-none d-md-flex`}>
      <CardImg src={profile_img} className="roster_profile_img rounded-circle p-4"  />
      <CardBody>
        <CardTitle>
          {`${firstname} ${lastname}`} <br/>
          <b>
            {job_title[lang]}{company?<>{` @ ${company.commercial_name}`}</>:''}
          </b>
        </CardTitle>
        <CardText tag="div">
          {socialmedia_handles
            ?  <Row className="justify-content-center social_cont mb-3">
                {socialmedia_handles.instagram
                ?<Col xs={1}>

                        <FontAwesomeIcon size="lg" icon={['fab','instagram']}/>
                </Col>
                :null}
                {socialmedia_handles.facebook
                ?<Col xs={1}>

                    <FontAwesomeIcon size="lg" icon={['fab','facebook']}/>
                </Col>
                :null}
                {socialmedia_handles.twitter
                ?<Col xs={1}>

                      <FontAwesomeIcon size="lg" icon={['fab','twitter']}/>
                </Col>
                :null}
                {socialmedia_handles.linkedin
                ?<Col xs={1}>

                    <FontAwesomeIcon size="lg" icon={['fab','linkedin']}/>
                </Col>
                :null}
                {socialmedia_handles.whatsapp
                ?<Col xs={1}>

                    <FontAwesomeIcon size="lg" icon={['fab','whatsapp']}/>

                </Col>
                :null}

              </Row>
            :null
          }
        </CardText>
       <ButtonGroup>
         <Button color="primary" tag='a' href={link_card} target="_blank" rel="noopener noreferrer">
           Visitar&nbsp;<FontAwesomeIcon icon="external-link-alt"/>
         </Button>
         {platform
           ?<Button
             onClick={()=>editBeCard(_id)}
             className="" color="primary">
               Editar&nbsp;<FontAwesomeIcon icon="edit"/>
           </Button>
           :null
         }
       </ButtonGroup>
      </CardBody>
      <CardFooter>

        {tags.map((el,i)=><Badge className="m-1" color="info" key={i}>
          {el}
        </Badge>)}

      </CardFooter>
    </Card>
    <Card className={`${className} becard_roster d-flex d-md-none`}>
      <Row noGutters>
        <Col xs={3}>
            <CardImg src={profile_img} className="roster_profile_img rounded-circle p-4"  />
        </Col>
        <Col>
          <CardBody>
            <CardTitle>
              {`${firstname} ${lastname}`} <br/>
              <b>
                {job_title[lang]}
              </b>
            </CardTitle>
            <CardText tag="div">
              {socialmedia_handles
                ?  <Row className="justify-content-center social_cont mb-3">
                    {socialmedia_handles.instagram
                    ?<Col xs={1}>

                            <FontAwesomeIcon size="lg" icon={['fab','instagram']}/>
                    </Col>
                    :null}
                    {socialmedia_handles.facebook
                    ?<Col xs={1}>

                        <FontAwesomeIcon size="lg" icon={['fab','facebook']}/>
                    </Col>
                    :null}
                    {socialmedia_handles.twitter
                    ?<Col xs={1}>

                          <FontAwesomeIcon size="lg" icon={['fab','twitter']}/>
                    </Col>
                    :null}
                    {socialmedia_handles.linkedin
                    ?<Col xs={1}>

                        <FontAwesomeIcon size="lg" icon={['fab','linkedin']}/>
                    </Col>
                    :null}
                    {socialmedia_handles.whatsapp
                    ?<Col xs={1}>

                        <FontAwesomeIcon size="lg" icon={['fab','whatsapp']}/>

                    </Col>
                    :null}

                  </Row>
                :null
              }
            </CardText>
           <ButtonGroup>
             <Button color="primary" tag='a' href={link_card} target="_blank" rel="noopener noreferrer">
               Visitar&nbsp;<FontAwesomeIcon icon="external-link-alt"/>
             </Button>
             {platform
               ?<Button
                 onClick={()=>editBeCard(_id)}
                 className="" color="primary">
                   Editar&nbsp;<FontAwesomeIcon icon="edit"/>
               </Button>
               :null
             }
           </ButtonGroup>
          </CardBody>

        </Col>
      </Row>
      <CardFooter>

        {tags.map((el,i)=><Badge className="m-1" color="info" key={i}>
          {el}
        </Badge>)}

      </CardFooter>


    </Card>
    </>
  )
}

export default RosterBeCards;
