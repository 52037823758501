import React from 'react';
import {connect} from 'react-redux'
import {Landing} from '../../components/routes';

function LandingCont({...rest}){
  return(
    <Landing {...rest}/>
  )
}

const mapStateToProps=({
  globalReducer:{lang}
})=>({
  lang
})

export default connect(mapStateToProps)(LandingCont)
