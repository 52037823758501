import React from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  Button
} from 'reactstrap';
import {Link} from 'react-router-dom';
import {Footer} from '../sections';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import top_hero from '../../assets/img/landing/top_hero.svg';

import personal from '../../assets/img/pricing/personal_card.png'
import enterprise from '../../assets/img/pricing/enterprise_card.png'


const bgHeroTop ={
  className:"float_hero_top",
  src:top_hero
}

function Pricing (){
  return(
    <section>
    <Container className="abs_cont public_pages sect_80" fluid>
      {bgHeroTop&&<img alt="background" className={bgHeroTop.className} src={bgHeroTop.src}/>}
      <Container className="">
        <Row className="text-light text-left sect_30 align-items-end" >
          <Col>
            <h2 className="sect_title">
              Planes disponibles
            </h2>
            <h3 className="secondary_title">
              Escoge el plan que mejor te vaya
            </h3>
          </Col>
        </Row>
        <Row className="justify-content-end align-items-center sect_80">
          <Col xs={12} md={7}>
            <Row>
              <Col>
                <Card>
                  <CardImg src={personal}/>
                  <CardBody>
                    <CardTitle>
                      Uso personal
                    </CardTitle>
                    <hr/>
                    <del>$600</del> <br/>
                    <small>Por introducción</small> <br/>
                    <b>$350</b> <br/>
                    Anual <br/>
                    <Button color="primary" to="/login" tag={Link}>
                      Empieza gratis
                    </Button>

                  </CardBody>

                </Card>
              </Col>
              <Col>
                <Card>
                  <CardImg src={enterprise}/>
                  <CardBody>
                    <CardTitle>
                      Enterprise
                    </CardTitle>
                    <ul className="list-unstyled text-left">
                      <li><FontAwesomeIcon className="text-info" icon={['far','check-circle']}/>&nbsp;Diseño personalizado</li>
                      <li><FontAwesomeIcon className="text-info" icon={['far','check-circle']}/>&nbsp;handle propio</li>
                      <li><FontAwesomeIcon className="text-info" icon={['far','check-circle']}/>&nbsp;Verificación</li>
                      <li><FontAwesomeIcon className="text-info" icon={['far','check-circle']}/>&nbsp;Productos ilimitados</li>
                      <li><FontAwesomeIcon className="text-info" icon={['far','check-circle']}/>&nbsp;User management</li>
                      <li><FontAwesomeIcon className="text-info" icon={['far','check-circle']}/>&nbsp;y más</li>
                    </ul>
                    <hr/>
                    <Button color="primary" tag="a" href="mailto:team@becards.app">Solicita una cotización</Button> <br/>
                    Anual
                  </CardBody>

                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
    <Footer/>
  </section>

  )
}

export default Pricing;
