import React,{Fragment,useState} from 'react';
import {
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  Form,
  Row,
  Col,
  FormGroup,
  FormFeedback,
  Input,
  Label,
  InputGroup,
  InputGroupAddon,
  Button,
  FormText
} from 'reactstrap';
import {AnimFailSucc} from '../elements';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {LOCAL_MESSAGES} from '../../constants';
import {LABELS_FORM_LOGIN} from '../../constants/platform';

function SignupForm({
  isFetching,
  cancelSignup,
  startSignup,
  addToast,
  error,
  message,
  setLogin,
  lang
}) {
  const [formVals,setFormVals] = useState({login:'',passW:'',passWConf:''})
  const [showPass,setShowPass] = useState(false)
  const passWname = 'passW'
  const loginName = 'login'
  const passWconfName = 'passWConf'
  const getValue=({target:{value,name}})=>{
    setFormVals({...formVals,[name]:value})
  }
  const {login,passW,passWConf} = formVals
  const handleForm=(e)=>{
    e.preventDefault()
    if (passW === '') {
      addToast({
        text:LOCAL_MESSAGES.EMPTY_PASS[lang],
        error:true
      })
    } else if (passW.length < 8) {
      addToast({
        text:LOCAL_MESSAGES.SHORT_PASS[lang],
        error:true
      })
    } else if (passW !== passWConf) {
      addToast({
        text:LOCAL_MESSAGES.PASSW_INVALID[lang],
        error:true
      })
    } else if (passWConf !== '' && passW === passWConf) {
      setFormVals({
        login:'',passW:'',passWConf:''
      })
      startSignup({
        password: passW,
        email: login
      })
    }
  }
  const showPassW=()=>{
    setShowPass(!showPass)
  }
  const validUsername = new RegExp(/@/).test(login)
  return (
    <Card className="animated fadeInPlace">
      <CardHeader className="font-weight-bold">
        {LABELS_FORM_LOGIN.CREATE_ACCOUNT[lang]}
      </CardHeader>
      <CardBody>
        <Form onSubmit={handleForm}>
          {
            isFetching
              ? message || error
                ? <Row className="justify-content-center">
                    <Col>
                      <AnimFailSucc error={error} endAnim={cancelSignup}/>
                    </Col>
                  </Row>
                : null
              : <Fragment>
                      <FormGroup>
                        <Label>
                          <FontAwesomeIcon icon="at"/>&nbsp;
                          Email
                        </Label>
                        <Input
                          onChange={getValue}
                          valid={login.length?validUsername:null}
                          invalid={login.length?null:validUsername}
                          className=""
                          required
                          placeholder="someone@web.com"
                          autoComplete="username"
                          value={login}
                          type="text"
                          name={loginName}
                          id={loginName}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label>
                          <i className="fas fa-lock"/> &nbsp;
                          {LABELS_FORM_LOGIN.PASS_EIGHT_CH[lang]}
                        </Label>
                        <InputGroup>
                          <Input
                            onChange={getValue}
                            className=""
                            required
                            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                            autoComplete="new-password"
                            placeholder="contraseña"
                            value={passW}
                            type={showPass?'text':'password'}
                            name={passWname}
                            id={passWname}
                          />
                          <InputGroupAddon addonType="append">
                            <Button onClick={showPassW} active={showPass}>
                              <FontAwesomeIcon icon={showPass?'eye':'eye-slash'}/>
                            </Button>

                          </InputGroupAddon>

                        </InputGroup>
                        <FormText>
                          {LABELS_FORM_LOGIN.PASS_RESTR[lang]}
                        </FormText>
                      </FormGroup>
                      <FormGroup>
                        <Label>
                          <i className="fas fa-lock"/>
                          &nbsp; {LABELS_FORM_LOGIN.CONFIRM_PASS[lang]}
                        </Label>
                        <Input
                          onChange={getValue}
                          className=""
                          required
                          valid={passWConf.length?passWConf===passW:null}
                          invalid={passWConf.length?passWConf!==passW:null}
                          placeholder="confirme la contraseña"
                          autoComplete="new-password"
                          value={passWConf}
                          type={showPass?'text':'password'}
                          name={passWconfName}
                          id={passWconfName}
                        />
                        <FormFeedback valid>{LOCAL_MESSAGES.PASSW_VALID[lang]}</FormFeedback>
                        <FormFeedback>{LOCAL_MESSAGES.PASSW_INVALID[lang]}</FormFeedback>
                       </FormGroup>

                      <FormGroup>
                          <Button
                            color="primary"
                            block
                            type="submit">
                            {LABELS_FORM_LOGIN.REGISTER[lang]}
                          </Button>
                        </FormGroup>

                  </Fragment>
          }
          {/* <FormGroup>
            {message !== '' && <FormText>{message}</FormText>}
            {error !== '' && <FormText color="danger">{error}</FormText>}
            {localMessage !== '' && <FormText color="warning">{localMessage}</FormText>}
          </FormGroup> */}
        </Form>
      </CardBody>
      <CardFooter>
        <b>
          {LABELS_FORM_LOGIN.HAS_ACCOUNT[lang]}
        </b>
        <Button onClick={setLogin} className="d-inline" color="link">
          {LABELS_FORM_LOGIN.LOGIN[lang]}
        </Button>
      </CardFooter>
  </Card>)
}

export default SignupForm;
