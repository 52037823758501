export const START_GET_BECARD = "START_GET_BECARD";
export const START_UPDATE_BECARD = "START_UPDATE_BECARD";
export const SEARCH_ONE_BECARD = "SEARCH_ONE_BECARD";
export const SEARCH_BECARDS = "SEARCH_BECARDS";
export const CREATE_BECARD = "CREATE_BECARD";
export const LIST_BECARDS = "LIST_BECARDS";
export const LIST_USER_BECARDS = "LIST_USER_BECARDS";
export const REQUEST_BECARD = "REQUEST_BECARD";
export const CANCEL_BECARD = "CANCEL_BECARD";
export const REQUEST_ONE_BECARD = "REQUEST_ONE_BECARD";
export const CANCEL_ONE_BECARD = "CANCEL_ONE_BECARD";
export const SUCCESS_BECARD = "SUCCESS_BECARD";
export const ERROR_BECARD = "ERROR_BECARD";
export const RESET_BECARD = "RESET_BECARD";
export const SET_ALL_BECARDS = "SET_ALL_BECARDS";
export const SET_USER_BECARDS = "SET_USER_BECARDS";
export const SET_FILTERED_BECARDS = "SET_FILTERED_BECARDS";
export const SET_ONE_BECARD = "SET_ONE_BECARD";
export const SET_SEL_BECARD = "SET_SEL_BECARD";
// export const SET_INFO_BECARD = "SET_INFO_BECARD";

export const start_get_becard=(becard_id)=>({
  type:START_GET_BECARD,
  becard_id
})

export const start_search_becards=(search_URL)=>({
  type:SEARCH_BECARDS,
  search_URL
})

export const start_update_becard=(dataBecard,becard_id,images)=>({
  type:START_UPDATE_BECARD,
  dataBecard,
  becard_id,
  images
})

export const search_one_becard=(becard_URL)=>({
  type:SEARCH_ONE_BECARD,
  becard_URL
})

export const set_all_becards=(all_becards)=>({
  type:SET_ALL_BECARDS,
  all_becards
})

export const set_filtered_becards=(filtered_becards)=>({
  type:SET_FILTERED_BECARDS,
  filtered_becards
})

export const set_user_becards=(user_becards)=>({
  type:SET_USER_BECARDS,
  user_becards
})

export const set_sel_becard=(sel_becard)=>({
  type:SET_SEL_BECARD,
  sel_becard
})

export const list_becards=(becard_URL)=>({
  type:LIST_BECARDS,
  becard_URL
})

export const list_user_becards=()=>({
  type:LIST_USER_BECARDS
})

export const create_becard=(dataBecard,images)=>({
  type:CREATE_BECARD,
  dataBecard,
  images
})

export const request_becard=()=>({
  type:REQUEST_BECARD,
  isFetching:true
})

export const cancel_becard=()=>({
  type:CANCEL_BECARD,
  isFetching:false
})

export const request_one_becard=()=>({
  type:REQUEST_ONE_BECARD,
  isFetchingOne:true
})

export const cancel_one_becard=()=>({
  type:CANCEL_ONE_BECARD,
  isFetchingOne:false
})

export const success_becard=(message)=>({
  type:SUCCESS_BECARD,
  message
})

export const error_becard=(error)=>({
  type:ERROR_BECARD,
  error
})

export const set_one_becard=(becard)=>({
  type:SET_ONE_BECARD,
  becard
})

// export const set_info_becard=(info_becard)=>({
//   type:SET_INFO_BECARD,
//   info_becard
// })

export const reset_becard=()=>({
  type:RESET_BECARD,
  message:false,
  error:false
})
