import {takeLatest,put,call,select} from 'redux-saga/effects';
import * as actions from '../redux/actions/user-actions'
import {set_user_login} from '../redux/actions/login-actions';
import {add_toast} from '../redux/actions/toasts-actions';
import {api_private} from '../services';

function* postUpdateUser({dataUser,images}){
  yield put(actions.reset_user())
  yield put(actions.request_user())
  const user = yield select(({loginReducer:{user}})=>user);
  const lang = yield select(({globalReducer:{lang}})=>lang)
  try {
    if(images){
      const upload = yield call(api_private.uploadImages,images)
      if(upload.image_urls&&upload.image_urls.length){
        let dataImg = {
          ...dataUser,
          profile_img:upload.image_urls[0]
        }
        const updated = yield call(api_private.updateUser,dataImg,user._id)
        if(updated.user){
          yield put(add_toast({
            error:false,
            text:updated.message[lang]
          }))
          yield put(set_user_login(updated.user))
        }
      }
    } else {
      const updated = yield call(api_private.updateUser,dataUser,user._id)
      if(updated.user._id){
        yield put(add_toast({
          error:false,
          text:updated.message[lang]
        }))
        yield put(set_user_login(updated.user))
      }
    }
  } catch (error) {
    yield put(actions.error_user(error))
    yield put(add_toast({
      error:true,
      text:error
    }))
    yield put (actions.cancel_user())
  }

}

export function* watchUser(){
  yield takeLatest(actions.START_UPDATE_USER,postUpdateUser)

}
