import React from 'react';
import {connect} from 'react-redux';
import {SignupForm} from '../../components/forms';
import {add_toast} from '../../redux/actions/toasts-actions';
import {start_signup,cancel_signup} from '../../redux/actions/signup-actions';

function SignupFormCont({add_toast,cancel_signup,start_signup,...rest}){
  return(
    <SignupForm
        cancelSignup={cancel_signup}
        startSignup={start_signup}
        addToast={add_toast}
        {...rest}/>
  )
}

const mapStateToProps = ({
  signupReducer,
  globalReducer:{lang}
})=>({
  ...signupReducer,
  lang
})

const mapActionsToProps={
  add_toast,
  start_signup,
  cancel_signup
}

export default connect(mapStateToProps,mapActionsToProps)(SignupFormCont);
