import React from 'react';
import {connect} from 'react-redux';
import {CalendarSmall} from '../../components/platform/elements/';

function CalendarSmallCont({...rest}){
  return(
    <CalendarSmall {...rest}/>
  )
}

const mapStateToProps=({
  globalReducer:{lang}
})=>({
  lang
})

const mapActionsToProps={

}

export default connect(mapStateToProps,mapActionsToProps)(CalendarSmallCont)
