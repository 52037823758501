import React,{Component} from 'react';
import {
  Input,
  InputGroup,
  Badge,
  Button,
  ListGroupItem,
  InputGroupAddon,
  FormText,
  FormGroup
} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {TAGS_MESSAGES,MAX_TAGS} from '../../../constants/platform'

export default class TagsInput extends Component{
  constructor(props){
    super(props)
    this.state={
      currTag:'',
      localMessage:''
    }
  }
  getValue=(e)=>{
    const {value,name} = e.target
    this.setState({
      [name]:`${value.slice(0,1).toUpperCase()}${value.slice(1)}`
    })
  }
  keyHandler=(e)=>{
    if(e.key==='Enter'){
      e.preventDefault()
      e.stopPropagation()
      this.addTag()
    }
    // else if (e.key==='ArrowLeft'){
    //   this.changePossibleQ(false)
    // }
  }
  addTag=()=>{
    const {tags,nameInput,addToast,lang} = this.props
    const {currTag} = this.state
    const found = tags.find(el=>el===currTag)
    if(found){
      addToast({
        text:TAGS_MESSAGES.REPEATED[lang],
        error:true
      })
    }
    if(tags.length===MAX_TAGS){
      addToast({
        text:TAGS_MESSAGES.MAXED[lang],
        error:true
      })
    }
    if(currTag!==''&&!found&&tags.length<MAX_TAGS){
      const newT = currTag.charAt(0).toUpperCase() + currTag.slice(1).trim()
      let newTags = [...tags,newT]
      this.props.getValue({target:{value:newTags,name:nameInput}})
      this.setState({
        currTag:''
      })
    }
  }
  delTag=(i)=>{
    const {tags,nameInput} = this.props
    let newTags =[...tags.slice(0,i),...tags.slice(i+1)]
    this.props.getValue({target:{value:newTags,name:nameInput}})
  }
  render(){
    const {tags} = this.props
    const {currTag,localMessage} = this.state
    return(
      <ListGroupItem onKeyPress={this.keyHandler}>
        {tags.map((el,i)=>
          <Badge key={i} className="close_bagde m-1" color="primary">
            {el}&nbsp;<Button onClick={()=>this.delTag(i)} close />
          </Badge>
        )}
        <FormGroup>
          <InputGroup className="my-2 justify-content-center animated fadeIn">
            <Input bsSize="sm" onChange={this.getValue} value={currTag} name={'currTag'} type="text"/>
            <InputGroupAddon addonType="append">
              <Button size="sm" onClick={this.addTag} color="primary">
                <FontAwesomeIcon icon="plus"/>
              </Button>
            </InputGroupAddon>
          </InputGroup>
          {
            localMessage!==''&&
            <FormText color="warning">{localMessage}</FormText>
          }
        </FormGroup>
      </ListGroupItem>
    )
  }
}
