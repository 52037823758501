import React from 'react';
import {connect} from 'react-redux';
import {SlideMenu} from '../../components/platform/elements';
import {toggle_slide_menu} from '../../redux/actions/platform-actions';

function SlideMenuCont({toggle_slide_menu,...rest}){
  return(
    <SlideMenu toggleSlideMenu={toggle_slide_menu} {...rest}/>
  )
}

const mapStateToProps=({
  globalReducer:{
    lang
  },
  platformReducer:{
    slide_content,
    slide_menu
  },
  becardReducer:{sel_becard}
})=>({
  slide_menu,
  slide_content,
  lang,
  sel_becard
})

const mapActionsToProps={
  toggle_slide_menu
}

export default connect(mapStateToProps,mapActionsToProps)(SlideMenuCont);
