import React,{Component} from 'react';
import {Col} from 'reactstrap';
// import {CalendarSmCont} from '../../containers';

export default class SecondaryBar extends Component{
  constructor(props){
    super(props)
    this.state={

    }
  }
  render(){
    const {className} = this.props
    return(
      <Col xs={12} md={3} className={`${className}`}>
        {this.props.children}
      </Col>
    )
  }

}
