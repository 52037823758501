import React, { useEffect } from "react";
import { connect } from "react-redux";
import { BeCardForm } from "../../components/forms";
import { add_toast } from "../../redux/actions/toasts-actions";
import {
  create_becard,
  cancel_one_becard,
} from "../../redux/actions/becard-actions";
import { list_countries } from "../../redux/actions/country-actions";
import { toggle_form_modal } from "../../redux/actions/platform-actions";

function BeCardFormCont({
  toggle_form_modal,
  cancel_one_becard,
  form_modal,
  // toggle,
  add_toast,
  create_becard,
  user_becards,
  list_countries,
  countries,
  countriesError,
  ...rest
}) {
  useEffect(() => {
    if (!countriesError && !countries.length) {
      list_countries();
    }
  }, [countries, countriesError, list_countries]);
  const cancelRequest = () => {
    cancel_one_becard();
    if (form_modal) {
      toggle_form_modal();
    }
  };
  return (
    <BeCardForm
      createBeCard={create_becard}
      cancelRequest={cancelRequest}
      // toggle={toggle}
      addToast={add_toast}
      countries={countries}
      {...rest}
    />
  );
}

const mapStateToProps = ({
  becardReducer,
  globalReducer: { lang },
  loginReducer: { user },
  platformReducer: { form_modal },
  countryReducer: {
    countries,
    isFetching: isFetchingCountries,
    error: countriesError,
  },
}) => ({
  ...becardReducer,
  lang,
  user,
  form_modal,
  countries,
  isFetchingCountries,
  countriesError,
});

const mapActionsToProps = {
  add_toast,
  create_becard,
  cancel_one_becard,
  toggle_form_modal,
  list_countries,
};

export default connect(mapStateToProps, mapActionsToProps)(BeCardFormCont);
