import React, { useEffect } from "react";
import { connect } from "react-redux";
import { CardsRoster } from "../../components/platform/elements";
import {
  list_becards,
  list_user_becards,
  start_get_becard,
} from "../../redux/actions/becard-actions";
import {
  toggle_slide_menu,
  set_slide_content,
} from "../../redux/actions/platform-actions";

function CardsRosterCont({
  list_becards,
  start_get_becard,
  set_slide_content,
  list_user_becards,
  toggle_slide_menu,
  all_becards,
  user_becards,
  platform,
  user,
  list_countries,
  ...rest
}) {
  useEffect(() => {
    if (!platform && !all_becards.length) {
      list_becards("");
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (platform && user && !user_becards.length) {
      list_user_becards();
    }
  }, [list_user_becards, platform, user, user_becards.length]);
  const editBeCard = (becard_id) => {
    start_get_becard(becard_id);
    set_slide_content("editBeCard");
    toggle_slide_menu();
  };
  return (
    <CardsRoster
      all_becards={all_becards}
      editBeCard={editBeCard}
      user_becards={user_becards}
      platform={platform}
      {...rest}
    />
  );
}

const mapStateToProps = ({
  becardReducer,
  globalReducer: { lang },
  loginReducer: { user },
  filterReducer: { filtered },
}) => ({
  ...becardReducer,
  lang,
  user,
  filtered,
});

const mapActionsToProps = {
  list_becards,
  list_user_becards,
  start_get_becard,
  toggle_slide_menu,
  set_slide_content,
};

export default connect(mapStateToProps, mapActionsToProps)(CardsRosterCont);
