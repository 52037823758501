import React from "react";
import {
  Jumbotron,
  Container,
  Row,
  Col,
  Button,
  // Button
} from "reactstrap";
//import {Link} from 'react-router-dom';
import "../../style/jumbo.css";

function Jumbo({
  heroText,
  leadText,
  className,
  heroImg,
  background,
  bgTop,
  buttonText,
  gotoBtn,
}) {
  return (
    <Jumbotron className={`${className} bg-transparent abs_cont`} fluid>
      {background && (
        <img
          alt="background"
          className={background.className}
          src={background.src}
        />
      )}
      {bgTop && (
        <img alt="background" className={bgTop.className} src={bgTop.src} />
      )}
      <Container>
        <Row className="sect_80 align-items-center">
          <Col xs={{ size: 12, order: 2 }} md={{ size: 5, order: 1 }}>
            <p className="multiline">{leadText}</p>
            <h3 className="font-weight-bold multiline">{heroText}</h3>
            <Button className="mt-2" color="primary" tag="a" href={gotoBtn}>
              {buttonText}
            </Button>
          </Col>
          <Col
            className="mb-5 mb-md-1"
            xs={{ size: 12, order: 1 }}
            md={{ size: 7, order: 2 }}
          >
            <img className="img-fluid" src={heroImg.src} alt={heroImg.alt} />
          </Col>
        </Row>
      </Container>
    </Jumbotron>
  );
}

export default Jumbo;
