import {
  SET_SEL_FORM,
  SET_SLIDE_CONTENT,
  TOGGLE_SLIDE_MENU,
  TOGGLE_FORM_MODAL
} from '../actions/platform-actions';

export const platformReducer=(
  state={
    sel_form:'profileUpdate',
    slide_content:false,
    slide_menu:false,
    form_modal:false
  },
  action)=>{
    const {
      type,
      sel_form,
      slide_content
    } = action
    switch (type) {
      case SET_SEL_FORM:
        return({...state,sel_form})
      case SET_SLIDE_CONTENT:
        return({...state,slide_content})
      case TOGGLE_SLIDE_MENU:
        return({...state,slide_menu:!state.slide_menu})
      case TOGGLE_FORM_MODAL:
        return({...state,form_modal:!state.form_modal})

      default:
        return({...state})

    }

}
