import React from 'react';
import {connect} from 'react-redux';
import {ProfileNav} from '../../components/platform/elements';
import {set_sel_form,toggle_form_modal} from '../../redux/actions/platform-actions';

function ProfileNavCont({set_sel_form,toggle_form_modal,...rest}){
  const fillProfile=()=>{
    set_sel_form('profileUpdate')
    toggle_form_modal()
  }
  return(
    <ProfileNav fillProfile={fillProfile} {...rest}/>
  )
}

const mapStateToProps=({
  loginReducer
})=>({
  ...loginReducer
})

const mapActionsToProps = {
  set_sel_form,
  toggle_form_modal
}

export default connect(mapStateToProps,mapActionsToProps)(ProfileNavCont)
