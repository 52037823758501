import React,{Component} from 'react';
import lottie from 'lottie-web';
import data from '../../assets/bodym/fail_succ.json';
import '../../style/anims.css';

export default class AnimSend extends Component{
  constructor(props){
    super(props)
    this.send=React.createRef()
    this.state={
      nextSegm:[[75,100]]
    }
  }
  componentDidMount=()=>{
    const {error} = this.props
    // eslint-disable-next-line
      this.animation = lottie.loadAnimation({
        container: this.send.current,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: data
      })
      // console.log('onmount error',error)
      if(error){
        this.animation.playSegments([[0,75],[100,125]],true)
      } else {
        this.animation.playSegments([[0,100]],true)
      }
      this.animation.addEventListener('complete',()=>{
        this.props.endAnim()
      })

      // this.animation.addEventListener('complete',()=>{
      //   // this.animation.removeEventListener('complete')
      //   this.playAll()
      // })
  }
  componentWillUnmount=()=>{
    this.animation.removeEventListener('complete')
  }
  componentDidUpdate=(prevProps)=>{
    const {error} = this.props
    if(error&&prevProps.error!==error){
      this.setState({
        nextSegm:[[100,125]]
      })
    }
    // if(error)
  }
  playAll=()=>{
    const {nextSegm} = this.state
    this.animation.playSegments(nextSegm,true)
    this.animation.addEventListener('complete',()=>{
      this.props.endAnim()
    })
  }
  render(){
    return(
      <div className="animSend lottie" ref={this.send}/>
    )
  }
}
