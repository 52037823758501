import { combineReducers } from "redux";

import { loginReducer } from "./login-reducer";
import { globalReducer } from "./global-reducer";
import { toastsReducer } from "./toasts-reducer";
import { becardReducer } from "./becard-reducer";
import { signupReducer } from "./signup-reducer";
import { userReducer } from "./user-reducer";
import { platformReducer } from "./platform-reducer";
import { filterReducer } from "./filter-reducer";
import { subscribeReducer } from "./subscribe-reducer";
import { companyReducer } from "./company-reducer";
import { countryReducer } from "./country-reducer";

const rootReducer = () =>
  combineReducers({
    loginReducer,
    toastsReducer,
    becardReducer,
    globalReducer,
    signupReducer,
    userReducer,
    platformReducer,
    filterReducer,
    subscribeReducer,
    companyReducer,
    countryReducer,
  });

export default rootReducer;
