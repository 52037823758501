import React from 'react';
import {Link} from 'react-router-dom';
import {Jumbotron,Container,Row,Col} from 'reactstrap';
import '../../style/jumbo.css'

import overlay from '../../assets/img/overlay_hero.svg';

// xoBg77epln0
// pG_DCIUtyvo
// 31QfYo1h32o
function JumboColor({header,lead,cta,link='/'}){
  return (
    <Jumbotron className="jumbo_sections gradPurple d-flex flex-column justify-content-center mb-0 text-light abs_cont" fluid>
     <img alt="" className="hero_overlay" src={overlay}/>
    <Container className="front_hero align-items-center">
      <Row className="">
        <Col>
          <h1 className="animated fadeInPlace display-3">
          {header}
          </h1>
          <p className="lead">
            {lead}
          </p>
          <hr className="hr_contrast w-75 border-light"/>
          <br/>
          {cta!==''&&
          <Link to={link} color="primary" className="btn_main">
            {cta}
          </Link> }
        </Col>
      </Row>
    </Container>

  </Jumbotron>
  )
}

export default JumboColor;
