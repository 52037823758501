import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { SEO } from "../elements";
import {
  LoginFormCont,
  SignupFormCont,
  ForgPassFormCont,
} from "../../containers";
import { ErrorBoundary } from "../elements";
import { useQuery } from "../../hooks";
import "../../style/login.css";

function Login({ history }) {
  const { card } = useQuery();

  const [isSignUp, setIsSignUp] = useState(
    card && card === "signup" ? true : false
  );
  const [forgPassword, setForgPass] = useState(false);

  const setSignUp = () => {
    setIsSignUp(true);
    setForgPass(false);
  };
  const setForgot = () => {
    setForgPass(true);
    setIsSignUp(false);
  };
  const setLogin = () => {
    setForgPass(false);
    setIsSignUp(false);
  };
  const fond = {
    backgroundImage: `linear-gradient(to top, rgba(0,0,0,.3),rgba(0,0,0,.8)),url('https://source.unsplash.com/HALe2SmkWAI')`,
    position: "relative",
    backgroundSize: "cover",
    minHeight: "100vh",
  };
  return (
    <>
      <SEO title="Login" />
      <Container className="login_bg" style={fond} fluid>
        <Container className="">
          <Row className="h-100 login_cont align-items-center">
            <Col
              xs={12}
              md={isSignUp ? 8 : 6}
              lg={isSignUp ? 6 : 4}
              className="ml-auto"
            >
              <ErrorBoundary>
                {!isSignUp && !forgPassword ? (
                  <LoginFormCont
                    history={history}
                    setSignUp={setSignUp}
                    setForgot={setForgot}
                  />
                ) : isSignUp ? (
                  <SignupFormCont
                    setLogin={setLogin}
                    // {...rest}
                  />
                ) : (
                  <ForgPassFormCont setSignUp={setSignUp} />
                )}
              </ErrorBoundary>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default Login;
