import React,{useEffect} from 'react';
import {connect} from 'react-redux';
import {ResetPForm} from '../../components/forms';
import {start_verify_token,cancel_login,start_reset_pass} from '../../redux/actions/login-actions';
import {PLATF_REDIR_TIMEOUT} from '../../constants/platform'

function ResetPFormCont({
  start_verify_token,
  cancel_login,
  start_reset_pass,
  user,
  token,
  history,
  match:{
    params:{reset_pass_token}
  },
  ...rest
}){
  useEffect(()=>{
    if(reset_pass_token){
      start_verify_token(reset_pass_token)
    }
  },[reset_pass_token, start_verify_token])
  // TODO: abstract this as a util hooks
  useEffect(()=>{
    if(user&&token){
      setTimeout(()=>{
        history.push('/platform')
      },PLATF_REDIR_TIMEOUT)
    }
  },[user, token, history])
  return(
    <ResetPForm
    startResetP={start_reset_pass}
    cancelLogin={cancel_login}
    reset_pass_token={reset_pass_token}
     {...rest}/>
  )
}

const mapStateToProps=({
  loginReducer,
  globalReducer:{lang}
})=>({
  ...loginReducer,
  lang
})

const mapActionsToProps={
  start_verify_token,
  cancel_login,
  start_reset_pass
}

export default connect(mapStateToProps,mapActionsToProps)(ResetPFormCont)
