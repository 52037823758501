import React, { Fragment, useState } from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  Form,
  Row,
  Col,
  FormGroup,
  FormFeedback,
  Input,
  Label,
  InputGroup,
  InputGroupAddon,
  Button,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimFailSucc } from "../elements";
//import {LABELS_FORM_SIGNUP} from '../../constants/platform';

function LoginForm({
  cancelLogin,
  startLogin,
  isFetching,
  error,
  message,
  setSignUp,
  setForgot,
  // lang
}) {
  const [formVals, setFormVals] = useState({ login: "", passW: "" });
  const [showPass, setShowPass] = useState(false);
  const passWname = "passW";
  const loginName = "login";
  const getValue = ({ target: { value, name } }) => {
    setFormVals({ ...formVals, [name]: value });
  };
  const { login, passW } = formVals;
  const handleForm = (e) => {
    e.preventDefault();
    if (passW !== "") {
      startLogin({
        password: passW,
        email: login,
      });
      setFormVals({ login: "", passW: "" });
    }
  };
  const showPassW = () => {
    setShowPass(!showPass);
  };
  const validUsername = new RegExp(/@/).test(login);
  return (
    <Card className="animated fadeInPlace">
      <CardHeader className="font-weight-bold">Acceder</CardHeader>
      <CardBody>
        <Form onSubmit={handleForm}>
          {isFetching ? (
            message || error ? (
              <Row className="justify-content-center">
                <Col>
                  <AnimFailSucc error={error} endAnim={cancelLogin} />
                </Col>
              </Row>
            ) : null
          ) : (
            <Fragment>
              <FormGroup>
                <Label>
                  <i className="fas fa-at" />
                  &nbsp; Email
                </Label>
                <Input
                  onChange={getValue}
                  valid={login.length ? validUsername : null}
                  invalid={login.length ? null : validUsername}
                  value={login}
                  type="text"
                  autoComplete="username"
                  name={loginName}
                  id={loginName}
                />
                <FormFeedback valid>Súper</FormFeedback>
                <FormFeedback>Inválido</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>
                  <i className="fas fa-lock" />
                  &nbsp; Contraseña
                </Label>
                <InputGroup>
                  <Input
                    onChange={getValue}
                    value={passW}
                    autoComplete="current-password"
                    type={showPass ? "text" : "password"}
                    placeholder="contraseña"
                    name={passWname}
                    id={passWname}
                  />
                  <InputGroupAddon addonType="append">
                    <Button onClick={showPassW} active={showPass}>
                      <FontAwesomeIcon icon={showPass ? "eye" : "eye-slash"} />
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>

              <FormGroup>
                <Button type="submit" color="primary" block>
                  Ingresar
                </Button>
              </FormGroup>
              <FormGroup>
                <Button onClick={setForgot} color="link" block>
                  ¿Olvidaste tu contraseña?
                </Button>
              </FormGroup>
              {/* <FormGroup>
            <Button
              onClick={checkFacebook}
              disabled
              className="fb_button mt-2" block>
              <i className="fab fa-facebook"/>&nbsp;Continuar con Facebook
            </Button>
          </FormGroup> */}
            </Fragment>
          )}
          {/* <FormGroup>
            {message !== '' && <FormText>{message}</FormText>}
            {error !== '' && <FormText color="danger">{error}</FormText>}
            {localMessage !== '' && <FormText color="warning">{localMessage}</FormText>}
          </FormGroup> */}
        </Form>
      </CardBody>
      <CardFooter>
        <b>¿No tienes cuenta?</b>
        <Button onClick={setSignUp} className="d-inline" color="link">
          Crea una
        </Button>
      </CardFooter>
    </Card>
  );
}

export default LoginForm;
