import React, {Component} from 'react';
import {
  // Container,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  ButtonGroup,
  Button,
  ListGroupItem,
  ListGroupItemHeading,
  // Badge,
  ListGroup
} from 'reactstrap';
import {CSSTransition} from 'react-transition-group';
import {days_min_es, months_full_es,optDate} from '../../../utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {LABELS_CALENDAR} from '../../../constants/platform';

import '../../../style/calendar.css'

export default class CalendarSmall extends Component {
  constructor(props) {
    super(props)
    this.state = {
      today: new Date(),
      todayControl: new Date().toISOString().split('T')[0],
      selDay: new Date().toISOString().split('T')[0],
      currYear: new Date().getFullYear(),
      currMonth: new Date().getMonth(),
      totalDays: 30,
      firstWDay: 0
    }
  }
  componentDidMount = () => {
    const {today} = this.state
    this.setState({
      totalDays: new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate(),
      firstWDay: new Date(today.getFullYear(), today.getMonth(), 0).getDay()
    })
  }
  nextMonth = (flag) => {
    const {currMonth, currYear} = this.state
      let nMonth = currMonth,
          nYear = currYear
      if(flag){
        if(currMonth===11){
          nMonth = 0
          nYear++
        } else {
          nMonth++
        }
      } else {
        if(currMonth===0){
          nMonth = 11
          nYear--
        } else {
          nMonth--
        }
      }

      this.setState({
        refDate: new Date(nYear, nMonth, 1),
        currMonth: nMonth,
        currYear: nYear
      }, this.changeDate)
    }
    changeDate = () => {
      const {refDate} = this.state
      this.setState({
        totalDays: new Date(refDate.getFullYear(), refDate.getMonth() + 1, 0).getDate(),
        firstWDay: new Date(refDate.getFullYear(), refDate.getMonth(), 0).getDay()
      })
    }
    renderNavMonth = () => {
      const {currMonth, currYear} = this.state
      return (<ButtonGroup className="navMonth my-1 d-flex">
        <Button onClick={() => this.nextMonth(false)} outline className="p-1 navCal calPrv  ml-0">
          <FontAwesomeIcon icon="angle-left"/></Button>
        <div className="monthIndicator d-flex align-items-center justify-content-center">
          {`${months_full_es[currMonth]} ${currYear}`}
        </div>
        <Button onClick={() => this.nextMonth(true)} outline className="p-1 navCal calNxt mr-0">
          <FontAwesomeIcon icon="angle-right"/>
        </Button>
      </ButtonGroup>)
    }
    selDay = (c) => {
      const d = c.split('-')
      this.setState({
        selDay: new Date(d[0], d[1] - 1, d[2]).toISOString().split('T')[0]
      })
    }
    renderDaysNames = () => {
      return (<div className="d-flex justify-content-start">
        {days_min_es.map((obj, i) => <div key={i} className="dayName">
          {/*<span className="d-none d-xl-block">{obj}</span>*/}
          <span className="d-block">{obj.slice(0,1)}</span>
        </div>)}
      </div>)
    }
    renderDays = () => {
      const {calendarDates} = this.props
      const {
        todayControl,
        selDay,
        firstWDay,
        totalDays,
        currYear,
        currMonth
      } = this.state
      const markDays = firstWDay + totalDays
      let days = []
      for (let i = 0; i < markDays; i++) {
        let control = new Date(currYear, currMonth, (i + 1 - firstWDay)).toISOString().split('T')[0];
        let activ = false
        if(calendarDates.length){
          activ = calendarDates.find((el)=>new Date(el.date).toISOString().split('T')[0] === control)
        }
        days = days.concat([<span onClick={() => this.selDay(control)} key={control} className={`dayCal d-flex flex-column justify-content-center ${control === todayControl && 'today'} ${selDay === control && 'selDay'} ${activ && 'activ'}`}>
          {control.split('-')[2]}
          {/* {i+1-firstWDay} */}
        </span>
          ])
      }
      let rows = []
      //eslint-disable-next-line
      days.map((day, i) => {
        if (i % 7 === 0) {
          rows = rows.concat([<div key={i + 2} className="daysContRow d-flex justify-content-start">
            {days.slice(i, i + 7)}
          </div>
            ])
        }
      })
      return (<CSSTransition timeout={500} classNames="slidein" in={true} appear={true}>
        <div className="daysCont">
          {rows}
        </div>
      </CSSTransition>)
    }
    renderActivities = () => {
          const {calendarDates,lang} = this.props
          const {selDay} = this.state
          let activ =  calendarDates.filter(el=>{
            const cont = new Date(el.date).toISOString().split('T')[0]
            return cont === selDay
          })
          // console.log(activ)
          return (
          <div className="activities" >
            <div>
                {`${activ.length?LABELS_CALENDAR.ACTIVITIES[lang]:LABELS_CALENDAR.NO_ACTIVITIES[lang]}`}
                <hr/>
            </div>
            <ListGroup>
              {activ.map((obj,i)=>
                <ListGroupItem className="animated fadeInUp bg-transparent" key={i}>
                  {/* <Badge color="primary">
                  {ACTIVITIES_LABELS[obj.type]}
                </Badge>&nbsp;<br/> */}
                <ListGroupItemHeading>{obj.reference}</ListGroupItemHeading>
              <FontAwesomeIcon icon={['far','clock']}/>{` ${new Date(obj.date).toLocaleString('es-MX',optDate)}`} <br/>
              </ListGroupItem>)
              }
            </ListGroup>
          </div>

        )

    }
    render() {
      const {className} = this.props
      return (<Card className={`${className} bg-transparent text-light calendar_small`}>
        <CardHeader>
          <CardTitle>
            Calendario
          </CardTitle>
        </CardHeader>
        <CardBody>
            {/*{this.renderNavMonth()}
            {this.renderDaysNames()}
            {this.renderDays()}
            {this.renderActivities()}*/}
            <b>Proximamente</b> <br/>
            Deja que tus contactos aparten tu siguiente espacio disponible con el asistente virtual

        </CardBody>
      </Card>)
    }
  }
