import React from 'react';
import {Container,Row,Col,Button} from 'reactstrap';
import {EditableBeCardCont} from '../../../containers';
import {SLIDE_MENU_CONTENT} from '../../../constants/platform';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import '../../../style/slidemenu.css'

function SlideMenu ({
  lang,
  toggleSlideMenu,
  slide_menu,
  slide_content,
  sel_becard
}){
  return (
    <Container className={`slideout ${slide_menu&&'slidein'}`}>
      <Row className="pt-5 text-left">
        <Col className="d-flex justify-content-start">
          <Button onClick={toggleSlideMenu} close />
        </Col>
      </Row>
      <Row>
        <Col>
          <h3>
            {slide_content
              ?SLIDE_MENU_CONTENT[slide_content].title[lang]
              :''
            }
          </h3>
        </Col>
      </Row>
      <Row>
        <Col>
          {slide_content==='editBeCard'&&sel_becard._id
          ?<EditableBeCardCont/>
          :<FontAwesomeIcon spin icon="atom"/>
          }
        </Col>
      </Row>

    </Container>
  )
}

export default SlideMenu;
