import {takeLatest,put,call,select} from 'redux-saga/effects';
import * as actions from '../redux/actions/signup-actions';
import {set_user_login,set_token_login} from '../redux/actions/login-actions';
import {add_toast} from '../redux/actions/toasts-actions';
import {api_public,local} from '../services';

function* postSignupData({dataSignup}){
  yield put(actions.reset_signup())
  yield put(actions.request_signup())
  const lang = yield select(({globalReducer:{lang}})=>lang)
  try {

    const signup = yield call(api_public.postSignUp,dataSignup)
    if(signup.error){
      yield put(actions.error_signup(signup.error[lang]))
      yield put(add_toast({
        text:signup.error[lang],
        error:true
      }))
      // yield put(actions.cancel_signup())
    } else {
      yield put(actions.success_signup(signup.message[lang]))
      yield put(add_toast({
        error:false,
        text:signup.message[lang]
      }))
    }
  } catch (error) {
    yield put(actions.error_signup(error))
    yield put(add_toast({
      text:error.toString(),
      error:true
    }))
  }
}

function* postVerifyData({dataVerify}){
  yield put(actions.request_signup())
  try {
    const lang = yield select(({globalReducer:{lang}})=>lang)
    const signup = yield call(api_public.postVerify,dataVerify)
    if(signup.error){
      yield put(actions.error_signup(signup.error))
      yield put(add_toast({
        text:signup.error,
        error:true
      }))
      yield put(actions.cancel_signup())
    } else {
      yield put(actions.success_signup(signup.message[lang]))
      yield call(local.setLocalToken,signup.token)
      yield put(set_user_login(signup.user))
      yield put(set_token_login(signup.token))
      yield put(add_toast({
        error:false,
        text:signup.message[lang]
      }))
      yield put(actions.cancel_signup())
    }
  } catch (error) {
    yield put(actions.error_signup(error))
    yield put(add_toast({
      text:error.toString(),
      error:true
    }))
    yield put(actions.cancel_signup())
  }

}

export function* watchPostDataSignup(){
  yield takeLatest(actions.START_SIGNUP,postSignupData)
  yield takeLatest(actions.START_VERIFY_SIGNUP,postVerifyData)
}
