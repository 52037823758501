import { takeLatest, put, call, select } from "redux-saga/effects";
import * as actions from "../redux/actions/becard-actions";
import { add_toast } from "../redux/actions/toasts-actions";
import { api_public, api_private } from "../services";
import { getStateLang } from "./utils";
import { LOCAL_MESSAGES } from "../constants";

// TODO: Cambiar la mecánica de obtener 1 becard con parámetro de id
function* getBeCardSearch({ becard_URL }) {
  yield put(actions.request_one_becard());
  try {
    const lang = yield getStateLang();
    const becard = yield call(api_public.getSearchBeCard, becard_URL);
    if (becard.error) {
      yield put(actions.error_becard(becard.error));
      yield put(
        add_toast({
          error: true,
          text: becard.error,
        })
      );
      yield put(actions.cancel_one_becard());
    } else {
      yield put(actions.success_becard(becard.message));
      if (becard.becards.length) {
        yield put(actions.set_one_becard(becard.becards[0]));
        yield put(
          add_toast({
            error: false,
            text: becard.message[lang],
          })
        );
      } else {
        yield put(
          add_toast({
            error: true,
            text: LOCAL_MESSAGES.NO_BECARD[lang],
          })
        );
      }

      yield put(actions.cancel_one_becard());
    }
  } catch (error) {
    yield put(actions.error_becard(error));
    yield put(
      add_toast({
        error: true,
        text: error,
      })
    );
    yield put(actions.cancel_one_becard());
  }
}

function* getBeCardsSearch({ search_URL }) {
  yield put(actions.set_filtered_becards([]));
  yield put(actions.request_becard());
  const lang = yield getStateLang();
  try {
    const becards = yield call(api_public.getSearchBeCard, search_URL);
    if (becards.error) {
      yield put(actions.error_becard(becards.error));
      yield put(
        add_toast({
          error: true,
          text: becards.error[lang],
        })
      );
      yield put(actions.cancel_becard());
    } else {
      yield put(actions.success_becard(becards.message));
      yield put(actions.set_filtered_becards(becards.becards));
      yield put(
        add_toast({
          error: becards.becards.length ? false : true,
          text: becards.message[lang],
        })
      );
      yield put(actions.cancel_becard());
    }
  } catch (error) {
    yield put(actions.error_becard(error));
    yield put(
      add_toast({
        error: true,
        text: error,
      })
    );
    yield put(actions.cancel_becard());
  }
}

function* getAllBeCards({ becard_URL }) {
  yield put(actions.request_becard());
  try {
    const lang = yield getStateLang();
    console.log("lang", lang);
    const becards = yield call(api_public.getAllBeCards, becard_URL);
    if (becards.error) {
      yield put(actions.error_becard(becards.error));
      yield put(
        add_toast({
          error: true,
          text: becards.error,
        })
      );
      yield put(actions.cancel_becard());
    } else {
      yield put(actions.success_becard(becards.message));
      yield put(actions.set_all_becards(becards.becards));
      yield put(
        add_toast({
          error: false,
          text: becards.message[lang],
        })
      );
      yield put(actions.cancel_becard());
    }
  } catch (error) {
    yield put(actions.error_becard(error));
    yield put(
      add_toast({
        error: true,
        text: error,
      })
    );
    yield put(actions.cancel_becard());
  }
}

function* postDataBeCard({ dataBecard, images }) {
  yield put(actions.reset_becard());
  yield put(actions.request_one_becard());
  const lang = yield getStateLang();
  try {
    if (images) {
      const upload = yield call(api_private.uploadImages, images);
      if (upload.image_urls && upload.image_urls.length) {
        let dataImg = {
          ...dataBecard,
          profile_img: upload.image_urls[0],
        };
        const created = yield call(api_private.createBeCard, dataImg);
        if (created.becard) {
          yield put(
            add_toast({
              error: false,
              text: created.message[lang],
            })
          );
          yield getUserBeCards();
        }
      }
    } else {
      const created = yield call(api_private.createBeCard, dataBecard);
      if (created.becard) {
        yield put(
          add_toast({
            error: false,
            text: created.message[lang],
          })
        );
        yield getUserBeCards();
      } else if (created.error) {
        yield put(actions.error_becard(created.error));
        yield put(
          add_toast({
            error: true,
            text: created.error[lang],
          })
        );
        yield put(actions.cancel_one_becard());
      }
    }
  } catch (error) {
    yield put(actions.error_becard(error));
    yield put(
      add_toast({
        error: true,
        text: error.toString(),
      })
    );
    yield put(actions.cancel_one_becard());
  }
}

function* getUserBeCards() {
  yield put(actions.reset_becard());
  yield put(actions.request_becard());
  try {
    const user = yield select(({ loginReducer: { user } }) => user);
    const lang = yield getStateLang();
    const becards = yield call(
      api_public.getAllBeCards,
      `?search=true&user=${user._id}`
    );
    if (becards.error) {
      yield put(actions.error_becard(becards.error));
      yield put(actions.cancel_becard());
    } else {
      yield put(actions.success_becard(becards.message));
      yield put(
        add_toast({
          error: false,
          text: LOCAL_MESSAGES.USER_BECARDS[lang],
        })
      );
      yield put(actions.set_user_becards(becards.becards));

      yield put(actions.cancel_becard());
    }
  } catch (error) {
    yield put(actions.error_becard(error));
    yield put(
      add_toast({
        error: true,
        text: error.toString(),
      })
    );
    yield put(actions.cancel_becard());
  }
}

function* getOneBeCard({ becard_id }) {
  yield put(actions.reset_becard());
  yield put(actions.request_one_becard());
  const lang = yield getStateLang();
  try {
    const response = yield call(api_public.getOneBeCard, becard_id);
    if (response.error) {
      yield put(actions.error_becard(response.error[lang]));
      yield put(actions.cancel_one_becard());
      yield put(
        add_toast({
          error: true,
          text: response.error[lang],
        })
      );
    } else {
      yield put(actions.success_becard(response.message[lang]));
      yield put(
        add_toast({
          error: false,
          text: response.message[lang],
        })
      );
      yield put(actions.cancel_one_becard());
      yield put(actions.set_sel_becard(response.becard));
    }
  } catch (error) {
    yield put(actions.error_becard(error));
    yield put(
      add_toast({
        error: true,
        text: error,
      })
    );
    yield put(actions.cancel_one_becard());
  }
}

function* postUpdateBeCard({ dataBecard, becard_id, images }) {
  yield put(actions.reset_becard());
  yield put(actions.request_one_becard());
  const lang = yield getStateLang();
  try {
    if (images) {
      const upload = yield call(api_private.uploadImages, images);
      if (upload.image_urls && upload.image_urls.length) {
        const dataImg = {
          ...dataBecard,
          profile_img: upload.image_urls[0],
        };
        const updated = yield call(
          api_private.updateBeCard,
          dataImg,
          becard_id
        );
        if (updated.becard) {
          yield put(actions.success_becard(updated.message));
          yield put(
            add_toast({
              error: false,
              text: updated.message[lang],
            })
          );
          yield put(actions.set_sel_becard(updated.becard));
          yield getUserBeCards();
        } else {
          yield put(actions.error_becard(updated.error[lang]));
          yield put(
            add_toast({
              error: true,
              text: updated.error[lang],
            })
          );
        }
      }
    } else {
      const updated = yield call(
        api_private.updateBeCard,
        dataBecard,
        becard_id
      );
      if (updated.becard) {
        yield put(actions.success_becard(updated.message));
        yield put(
          add_toast({
            error: false,
            text: updated.message[lang],
          })
        );
        yield put(actions.set_sel_becard(updated.becard));
        yield getUserBeCards();
      } else {
        yield put(actions.error_becard(updated.error[lang]));
        yield put(
          add_toast({
            error: true,
            text: updated.error[lang],
          })
        );
      }
    }
  } catch (error) {
    yield put(actions.error_becard(error));
    yield put(
      add_toast({
        error: true,
        text: error,
      })
    );
    yield put(actions.cancel_one_becard());
  }
}

export function* watchPostDataBeCard() {
  yield takeLatest(actions.SEARCH_ONE_BECARD, getBeCardSearch);
  yield takeLatest(actions.SEARCH_BECARDS, getBeCardsSearch);
  yield takeLatest(actions.LIST_BECARDS, getAllBeCards);
  yield takeLatest(actions.CREATE_BECARD, postDataBeCard);
  yield takeLatest(actions.LIST_USER_BECARDS, getUserBeCards);
  yield takeLatest(actions.START_GET_BECARD, getOneBeCard);
  yield takeLatest(actions.START_UPDATE_BECARD, postUpdateBeCard);
}
