import React,{useState,Fragment} from 'react';
import {Link} from 'react-router-dom';
import {Col,Button,Collapse,Navbar,NavbarToggler} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {PLATF_SECTIONS_MENU} from '../../constants/platform';

function SideBar({className,lang}){
  const [isOpen,setOpen] = useState(false);
  const toggle =()=>{
    setOpen(!isOpen);
  }
  return(
    <>
      <Col xs={12} md={1} className={`pt-5 px-0 d-none d-md-flex flex-column justify-content-between ${className}`}>
        <div className="pt-5 text-center">
          {PLATF_SECTIONS_MENU.map(({to,name,faIcon},i)=><Button
             tag={Link} to={to} className="text-light px-1" color="link" block
             key={i}>
             <FontAwesomeIcon icon={faIcon}/>
             <br/>
             {name[lang]}
          </Button>)}

          {/* {isAdmin
            ?<Button tag={Link} to={`/platform/${PLATF_SECTIONS_MENU.ADMIN}`} className="text-light px-1" color="link" block>
              <i className="fas fa-toolbox"/>
              <br/>
              Admin
            </Button>
            :null
          } */}
        </div>
        <div>
          <Button tag={Link} to="/login" className="text-light px-1" color="link" block>
            <FontAwesomeIcon icon="sign-out-alt"/>
            <br/>
            Log out
          </Button>
        </div>
      </Col>
      <Col xs={12} className={`d-block d-md-none ${className}`}>
        <Navbar>
          <h4 className="mr-auto text-light">Menu</h4>
          <NavbarToggler className="toggle-icon-white" onClick={toggle}>
            <FontAwesomeIcon icon="bars"/>
          </NavbarToggler>
          <Collapse isOpen={isOpen} navbar>
            <div className="text-left">
              {PLATF_SECTIONS_MENU.map(({to,name,faIcon},i)=><Fragment key={i}>
                <Button
                   tag={Link} to={to} className="text-light px-1" color="link" block
                   >
                   <FontAwesomeIcon icon={faIcon}/>
                   <br/>
                   {name[lang]}
                </Button>
                <br/>
              </Fragment>)}

              {/* {isAdmin
                ?<Button tag={Link} to={`/platform/${PLATF_SECTIONS.ADMIN}`} className="text-light px-1" color="link">
                  <i className="fas fa-toolbox"/>
                  &nbsp;
                  Admin
                </Button>
                :null
              } */}
            </div>
            <div className="text-left">
              <Button tag={Link} to="/login" className="text-light px-1" color="link">
               <FontAwesomeIcon icon="sign-out-alt"/>
                &nbsp;
                Log out
              </Button>
            </div>
          </Collapse>

        </Navbar>

      </Col>
    </>
  )
}

export default SideBar;
