import React from "react";
import { Container, Row, Col } from "reactstrap";
import {
  SideBarCont,
  FormModalCont,
  SlideMenuCont,
  UserProfileCont,
  CheckLoginCont,
  CardsRosterCont,
  CompanyRosterCont,
  BeCardsSubmenuCont,
  CompanySubmenuCont,
  CalendarCont,
  SecBarAdCont,
} from "../../containers";
import { SecondaryBar } from "../platform";
import { SEO } from "../elements";
import "../../style/platform.css";

function Platform({
  history,
  match: {
    params: { section },
  },
}) {
  // const [slideMenu,setSlideMenu] = useState(false)
  return (
    <section className="Platform">
      <SEO title="Platform" />
      <CheckLoginCont history={history} />
      <FormModalCont className="" />
      <SlideMenuCont className="" />
      {/* <SlideMenuCont className="">
          {adminSect===ADMIN_SECTIONS.CAMPAIGNS&&selCampaign
            ?<EditableCampaignCont/>
            :null
          }
          {adminSect===ADMIN_SECTIONS.USERS&&selUser
            ?<UserModifyCont/>
            :null
          }
      </SlideMenuCont> */}
      <Container fluid>
        <Row className="flex-md-nowrap">
          <SideBarCont className="side_Bar" />
          <SecondaryBar
            className={`secondary_bar d-none d-md-flex flex-column justify-content-start align-items-center `}
          >
            {!section ? (
              <CalendarCont
                className="animated fadeInPlace"
                calendarDates={[]}
              />
            ) : section === "becards" ? (
              <BeCardsSubmenuCont className="animated fadeInPlace" />
            ) : section === "services" ? (
              <h3 className="text-light">Proximamente</h3>
            ) : section === "profile" ? (
              <UserProfileCont className="animated fadeInPlace" />
            ) : section === "companies" ? (
              <CompanySubmenuCont className="animated fadeInPlace" />
            ) : null}
            <SecBarAdCont
              img={{
                src: "https://assets.n12.mx/assets/logo/landscape_logo_w.png",
                alt: "logo n12mx",
              }}
              title={"12 meses gratis"}
              subtitle={"Cortesía de"}
              link={"https://www.n12.mx"}
              className="mt-auto pt-2 mb-2 blue_grad_bg text-light rounded"
            />
          </SecondaryBar>
          <Col xs={12} md={8} className="pt-md-5 px-0 px-md-3 content_col">
            <SecondaryBar
              className={`animated fadeIn secondary_bar d-flex d-md-none flex-column justify-content-start align-items-center `}
              // className="animated fadeIn secondary_bar pt-5"
            >
              <div className="pb-2 w-100">
                {!section ? (
                  <CalendarCont calendarDates={[]} />
                ) : section === "becards" ? (
                  <BeCardsSubmenuCont />
                ) : section === "services" ? (
                  <h3 className="text-light">Proximamente</h3>
                ) : section === "profile" ? (
                  <UserProfileCont />
                ) : section === "companies" ? (
                  <CompanySubmenuCont />
                ) : null}
              </div>
              <SecBarAdCont
                img={{
                  src: "https://assets.n12.mx/assets/logo/landscape_logo_w.png",
                  alt: "logo n12mx",
                }}
                title={"12 meses gratis"}
                subtitle={"Cortesía de"}
                link={"https://www.n12.mx"}
                className="mt-auto pt-2 mb-2 blue_grad_bg text-light rounded"
              />
            </SecondaryBar>

            <Row className="pb-1">
              <Col>
                {section === "companies" ? (
                  <CompanyRosterCont platform />
                ) : (
                  <CardsRosterCont platform />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Platform;
