import React from 'react';
import {connect} from 'react-redux';
import {UserProfile} from '../../components/platform/elements';
import {
  post_data_user,
  cancel_user
} from '../../redux/actions/user-actions';
import {
  set_sel_form,
  toggle_form_modal
} from '../../redux/actions/platform-actions'

function UserProfileCont ({
  set_sel_form,
  toggle_form_modal,
  post_data_user,
  cancel_user,
  ...rest
}){
  const fillProfile=()=>{
    set_sel_form('profileUpdate')
    toggle_form_modal()
  }
  return(
    <UserProfile
      updateUser={post_data_user}
      fillProfile={fillProfile}
      cancelRequest={cancel_user}
      // toggleChangedUser={this.props.toggle_changed_user}
      // cancelRequest={this.props.cancel_request}
       {...rest}/>
  )
}

const mapStateToProps=({
  loginReducer,
  globalReducer:{lang}
})=>({
  ...loginReducer,
  lang
})

const mapActionsToProps={
  post_data_user,
  set_sel_form,
  toggle_form_modal,
  cancel_user
}

export default connect(mapStateToProps,mapActionsToProps)(UserProfileCont)
