import React from 'react';
import {connect} from 'react-redux';
import {FormModal} from '../../components/platform';
import {toggle_form_modal} from '../../redux/actions/platform-actions';

function FormModalCont({toggle_form_modal,...rest}){
  return(
    <FormModal toggle={toggle_form_modal} {...rest}/>
  )
}

const mapStateToProps=({
  platformReducer,
  globalReducer:{lang}
})=>({
  ...platformReducer,
  lang
})

const mapActionsToProps={
  toggle_form_modal
}

export default connect(mapStateToProps,mapActionsToProps)(FormModalCont);
