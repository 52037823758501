import { fetchStatus, getErrors, setHJson, setHFData } from "../utils";
// let API = 'http://localhost:3015/api/v1'
// let API = 'https://www.n12.mx/api/v1'

let API = process.env.REACT_APP_API_URL;

// if(process.env.NODE_ENV==='production'){
//   API = process.env.PROD_URL
// }

export const local = {
  setLocalToken(token) {
    return sessionStorage.setItem(process.env.REACT_APP_TOKEN_NAME, token);
  },
  revokeLocalToken() {
    return sessionStorage.removeItem(process.env.REACT_APP_TOKEN_NAME);
  },
};
// export const api = {
//     postArticle(dataPost){
//         let init
//         init = setHJson(init,'POST',dataPost)
//         return fetch(`${API}/articles`,init)
//         .then(fetchStatus)
//         .catch(getErrors)
//     }
// }

export const api_private = {
  getRenewToken() {
    let init;
    init = setHJson(init, "GET");
    return fetch(`${API}/private/auth/renew_token`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  uploadImages(images) {
    let init;
    init = setHFData(init, "POST", images);
    return fetch(`${API}/private/upload/multiple`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  createBeCard(data) {
    let init;
    init = setHJson(init, "POST", data);
    return fetch(`${API}/private/becards`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  createCompany(data) {
    let init;
    init = setHJson(init, "POST", data);
    return fetch(`${API}/private/companies`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  updateUser(dataUser, user_id) {
    let init;
    init = setHJson(init, "PUT", dataUser);
    return fetch(`${API}/private/user/${user_id}`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  updateBeCard(dataBecard, becard_id) {
    let init;
    init = setHJson(init, "PUT", dataBecard);
    return fetch(`${API}/private/becards/${becard_id}`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
};

export const api_public = {
  getOneBeCard(becard_id) {
    let init;
    init = setHJson(init, "GET");
    return fetch(`${API}/public/becards/${becard_id}`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  getSearchBeCard(search_URL) {
    let init;
    init = setHJson(init, "GET");
    return fetch(`${API}/public/becards${search_URL}`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  getAllBeCards(becard_URL) {
    let init;
    init = setHJson(init, "GET");
    return fetch(`${API}/public/becards${becard_URL}`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  async getAllCountries() {
    let init;
    init = setHJson(init, "GET");
    return fetch(`${API}/public/countries`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  getAllCompanies(company_URL) {
    let init;
    init = setHJson(init, "GET");
    return fetch(`${API}/public/companies${company_URL}`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  postSignUp(dataSignup) {
    let init;
    init = setHJson(init, "POST", dataSignup);
    return fetch(`${API}/public/auth/sign_up`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  postLogin(dataLogin) {
    let init;
    init = setHJson(init, "POST", dataLogin);
    return fetch(`${API}/public/auth/login`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  postForgPass(dataForgP) {
    let init;
    init = setHJson(init, "POST", dataForgP);
    return fetch(`${API}/public/auth/forgot_password`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  postResetPass(dataResetP) {
    let init;
    init = setHJson(init, "POST", dataResetP);
    return fetch(`${API}/public/auth/password_reset`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  getVerifyToken(reset_pass_token) {
    let init;
    init = setHJson(init, "GET");
    return fetch(`${API}/public/auth/validate_token/${reset_pass_token}`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  postVerify(dataVerify) {
    let init;
    init = setHJson(init, "POST", dataVerify);
    return fetch(`${API}/public/auth/verify_account`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  postOffer(dataOffer) {
    let init;
    init = setHJson(init, "POST", dataOffer);
    return fetch(`${API}/public/coupon`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  verifyOffer(couponURL) {
    let init;
    init = setHJson(init, "GET");
    return fetch(`${API}/public/coupon_verify${couponURL}`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  postOrder(dataOrder) {
    let init;
    init = setHJson(init, "POST", dataOrder);
    return fetch(`${API}/public/order`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  postPurchase(dataBuy) {
    let init;
    init = setHJson(init, "POST", dataBuy);
    return fetch(`${API}/public/transaction-complete`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  postStripePaymentIntent(intentData) {
    let init;
    init = setHJson(init, "POST", intentData);
    return fetch(`${API}/public/payment-intent`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  putStripePaymentIntent(updateData) {
    let init;
    init = setHJson(init, "PUT", updateData);
    return fetch(`${API}/public/payment-intent`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  postStripeConfirmPayment(intentData) {
    let init;
    init = setHJson(init, "POST", intentData);
    return fetch(`${API}/public/confirm-payment`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  postStripeSession(order_id) {
    let init;
    init = setHJson(init, "POST");
    return fetch(`${API}/public/checkout-session/${order_id}`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  postSubscribe(dataSubs) {
    let init;
    init = setHJson(init, "POST", dataSubs);
    return fetch(`${API}/public/subscriber`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  postDataLead(dataLead) {
    let init;
    init = setHJson(init, "POST", dataLead);
    return fetch(`${API}/public/lead`, init).then(fetchStatus).catch(getErrors);
  },
  postDataRegistry(dataRegistry) {
    let init;
    init = setHJson(init, "POST", dataRegistry);
    return fetch(`${API}/public/assistance`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  getProducts(productQueries) {
    return fetch(`${API}/public/product${productQueries}`)
      .then(fetchStatus)
      .catch(getErrors);
  },
  postQRcode(url) {
    let init;
    init = setHJson(init, "POST", url);
    return fetch(`${API}/public/codeqr`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
  verifyAssistance(assistance_id, body) {
    let init;
    init = setHJson(init, "POST", body);
    return fetch(`${API}/assistances/${assistance_id}`, init)
      .then(fetchStatus)
      .catch(getErrors);
  },
};
