import React from "react";
import {
  Container,
  Col,
  // Progress,
  Row,
  CardDeck,
  // CardColumns
} from "reactstrap";
import { useIsSmall } from "../../../hooks";
import { GhostCard } from "../../elements";
import { RosterBeCard } from "./";
import {
  CARDS_PER_ROW_ROAST,
  CARDS_PER_ROW_ROAST_MEDIUM,
  LOCAL_MESSAGES,
} from "../../../constants";

import "../../../style/roster.css";

const CardsRoster = ({
  editBeCard,
  all_becards = [],
  user_becards = [],
  filtered_becards = [],
  filtered,
  platform,
  isFetching,
  lang,
}) => {
  const isSmall = useIsSmall();
  const cards_per_row = isSmall
    ? CARDS_PER_ROW_ROAST_MEDIUM
    : CARDS_PER_ROW_ROAST;
  let decks = [];
  let ghostDecks = [];
  let ghostCards = Array(
    isSmall ? CARDS_PER_ROW_ROAST_MEDIUM * 2 : CARDS_PER_ROW_ROAST * 2
  ).fill("");
  ghostCards = ghostCards.map((el, i) => {
    if (i % cards_per_row === 0) {
      return (ghostDecks = ghostDecks.concat([
        [...ghostCards.slice(i, i + cards_per_row)],
      ]));
    }
    return null;
  });
  // let cards = []
  if (platform) {
    user_becards.map((el, i) => {
      if (i % cards_per_row === 0) {
        return (decks = decks.concat([
          [...user_becards.slice(i, i + cards_per_row)],
        ]));
      }
      return null;
    });
    // cards = user_becards
  } else {
    if (filtered) {
      filtered_becards.map((el, i) => {
        if (i % cards_per_row === 0) {
          return (decks = decks.concat([
            [...filtered_becards.slice(i, i + cards_per_row)],
          ]));
        }
        return null;
      });
    } else {
      all_becards.map((el, i) => {
        if (i % cards_per_row === 0) {
          return (decks = decks.concat([
            [...all_becards.slice(i, i + cards_per_row)],
          ]));
        }
        return null;
      });
    }

    // cards = all_becards
  }

  return (
    <Container>
      <Row>
        {platform && !decks.length ? (
          <Col>
            <h3 className="text-center animated fadeInPlace">
              {LOCAL_MESSAGES.EMPTY_BECARDS[lang]}
            </h3>
          </Col>
        ) : null}
      </Row>
      <Row>
        <Col>
          {isFetching ? (
            <>
              {ghostDecks.map((arr, i) => (
                <CardDeck key={i}>
                  {arr &&
                    arr.map((el, i) => <GhostCard className="mb-3" key={i} />)}
                </CardDeck>
              ))}
            </>
          ) : decks.length ? (
            decks.map((arr, o) => (
              <CardDeck key={o}>
                {arr &&
                  arr.map((el, i) => (
                    <RosterBeCard
                      editBeCard={editBeCard}
                      platform={platform}
                      className="animated fadeInPlace mb-3"
                      key={i}
                      lang={lang}
                      info={el}
                    />
                  ))}
              </CardDeck>
            ))
          ) : (
            ghostDecks.map((arr, i) => (
              <CardDeck key={i}>
                {arr &&
                  arr.map((el, i) => <GhostCard className="mb-3" key={i} />)}
              </CardDeck>
            ))
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default CardsRoster;
