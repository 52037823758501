import React,{useState,useEffect} from 'react';
import {
  Row,
  Col,
  Card,
  Button,
  InputGroup,
  Input,
  InputGroupAddon,
  Collapse,
  ButtonGroup,
  // Badge
} from 'reactstrap';
// import {debounce} from '../../../utils';
import {useDebounce} from '../../../hooks';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {DEBOUNCE_TIME,LABELS_FILTER,FILTERS} from '../../../constants';


function CardsFilter({
  filtered,
  lang,
  filter_types,
  toggleFilter,
  startSearch,
  addFilter,
  remFilter
}){
  const [showFilters,setShowFilters] = useState(false)
  const [search,setSearch] = useState('')
  const searchDebounce = useDebounce(search,DEBOUNCE_TIME.SEARCH)
  useEffect(()=>{
    if(searchDebounce!==''){
      let queryURL = new URLSearchParams()
      queryURL.append('search',true)
      queryURL.append('search_text',searchDebounce)
      startSearch(`?${queryURL.toString()}`)
    }

  },[searchDebounce, startSearch])
  useEffect(()=>{
    if(searchDebounce!==''){
      let queryURL = new URLSearchParams()

      if(filter_types.length){
        queryURL.append('search',true)
        filter_types.map((el)=>{
          const {urlQry} = FILTERS[el]
          queryURL.append(urlQry,searchDebounce)
          return null
        })
        startSearch(`?${queryURL.toString()}`)
      }
    }
    // queryURL.append('search_text',searchDebounce)

    // eslint-disable-next-line
  },[filter_types,searchDebounce])
  useEffect(()=>{
    if(search!==''){
      if(!filtered){
        toggleFilter()
      }
    } else {
      if(filtered){
        toggleFilter()
      }
    }
    // eslint-disable-next-line
  },[search])

  const toggleAm=()=>{
    setShowFilters(!showFilters)
  }
  const getValue=({target:{value,name}})=>{
    setSearch(value)
  }
  return(
    <>
        <Row className="my-3">
          <Col>
            <Card className="bg-light py-3" inverse body>
              <Row className="justify-content-end" form>
                <Col className="text-left align-self-start mr-auto" xs={12} md={2}>
                  <Button
                    // onClick={this.resetFilter}
                    size="sm"
                    color="primary" >
                    {LABELS_FILTER.CLEAN[lang]}&nbsp;
                    <FontAwesomeIcon icon="broom"/>
                  </Button>
                </Col>
                <Col className="text-right mb-2 mb-md-auto" xs={12} md={2}>
                  {/* <TagDropDown
                    addAmenity={addAmenity}
                    remAmenity={remAmenity}
                    filter_types={filter_types}
                    size='sm'/> */}
                    <Button color="primary" size="sm"
                       onClick={toggleAm}
                      >
                      {LABELS_FILTER.CATHEGORIES[lang]}
                      &nbsp;
                      {showFilters?<FontAwesomeIcon icon={'caret-up'}/>:<FontAwesomeIcon icon={'caret-down'}/>}

                    </Button>
                </Col>
                {/* <Col xs={12} md={3} >
                  <InputGroup size="sm">
                    <Input
                      // onChange={this.getValue}
                       // value={this.state.location}
                        name="location" type="select">

                      <option value="">Todas</option>
                      <option value="">Profesionales</option>
                      <option value="">Empresariales</option>
                    </Input>
                  </InputGroup>
                </Col> */}

                <Col xs={12} md={4} >
                  <InputGroup size="sm">
                    <Input name="search"
                       value={search}
                       placeholder={LABELS_FILTER.SEARCH_PLACEHOLDER[lang]}
                        onChange={getValue}
                      />
                    <InputGroupAddon addonType="append">
                      <Button color="primary">{LABELS_FILTER.SEARCH[lang]}&nbsp;&nbsp;<FontAwesomeIcon icon={"search"}/></Button>
                    </InputGroupAddon>
                  </InputGroup>
                </Col>

              </Row>
            </Card>
          </Col>
        </Row>

          <Row className="justify-content-center mb-2">
            <Col className="text-center">
              <Collapse isOpen={showFilters}>
                <ButtonGroup>
                {Object.keys(FILTERS).map((el,i)=><Button
                  size="sm"
                  outline
                  active={filter_types.indexOf(el)>-1}
                  color="primary"
                  onClick={filter_types.indexOf(el)>-1?()=>remFilter(filter_types.indexOf(el)):()=>addFilter(el)}
                  key={i}>
                    {FILTERS[el].info}&nbsp;<FontAwesomeIcon icon={FILTERS[el].icon}/>
                </Button>)}
              </ButtonGroup>
              </Collapse>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
                {/* {FILTERS.map((el,i)=><Badge color="info" className="mr-2 animated fadeIn"  key={i}>
                  <FontAwesomeIcon className="mt-1" icon={el.icon}/>&nbsp;<Button onClick={()=>remAmenity(i)} close />
                </Badge>)} */}
            </Col>
          </Row>

        {/* {isFetching?<Row className="text-center">
          <Col>
            <FontAwesomeIcon icon="cog" spin />
          </Col>
        </Row>:null} */}
      </>
  )
}

export default CardsFilter;
