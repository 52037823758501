export const CREATE_COMPANY = "CREATE_COMPANY";
export const LIST_COMPANIES = "LIST_COMPANIES";
export const LIST_USER_COMPANIES = "LIST_USER_COMPANIES";
export const SET_ALL_COMPANIES = "SET_ALL_COMPANIES";
export const SET_USER_COMPANIES = "SET_USER_COMPANIES";
export const SET_ONE_COMPANY = "SET_ONE_COMPANY";
export const SET_SEL_COMPANY = "SET_SEL_COMPANY";
export const SET_FILTERED_COMPANIES = "SET_FILTERED_COMPANIES";
export const REQUEST_COMPANY = "REQUEST_COMPANY";
export const CANCEL_COMPANY = "CANCEL_COMPANY";
export const SUCCESS_COMPANY = "SUCCESS_COMPANY";
export const ERROR_COMPANY = "ERROR_COMPANY";
export const RESET_COMPANY = "RESET_COMPANY";

export const list_user_companies=()=>({
  type:LIST_USER_COMPANIES
})

export const create_company=(dataCompany,images)=>({
  type:CREATE_COMPANY,
  dataCompany,
  images
})

export const set_user_companies=(user_companies)=>({
  type:SET_USER_COMPANIES,
  user_companies
})

export const request_company=()=>({
  type:REQUEST_COMPANY,
  isFetching:true
})

export const cancel_company=()=>({
  type:REQUEST_COMPANY,
  isFetching:false
})

export const success_company=(message)=>({
  type:SUCCESS_COMPANY,
  message
})

export const error_company=(error)=>({
  type:ERROR_COMPANY,
  error
})

export const reset_company=()=>({
  type:RESET_COMPANY,
  error:false,
  message:false
})
