import React from 'react';
import {connect} from 'react-redux';
import {BeCardsSubmenu} from '../../components/platform';
import {set_sel_form,toggle_form_modal} from '../../redux/actions/platform-actions';

function BeCardsSubmenuCont({
  set_sel_form,
  toggle_form_modal,
  ...rest
}){
  const createBeCard=()=>{
    set_sel_form('BeCard')
    toggle_form_modal()
  }
  return(
    <BeCardsSubmenu createBeCard={createBeCard} {...rest}/>
  )
}

const mapStateToProps=({
  globalReducer:{lang}
})=>({
  lang
})
const mapActionsToProps={
  set_sel_form,
  toggle_form_modal
}

export default connect(mapStateToProps,mapActionsToProps)(BeCardsSubmenuCont);
