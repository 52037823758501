import React from "react";

export const BackgroundBeCard = ({
  className = "",
  primary = "#ffe1fb",
  secondary = "#ffb055",
  overlay,
  highlights
}) => {
  return (
    <svg
      id="a"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 640 720"
      className={className}>
      <rect
        x="106.95"
        y="-18.81"
        width="713.74"
        height="606.27"
        rx="35.83"
        ry="35.83"
        transform="translate(-74.18 203.58) rotate(-22.97)"
        style={{ fill: primary }}
      />
      {overlay ? (
        <>
          <g style={{ opacity: ".1" }}>
            <path
              d="M236.6,51.29l237.65,558.24-183.07,77.47c-20.21,8.61-43.57-.79-52.18-20.99h0S32.51,181,32.51,181c-8.61-20.21,.79-43.57,20.99-52.18,0,0,0,0,0,0L236.6,51.29Z"
              style={{ fill: "#fff" }}
            />
          </g>
          <g style={{ opacity: ".1" }}>
            <path
              d="M390.66-14.51l237.65,558.24-337.13,143.27c-20.21,8.61-43.57-.79-52.18-20.99h0S32.51,181,32.51,181c-8.61-20.21,.79-43.57,20.99-52.18,0,0,0,0,0,0L390.66-14.51Z"
              style={{ fill: "#fff" }}
            />
          </g>
        </>
      ) : null}

      {highlights ? (
        <>
          <circle cx="474" cy="491" r="15" style={{ fill: "#fff" }} />
          <circle
            cx="474"
            cy="491"
            r="32"
            style={{
              fill: "none",
              stroke: "#fff",
              strokeMiterlimit: "10",
              strokeWidth: "6px"
            }}
          />
          <rect
            x="296.01"
            y="82.02"
            width="40"
            height="40"
            transform="translate(28.13 267.55) rotate(-47.79)"
            style={{ fill: secondary }}
          />
          <rect
            x="308.99"
            y="646.07"
            width="40"
            height="40"
            transform="translate(-385.39 462.25) rotate(-47.79)"
            style={{ fill: secondary }}
          />
          <rect
            x="70"
            y="173.02"
            width="40"
            height="40"
            transform="translate(-113.43 130) rotate(-47.79)"
            style={{ fill: secondary }}
          />
        </>
      ) : null}
    </svg>
  );
};
