import React, { useState, useEffect } from "react";
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircleProgress } from "../../pieces";
import { useInterval } from "../../../hooks";

import { CHANGE_FEATURES } from "../../../constants";

function Features({ features, background }) {
  const [play, setPlay] = useState(true);
  const [currFeature, setCurrFeature] = useState(0);
  const [progress, setProgress] = useState(100);
  useEffect(() => {
    if (!play) {
      setPlay(true);
      setProgress(100);
    }
  }, [play]);
  useInterval(
    () => {
      const quota = 10000 / CHANGE_FEATURES;
      setProgress(progress - quota);
    },
    play ? 100 : null
  );
  useInterval(
    () => {
      changeFeature();
      setProgress(100);
    },
    play ? CHANGE_FEATURES : null
  );
  const changeFeature = (changeTo) => {
    if (changeTo !== undefined) {
      setCurrFeature(changeTo);
    } else {
      let next;
      currFeature + 1 > features.length - 1
        ? (next = 0)
        : (next = currFeature + 1);
      setCurrFeature(next);
    }
    setPlay(false);
  };
  const {
    graphs: [feature1, feature2, feature3],
  } = features[currFeature];
  return (
    <Container className={`${background ? "abs_cont" : ""}`}>
      {background && (
        <div className="trans_sect" style={{ ...background, top: "10vh" }} />
      )}
      <Container>
        <Row className="text-center mb-5">
          <Col>
            <h3 className="sect_title text-light">
              Características que te ayudan a destacar
            </h3>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={7} className="feat_cont abs_cont">
            {feature1 ? (
              <motion.img
                animate={{ y: 0, opacity: 1 }}
                initial={{ y: 10, opacity: 0 }}
                transition={{ delay: 0.3 }}
                className="feature_img"
                style={feature1.style}
                key={feature1.img}
                src={feature1.img}
                alt="feature"
              />
            ) : null}
            {feature2 ? (
              <motion.img
                animate={{ y: 0, opacity: 1 }}
                initial={{ y: 10, opacity: 0 }}
                transition={{ delay: 0.5 }}
                className="feature_img"
                style={feature2.style}
                key={feature2.img}
                src={feature2.img}
                alt="feature"
              />
            ) : null}
            {feature3 ? (
              <motion.img
                animate={{ y: 0, opacity: 1 }}
                initial={{ y: 10, opacity: 0 }}
                transition={{ delay: 0.7 }}
                className="feature_img"
                style={feature3.style}
                key={feature3.img}
                src={feature3.img}
                alt="feature"
              />
            ) : null}
          </Col>
          <Col xs={12} md={5}>
            <Nav vertical>
              {features.map(({ title, text, icon }, i) => (
                <NavItem
                  className="feature_menu_item_style p-2"
                  active={i === currFeature}
                  key={i}
                >
                  <NavLink onClick={() => changeFeature(i)} href="#">
                    <Row className="d-flex  justify-content-start">
                      <Col className="d-flex " xs={4} md={2}>
                        <CircleProgress
                          className={i === currFeature ? "" : "d-none"}
                          radius={15}
                          stroke={2}
                          progress={progress}
                        />
                      </Col>
                      <Col xs={8} md={10} className="text-light">
                        <p>
                          <b>
                            <FontAwesomeIcon icon={icon} />
                            &nbsp;{title}
                          </b>{" "}
                          <br />
                          {text}
                        </p>
                      </Col>
                    </Row>
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Features;
